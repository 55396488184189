import HomepageBanner from "../Banner/HomepageBanner"
import Header from "../Header"
import DesignBlock from "../DesignBlock"
import HomepageCollection from "../Collection/HomepageCollection"
import HomepagePromotion from "../Promotion/HomepagePromotion"
import HomepageArticle from "../Article/HomepageArticle"
import HomepageProject from "../Project//HomepageProject"
import Service from "../Service"
import Newsletter from "../Newsletter"
import SocialSupportOnlineButtons from "../SocialSupportOnlineButtons"
import Footer from "../Footer"

export default function Products(){
  return (
    <div className="container-fluid p-0">
      <Header page="empty" />
      <section className="account-login-verification">
        <ProductBanner />
        <ProductFilter />
        <ProductData />
        <ViewedProductData />
      </section>
      <Newsletter />
      <SocialSupportOnlineButtons />
      <Footer />
    </div>
    
  )
}

function ProductBanner(){
  return (
    <div id="carousel-v2">
        <div id="carouselHomepage" className="carousel slide" data-ride="carousel" data-interval="500000">
            <div className="carousel-inner">
                <div className="carousel-item active">
                    <img className="d-block w-100 img-responsive" src={process.env.REACT_APP_PUBLIC_URL + "/images/2021_Januari 1.png"} alt="First slide" />
                </div>
            </div>
        </div>
    </div>
  )
}

function ProductFilter(){
  return (
    <div id="filter-v2" className="container-fluid padding-block-l-r-bt w-1150">
        <div className="row">
            <div className="col-12">
                <h4 className="title-filter-v2">PHÒNG KHÁCH</h4>
            </div>
            <div className="view-btn-filter">
                <div className="btn-dropdown">
                    <div id="dropMenuFilter01" className="dropdown" data-value="Loại phòng" data-map="dropMenuFilter01">
                        <button className="btn" type="button" id="dropFilter01" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span>Loại phòng</span> <i className="fa-solid fa-angle-down"></i>
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropFilter01">
                            <a className="dropdown-item item-filter" data-value="Phòng khách">Phòng khách<span className="number-prds">12</span> </a>
                            <a className="dropdown-item item-filter" data-value="Phòng ngủ">Phòng ngủ<span className="number-prds">8</span></a>
                            <a className="dropdown-item item-filter" data-value="Phòng ăn">Phòng ăn<span className="number-prds">5</span></a>
                            <a className="dropdown-item item-filter" data-value="Phòng học">Phòng học<span className="number-prds">11</span></a>
                            <a className="dropdown-item item-filter" data-value="Phòng chơi">Phòng chơi<span className="number-prds">3</span></a>
                            <a className="action-dropdown item-see-more active">Xem thêm</a>
                            <div className="load-more-item">
                                <a className="dropdown-item item-filter" data-value="Phòng khách">Phòng khách<span className="number-prds">12</span> </a>
                                <a className="dropdown-item item-filter" data-value="Phòng ngủ">Phòng ngủ<span className="number-prds">8</span></a>
                                <a className="dropdown-item item-filter" data-value="Phòng ăn">Phòng ăn<span className="number-prds">5</span></a>
                                <a className="dropdown-item item-filter" data-value="Phòng học">Phòng học<span className="number-prds">11</span></a>
                                <a className="dropdown-item item-filter" data-value="Phòng chơi">Phòng chơi<span className="number-prds">3</span></a>
                                <a className="dropdown-item item-filter" data-value="Phòng khách">Phòng khách<span className="number-prds">12</span> </a>
                                <a className="dropdown-item item-filter" data-value="Phòng ngủ">Phòng ngủ<span className="number-prds">8</span></a>
                                <a className="dropdown-item item-filter" data-value="Phòng ăn">Phòng ăn<span className="number-prds">5</span></a>
                                <a className="dropdown-item item-filter" data-value="Phòng học">Phòng học<span className="number-prds">11</span></a>
                                <a className="dropdown-item item-filter" data-value="Phòng chơi">Phòng chơi<span className="number-prds">3</span></a>
                                <a className="dropdown-item item-filter" data-value="Phòng khách">Phòng khách<span className="number-prds">12</span> </a>
                                <a className="dropdown-item item-filter" data-value="Phòng ngủ">Phòng ngủ<span className="number-prds">8</span></a>
                                <a className="dropdown-item item-filter" data-value="Phòng ăn">Phòng ăn<span className="number-prds">5</span></a>
                                <a className="dropdown-item item-filter" data-value="Phòng học">Phòng học<span className="number-prds">11</span></a>
                                <a className="dropdown-item item-filter" data-value="Phòng chơi">Phòng chơi<span className="number-prds">3</span></a>
                            </div>
                            <a className="action-dropdown item-collapse">Thu gọn</a>
                            <div className="sub-dropdown-menu">
                                {/* <!-- call ajax -->
                                <!-- <a className="dropdown-item drop-item-filter"><i className="fa-solid fa-arrow-left"></i> Phòng khách<span className="number-prds">12</span></a>
                            <div className="sub-menu">
                                <a className="dropdown-item sub-item-filter" data-value="Sofa">Sofa<span className="number-prds">5</span></a>
                                <a className="dropdown-item sub-item-filter" data-value="Armchair">Armchair<span className="number-prds">6</span></a>
                                <a className="dropdown-item sub-item-filter" data-value="Sidetable">Sidetable<span className="number-prds">7</span></a>
                                <a className="dropdown-item sub-item-filter" data-value="Coffee table">Coffee table<span className="number-prds">8</span></a>
                                <a className="dropdown-item sub-item-filter" data-value="Console">Console<span className="number-prds">9</span></a>
                            </div> --> */}
                            </div>
                        </div>
                    </div>
                    <div id="dropMenuFilter02" className="dropdown" data-value="Danh mục" data-map="dropMenuFilter02">
                        <button className="btn" type="button" id="dropFilter02" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span>Danh mục</span> <i className="fa-solid fa-angle-down"></i>
                    </button>
                        <div className="dropdown-menu" aria-labelledby="dropFilter02">
                            <a className="dropdown-item item-filter" data-value="Danh mục 01">Danh mục 01<span className="number-prds">12</span> </a>
                            <a className="dropdown-item item-filter" data-value="Danh mục 02">Danh mục 02<span className="number-prds">8</span></a>
                            <a className="dropdown-item item-filter" data-value="Danh mục 03">Danh mục 03<span className="number-prds">5</span></a>
                            <a className="dropdown-item item-filter" data-value="Danh mục 04">Danh mục 04<span className="number-prds">11</span></a>
                            <a className="dropdown-item item-filter" data-value="Danh mục 05">Danh mục 05<span className="number-prds">3</span></a>
                            <a className="action-dropdown item-see-more active">Xem thêm</a>
                            <div className="load-more-item">
                                <a className="dropdown-item item-filter" data-value="Danh mục 01">Danh mục 01<span className="number-prds">12</span> </a>
                                <a className="dropdown-item item-filter" data-value="Danh mục 02">Danh mục 02<span className="number-prds">8</span></a>
                                <a className="dropdown-item item-filter" data-value="Danh mục 03">Danh mục 03<span className="number-prds">5</span></a>
                                <a className="dropdown-item item-filter" data-value="Danh mục 04">Danh mục 04<span className="number-prds">11</span></a>
                                <a className="dropdown-item item-filter" data-value="Danh mục 05">Danh mục 05<span className="number-prds">3</span></a>
                                <a className="dropdown-item item-filter" data-value="Danh mục 01">Danh mục 01<span className="number-prds">12</span> </a>
                                <a className="dropdown-item item-filter" data-value="Danh mục 02">Danh mục 02<span className="number-prds">8</span></a>
                                <a className="dropdown-item item-filter" data-value="Danh mục 03">Danh mục 03<span className="number-prds">5</span></a>
                                <a className="dropdown-item item-filter" data-value="Danh mục 04">Danh mục 04<span className="number-prds">11</span></a>
                                <a className="dropdown-item item-filter" data-value="Danh mục 05">Danh mục 05<span className="number-prds">3</span></a>
                                <a className="dropdown-item item-filter" data-value="Danh mục 01">Danh mục 01<span className="number-prds">12</span> </a>
                                <a className="dropdown-item item-filter" data-value="Danh mục 02">Danh mục 02<span className="number-prds">8</span></a>
                                <a className="dropdown-item item-filter" data-value="Danh mục 03">Danh mục 03<span className="number-prds">5</span></a>
                                <a className="dropdown-item item-filter" data-value="Danh mục 04">Danh mục 04<span className="number-prds">11</span></a>
                                <a className="dropdown-item item-filter" data-value="Danh mục 05">Danh mục 05<span className="number-prds">3</span></a>
                            </div>
                            <a className="action-dropdown item-collapse">Thu gọn</a>
                            <div className="sub-dropdown-menu">
                                {/* <!-- call ajax --> */}
                            </div>
                        </div>
                    </div>
                    <div id="dropMenuFilter03" className="dropdown" data-value="Giá" data-map="dropMenuFilter03">
                        <button className="btn" type="button" id="dropMenuFilter03" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span>Giá</span> <i className="fa-solid fa-angle-down"></i>
                    </button>
                        <div id="dropPrice" className="dropdown-menu filterPriceV2" aria-labelledby="dropMenuFilter03">
                            {/* <!-- <div className="time-range">
                                <div className="sliders_step1">
                                    <div className="slider-range slider-range-price">
                                    </div>
                                </div>
                            </div> --> */}
                            <div className="time-range">
                                <div className="sliders_step1">
                                    <div className="slider-range slider-range-price">
                                        <span tabIndex="0" className="span-range-from ui-slider-handle ui-corner-all ui-state-default"></span>
                                        <span tabIndex="0" className="span-range-to ui-slider-handle ui-corner-all ui-state-default"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="dropMenuFilterAll" className="dropdown" data-value="Tất cả bộ lọc">
                        <button className="btn" data-toggle="modal" data-target="#modalFilterAll" type="button">
                        <span>Tất cả bộ lọc</span>
                        <svg focusable="false" viewBox="0 0 24 24" className="plp-svg-icon plp-pill__icon" aria-hidden="true"><path fillRule="evenodd" clipRule="evenodd" d="M6 5h2v1h12v2H8v1H6V8H4V6h2V5zm12 8h2v-2h-2v-1h-2v1H4v2h12v1h2v-1zm-5.9392 5H20v-2h-7.9392v-1h-2v1H4v2h6.0608v1h2v-1z"></path></svg>
                    </button>
                    </div>
                </div>
                <div id="dropMenuFilter00" className="dropdown btnSortTitle" data-value="Sắp xếp" data-map="dropMenuFilter00">
                    <button className="btn" type="button" id="dropFilter04" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span>Sắp xếp</span> <i className="fa-solid fa-angle-down"></i>
                    </button>
                    <div className="dropdown-menu" aria-labelledby="dropFilter04">
                        <fieldset>
                            <label className="plp-radio-button">
                                <span className="plp-radio-button__text">Mới nhất</span>
                                <div className="plp-radio plp-radio--subtle active">
                                    <input type="radio" name="radioname" data-value="Mới nhất" text="" />
                                    <span className="plp-radio__symbol"></span>
                                </div>
                            </label>
                            <label className="plp-radio-button">
                                <span className="plp-radio-button__text">Giá thấp tới cao</span>
                                <div className="plp-radio plp-radio--subtle">
                                    <input type="radio" name="radioname" data-value="Giá thấp tới cao" text="" />
                                    <span className="plp-radio__symbol"></span>
                                </div>
                            </label>
                            <label className="plp-radio-button">
                                <span className="plp-radio-button__text">Giá cao tới thấp</span>
                                <div className="plp-radio plp-radio--subtle">
                                    <input type="radio" name="radioname" data-value="Giá cao tới thấp" text="" />
                                    <span className="plp-radio__symbol"></span>
                                </div>
                            </label>
                            <label className="plp-radio-button">
                                <span className="plp-radio-button__text">Bán chạy nhất</span>
                                <div className="plp-radio plp-radio--subtle">
                                    <input type="radio" name="radioname" data-value="Bán chạy nhất" text="" />
                                    <span className="plp-radio__symbol"></span>
                                </div>
                            </label>
                            <label className="plp-radio-button">
                                <span className="plp-radio-button__text">Phổ biến nhất</span>
                                <div className="plp-radio plp-radio--subtle">
                                    <input type="radio" name="radioname" data-value="Phổ biến nhất" text="" />
                                    <span className="plp-radio__symbol"></span>
                                </div>
                            </label>
                            <label className="plp-radio-button">
                                <span className="plp-radio-button__text">Tên từ A-Z</span>
                                <div className="plp-radio plp-radio--subtle">
                                    <input type="radio" name="radioname" data-value="Tên từ A-Z" text="" />
                                    <span className="plp-radio__symbol"></span>
                                </div>
                            </label>
                            <label className="plp-radio-button">
                                <span className="plp-radio-button__text">Tên từ Z-A</span>
                                <div className="plp-radio plp-radio--subtle">
                                    <input type="radio" name="radioname" data-value="Tên từ Z-A" text="" />
                                    <span className="plp-radio__symbol"></span>
                                </div>
                            </label>
                        </fieldset>
                    </div>
                </div>
            </div>
        </div>
        <div id="content-filter" className="content-filter">
            <div className="list-btn-filter">
                {/* <!-- <button className="btn">
                    <i className="fa-solid fa-arrow-left"></i>
                    <span>Sofa</span>
                </button> --> */}
                <button className="btn btn-remove btn-filter-price btn_dropMenuFilter03">
                    <span>5.000.000 - 25.000.000 vnđ</span>
                    <i className="fa-solid fa-xmark"></i>
                </button>
            </div>
            {/* <!-- <div>
                <button className="btn btnDeleteAllFilter">Xoá tất cả</button>
            </div> --> */}
        </div>
    </div>
  )
}

function ProductData(){
  return (
    <div id="collection-v2" className="container-fluid padding-block-l-r-bt w-1150">
        <div className="row">
            <div className="col-6 col-md-6 col-lg-3 collection-v2">
                <div className="img__wrap">
                    <img className="img-responsive img__img w-100" src={process.env.REACT_APP_PUBLIC_URL + "/images/prd1.png"} />
                    <span className="text-on-img text-best">Best seller</span>
                    <div className="img__description_layer">
                        <div>
                            <button className="img__description img__description__mobile"><a href="#">Xem thêm</a></button>
                            <div className="img__description__cart__heart">
                                <button className="img__description img__description__cart"><a href="#"><i className="fa-solid fa-cart-shopping"></i></a></button>
                                <button className="img__description img__description__heart"><a href="#"><i className="fa-solid fa-heart"></i></a></button>
                            </div>
                        </div>
                    </div>
                </div>
                <a href="#" className="collection-title wrap-text">summer collection summer collection summer collection summer collection summer collection summer collection</a>
                <p className="collection-sku">SKU: HCB0001</p>
                <div className="discount-price-v2">
                    <span className="original-price">32.980.000đ</span>
                    <span className="percent-discount">-5%</span>
                </div>
                <span className="price-v2">29.996.000đ</span>
            </div>
            <div className="col-6 col-md-6 col-lg-3 collection-v2">
                <div className="img__wrap">
                    <img className="img-responsive img__img w-100" src={process.env.REACT_APP_PUBLIC_URL + "/images/prd2.png"} />
                    <div className="img__description_layer">
                        <div>
                            <button className="img__description img__description__mobile"><a href="#">Xem thêm</a></button>
                            <div className="img__description__cart__heart">
                                <button className="img__description img__description__cart"><a href="#"><i className="fa-solid fa-cart-shopping"></i></a></button>
                                <button className="img__description img__description__heart"><a href="#"><i className="fa-solid fa-heart"></i></a></button>
                            </div>
                        </div>
                    </div>
                </div>
                <a href="#" className="collection-title wrap-text">milano collection</a>
                <p className="collection-sku">SKU: HCB0002</p>
                <span className="price-v2">19.296.000đ</span>
            </div>
            <div className="col-6 col-md-6 col-lg-3 collection-v2">
                <div className="img__wrap">
                    <img className="img-responsive img__img w-100" src={process.env.REACT_APP_PUBLIC_URL + "/images/prd3.png"} />
                    <div className="img__description_layer">
                        <div>
                            <button className="img__description img__description__mobile"><a href="#">Xem thêm</a></button>
                            <div className="img__description__cart__heart">
                                <button className="img__description img__description__cart"><a href="#"><i className="fa-solid fa-cart-shopping"></i></a></button>
                                <button className="img__description img__description__heart"><a href="#"><i className="fa-solid fa-heart"></i></a></button>
                            </div>
                        </div>
                    </div>
                </div>
                <a href="#" className="collection-title wrap-text">yello collection</a>
                <p className="collection-sku">SKU: HCB0003</p>
                <span className="price-v2">27.296.000đ</span>
            </div>
            <div className="col-6 col-md-6 col-lg-3 collection-v2">
                <div className="img__wrap">
                    <img className="img-responsive img__img w-100" src={process.env.REACT_APP_PUBLIC_URL + "/images/prd4.png"} />
                    <div className="img__description_layer">
                        <div>
                            <button className="img__description img__description__mobile"><a href="#">Xem thêm</a></button>
                            <div className="img__description__cart__heart">
                                <button className="img__description img__description__cart"><a href="#"><i className="fa-solid fa-cart-shopping"></i></a></button>
                                <button className="img__description img__description__heart"><a href="#"><i className="fa-solid fa-heart"></i></a></button>
                            </div>
                        </div>
                    </div>
                </div>
                <a href="#" className="collection-title wrap-text">summer collection summer collection summer collection summer collection summer collection summer collection</a>
                <p className="collection-sku">SKU: HCB0001</p>
                <div className="discount-price-v2">
                    <span className="original-price">32.980.000đ</span>
                    <span className="percent-discount">-5%</span>
                </div>
                <span className="price-v2">29.996.000đ</span>
            </div>
            <div className="col-6 col-md-6 col-lg-3 collection-v2">
                <div className="img__wrap">
                    <img className="img-responsive img__img w-100" src={process.env.REACT_APP_PUBLIC_URL + "/images/prd5.png"} />
                    <div className="img__description_layer">
                        <div>
                            <button className="img__description img__description__mobile"><a href="#">Xem thêm</a></button>
                            <div className="img__description__cart__heart">
                                <button className="img__description img__description__cart"><a href="#"><i className="fa-solid fa-cart-shopping"></i></a></button>
                                <button className="img__description img__description__heart"><a href="#"><i className="fa-solid fa-heart"></i></a></button>
                            </div>
                        </div>
                    </div>
                </div>
                <a href="#" className="collection-title wrap-text">summer collection summer collection summer collection summer collection summer collection summer collection</a>
                <p className="collection-sku">SKU: HCB0001</p>
                <div className="discount-price-v2">
                    <span className="original-price">32.980.000đ</span>
                    <span className="percent-discount">-5%</span>
                </div>
                <span className="price-v2">29.996.000đ</span>
            </div>
            <div className="col-6 col-md-6 col-lg-3 collection-v2">
                <div className="img__wrap">
                    <img className="img-responsive img__img w-100" src={process.env.REACT_APP_PUBLIC_URL + "/images/prd6.png"} />
                    <div className="img__description_layer">
                        <div>
                            <button className="img__description img__description__mobile"><a href="#">Xem thêm</a></button>
                            <div className="img__description__cart__heart">
                                <button className="img__description img__description__cart"><a href="#"><i className="fa-solid fa-cart-shopping"></i></a></button>
                                <button className="img__description img__description__heart"><a href="#"><i className="fa-solid fa-heart"></i></a></button>
                            </div>
                        </div>
                    </div>
                </div>
                <a href="#" className="collection-title wrap-text">milano collection</a>
                <p className="collection-sku">SKU: HCB0002</p>
                <span className="price-v2">19.296.000đ</span>
            </div>
            <div className="col-6 col-md-6 col-lg-3 collection-v2">
                <div className="img__wrap">
                    <img className="img-responsive img__img w-100" src={process.env.REACT_APP_PUBLIC_URL + "/images/prd7.png"} />
                    <div className="img__description_layer">
                        <div>
                            <button className="img__description img__description__mobile"><a href="#">Xem thêm</a></button>
                            <div className="img__description__cart__heart">
                                <button className="img__description img__description__cart"><a href="#"><i className="fa-solid fa-cart-shopping"></i></a></button>
                                <button className="img__description img__description__heart"><a href="#"><i className="fa-solid fa-heart"></i></a></button>
                            </div>
                        </div>
                    </div>
                </div>
                <a href="#" className="collection-title wrap-text">yello collection</a>
                <p className="collection-sku">SKU: HCB0003</p>
                <span className="price-v2">27.296.000đ</span>
            </div>
            <div className="col-6 col-md-6 col-lg-3 collection-v2">
                <div className="img__wrap">
                    <img className="img-responsive img__img w-100" src={process.env.REACT_APP_PUBLIC_URL + "/images/prd8.png"} />
                    <span className="text-on-img text-new">New</span>
                    <div className="img__description_layer">
                        <div>
                            <button className="img__description img__description__mobile"><a href="#">Xem thêm</a></button>
                            <div className="img__description__cart__heart">
                                <button className="img__description img__description__cart"><a href="#"><i className="fa-solid fa-cart-shopping"></i></a></button>
                                <button className="img__description img__description__heart"><a href="#"><i className="fa-solid fa-heart"></i></a></button>
                            </div>
                        </div>
                    </div>
                </div>
                <a href="#" className="collection-title wrap-text">summer collection summer collection summer collection summer collection summer collection summer collection</a>
                <p className="collection-sku">SKU: HCB0001</p>
                <div className="discount-price-v2">
                    <span className="original-price">32.980.000đ</span>
                    <span className="percent-discount">-5%</span>
                </div>
                <span className="price-v2">29.996.000đ</span>
            </div>
            <div className="col-6 col-md-6 col-lg-3 collection-v2">
                <div className="img__wrap">
                    <img className="img-responsive img__img w-100" src={process.env.REACT_APP_PUBLIC_URL + "/images/prd9.png"} />
                    <div className="img__description_layer">
                        <div>
                            <button className="img__description img__description__mobile"><a href="#">Xem thêm</a></button>
                            <div className="img__description__cart__heart">
                                <button className="img__description img__description__cart"><a href="#"><i className="fa-solid fa-cart-shopping"></i></a></button>
                                <button className="img__description img__description__heart"><a href="#"><i className="fa-solid fa-heart"></i></a></button>
                            </div>
                        </div>
                    </div>
                </div>
                <a href="#" className="collection-title wrap-text">summer collection summer collection summer collection summer collection summer collection summer collection</a>
                <p className="collection-sku">SKU: HCB0001</p>
                <div className="discount-price-v2">
                    <span className="original-price">32.980.000đ</span>
                    <span className="percent-discount">-5%</span>
                </div>
                <span className="price-v2">29.996.000đ</span>
            </div>
            <div className="col-6 col-md-6 col-lg-3 collection-v2">
                <div className="img__wrap">
                    <img className="img-responsive img__img w-100" src={process.env.REACT_APP_PUBLIC_URL + "/images/prd10.png"} />
                    <div className="img__description_layer">
                        <div>
                            <button className="img__description img__description__mobile"><a href="#">Xem thêm</a></button>
                            <div className="img__description__cart__heart">
                                <button className="img__description img__description__cart"><a href="#"><i className="fa-solid fa-cart-shopping"></i></a></button>
                                <button className="img__description img__description__heart"><a href="#"><i className="fa-solid fa-heart"></i></a></button>
                            </div>
                        </div>
                    </div>
                </div>
                <a href="#" className="collection-title wrap-text">milano collection</a>
                <p className="collection-sku">SKU: HCB0002</p>
                <span className="price-v2">19.296.000đ</span>
            </div>
            <div className="col-6 col-md-6 col-lg-3 collection-v2">
                <div className="img__wrap">
                    <img className="img-responsive img__img w-100" src={process.env.REACT_APP_PUBLIC_URL + "/images/prd11.png"} />
                    <div className="img__description_layer">
                        <div>
                            <button className="img__description img__description__mobile"><a href="#">Xem thêm</a></button>
                            <div className="img__description__cart__heart">
                                <button className="img__description img__description__cart"><a href="#"><i className="fa-solid fa-cart-shopping"></i></a></button>
                                <button className="img__description img__description__heart"><a href="#"><i className="fa-solid fa-heart"></i></a></button>
                            </div>
                        </div>
                    </div>
                </div>
                <a href="#" className="collection-title wrap-text">yello collection</a>
                <p className="collection-sku">SKU: HCB0003</p>
                <span className="price-v2">27.296.000đ</span>
            </div>
            <div className="col-6 col-md-6 col-lg-3 collection-v2">
                <div className="img__wrap">
                    <img className="img-responsive img__img w-100" src={process.env.REACT_APP_PUBLIC_URL + "/images/prd1.png"} />
                    <div className="img__description_layer">
                        <div>
                            <button className="img__description img__description__mobile"><a href="#">Xem thêm</a></button>
                            <div className="img__description__cart__heart">
                                <button className="img__description img__description__cart"><a href="#"><i className="fa-solid fa-cart-shopping"></i></a></button>
                                <button className="img__description img__description__heart"><a href="#"><i className="fa-solid fa-heart"></i></a></button>
                            </div>
                        </div>
                    </div>
                </div>
                <a href="#" className="collection-title wrap-text">summer collection summer collection summer collection summer collection summer collection summer collection</a>
                <p className="collection-sku">SKU: HCB0001</p>
                <div className="discount-price-v2">
                    <span className="original-price">32.980.000đ</span>
                    <span className="percent-discount">-5%</span>
                </div>
                <span className="price-v2">29.996.000đ</span>
            </div>
        </div>
        <div className="row" style={{justifyContent: 'center'}}>
            <div className="load-more-prd-detail">
                <button className="btn">
                    Xem thêm
                </button>
            </div>
        </div>
    </div>
  )
}

function ViewedProductData(){
  return (
    <div id="viewed-products-v2" className="container-fluid padding-block-l-r-bt w-1150">
        <h4 className="title-viewed-product">Sản phẩm đã xem</h4>
        <div className="mhn-slide owl-carousel">
            <div className="mhn-item">
                <div className="mhn-inner">
                    <div className="mhn-prd-img">
                        <img src={process.env.REACT_APP_PUBLIC_URL + "/images/prd1.png"} />
                    </div>
                    <div className="mhn-img">
                        <div className="loader-circle">
                            <div className="loader-stroke-left"></div>
                            <div className="loader-stroke-right"></div>
                        </div>
                    </div>
                    <div className="mhn-text collection-v2">
                        <a href="#" className="collection-title wrap-text">summer collection summer collection summer collection summer collection summer collection summer collection</a>
                        <p className="collection-sku">SKU: HCB0001</p>
                        <div className="discount-price-v2">
                            <span className="original-price">32.980.000đ</span>
                            <span className="percent-discount">-5%</span>
                        </div>
                        <span className="price-v2">29.996.000đ</span>
                    </div>
                </div>
            </div>
            <div className="mhn-item">
                <div className="mhn-inner">
                    <div className="mhn-prd-img">
                        <img src={process.env.REACT_APP_PUBLIC_URL + "/images/prd2.png"} />
                    </div>
                    <div className="mhn-img">
                        <div className="loader-circle">
                            <div className="loader-stroke-left"></div>
                            <div className="loader-stroke-right"></div>
                        </div>
                    </div>
                    <div className="mhn-text collection-v2">
                        <a href="#" className="collection-title wrap-text">milano collection</a>
                        <p className="collection-sku">SKU: HCB0002</p>
                        <span className="price-v2">19.296.000đ</span>
                    </div>
                </div>
            </div>
            <div className="mhn-item">
                <div className="mhn-inner">
                    <div className="mhn-prd-img">
                        <img src={process.env.REACT_APP_PUBLIC_URL + "/images/prd3.png"} />
                    </div>
                    <div className="mhn-img">
                        <div className="loader-circle">
                            <div className="loader-stroke-left"></div>
                            <div className="loader-stroke-right"></div>
                        </div>
                    </div>
                    <div className="mhn-text collection-v2">
                        <a href="#" className="collection-title wrap-text">yello collection</a>
                        <p className="collection-sku">SKU: HCB0003</p>
                        <span className="price-v2">27.296.000đ</span>
                    </div>
                </div>
            </div>
            <div className="mhn-item">
                <div className="mhn-inner">
                    <div className="mhn-prd-img">
                        <img src={process.env.REACT_APP_PUBLIC_URL + "/images/prd4.png"} />
                    </div>
                    <div className="mhn-img">
                        <div className="loader-circle">
                            <div className="loader-stroke-left"></div>
                            <div className="loader-stroke-right"></div>
                        </div>
                    </div>
                    <div className="mhn-text collection-v2">
                        <a href="#" className="collection-title wrap-text">summer collection summer collection summer collection summer collection summer collection summer collection</a>
                        <p className="collection-sku">SKU: HCB0004</p>
                        <div className="discount-price-v2">
                            <span className="original-price">50.000.000đ</span>
                            <span className="percent-discount">-10%</span>
                        </div>
                        <span className="price-v2">45.000.000đ</span>
                    </div>
                </div>
            </div>
            <div className="mhn-item">
                <div className="mhn-inner">
                    <div className="mhn-prd-img">
                        <img src={process.env.REACT_APP_PUBLIC_URL + "/images/prd1.png"} />
                    </div>
                    <div className="mhn-img">
                        <div className="loader-circle">
                            <div className="loader-stroke-left"></div>
                            <div className="loader-stroke-right"></div>
                        </div>
                    </div>
                    <div className="mhn-text collection-v2">
                        <a href="#" className="collection-title wrap-text">summer collection summer collection summer collection summer collection summer collection summer collection</a>
                        <p className="collection-sku">SKU: HCB0001</p>
                        <div className="discount-price-v2">
                            <span className="original-price">32.980.000đ</span>
                            <span className="percent-discount">-5%</span>
                        </div>
                        <span className="price-v2">29.996.000đ</span>
                    </div>
                </div>
            </div>
            <div className="mhn-item">
                <div className="mhn-inner">
                    <div className="mhn-prd-img">
                        <img src={process.env.REACT_APP_PUBLIC_URL + "/images/prd2.png"} />
                    </div>
                    <div className="mhn-img">
                        <div className="loader-circle">
                            <div className="loader-stroke-left"></div>
                            <div className="loader-stroke-right"></div>
                        </div>
                    </div>
                    <div className="mhn-text collection-v2">
                        <a href="#" className="collection-title wrap-text">milano collection</a>
                        <p className="collection-sku">SKU: HCB0002</p>
                        <span className="price-v2">19.296.000đ</span>
                    </div>
                </div>
            </div>
            <div className="mhn-item">
                <div className="mhn-inner">
                    <div className="mhn-prd-img">
                        <img src={process.env.REACT_APP_PUBLIC_URL + "/images/prd3.png"} />
                    </div>
                    <div className="mhn-img">
                        <div className="loader-circle">
                            <div className="loader-stroke-left"></div>
                            <div className="loader-stroke-right"></div>
                        </div>
                    </div>
                    <div className="mhn-text collection-v2">
                        <a href="#" className="collection-title wrap-text">yello collection</a>
                        <p className="collection-sku">SKU: HCB0003</p>
                        <span className="price-v2">27.296.000đ</span>
                    </div>
                </div>
            </div>
            <div className="mhn-item">
                <div className="mhn-inner">
                    <div className="mhn-prd-img">
                        <img src={process.env.REACT_APP_PUBLIC_URL + "/images/prd4.png"} />
                    </div>
                    <div className="mhn-img">
                        <div className="loader-circle">
                            <div className="loader-stroke-left"></div>
                            <div className="loader-stroke-right"></div>
                        </div>
                    </div>
                    <div className="mhn-text collection-v2">
                        <a href="#" className="collection-title wrap-text">summer collection summer collection summer collection summer collection summer collection summer collection</a>
                        <p className="collection-sku">SKU: HCB0004</p>
                        <div className="discount-price-v2">
                            <span className="original-price">50.000.000đ</span>
                            <span className="percent-discount">-10%</span>
                        </div>
                        <span className="price-v2">45.000.000đ</span>
                    </div>
                </div>
            </div>
            <div className="mhn-item">
                <div className="mhn-inner">
                    <div className="mhn-prd-img">
                        <img src={process.env.REACT_APP_PUBLIC_URL + "/images/prd1.png"} />
                    </div>
                    <div className="mhn-img">
                        <div className="loader-circle">
                            <div className="loader-stroke-left"></div>
                            <div className="loader-stroke-right"></div>
                        </div>
                    </div>
                    <div className="mhn-text collection-v2">
                        <a href="#" className="collection-title wrap-text">summer collection summer collection summer collection summer collection summer collection summer collection</a>
                        <p className="collection-sku">SKU: HCB0001</p>
                        <div className="discount-price-v2">
                            <span className="original-price">32.980.000đ</span>
                            <span className="percent-discount">-5%</span>
                        </div>
                        <span className="price-v2">29.996.000đ</span>
                    </div>
                </div>
            </div>
            <div className="mhn-item">
                <div className="mhn-inner">
                    <div className="mhn-prd-img">
                        <img src={process.env.REACT_APP_PUBLIC_URL + "/images/prd2.png"} />
                    </div>
                    <div className="mhn-img">
                        <div className="loader-circle">
                            <div className="loader-stroke-left"></div>
                            <div className="loader-stroke-right"></div>
                        </div>
                    </div>
                    <div className="mhn-text collection-v2">
                        <a href="#" className="collection-title wrap-text">milano collection</a>
                        <p className="collection-sku">SKU: HCB0002</p>
                        <span className="price-v2">19.296.000đ</span>
                    </div>
                </div>
            </div>
            <div className="mhn-item">
                <div className="mhn-inner">
                    <div className="mhn-prd-img">
                        <img src={process.env.REACT_APP_PUBLIC_URL + "/images/prd3.png"} />
                    </div>
                    <div className="mhn-img">
                        <div className="loader-circle">
                            <div className="loader-stroke-left"></div>
                            <div className="loader-stroke-right"></div>
                        </div>
                    </div>
                    <div className="mhn-text collection-v2">
                        <a href="#" className="collection-title wrap-text">yello collection</a>
                        <p className="collection-sku">SKU: HCB0003</p>
                        <span className="price-v2">27.296.000đ</span>
                    </div>
                </div>
            </div>
            <div className="mhn-item">
                <div className="mhn-inner">
                    <div className="mhn-prd-img">
                        <img src={process.env.REACT_APP_PUBLIC_URL + "/images/prd4.png"} />
                    </div>
                    <div className="mhn-img">
                        <div className="loader-circle">
                            <div className="loader-stroke-left"></div>
                            <div className="loader-stroke-right"></div>
                        </div>
                    </div>
                    <div className="mhn-text collection-v2">
                        <a href="#" className="collection-title wrap-text">summer collection summer collection summer collection summer collection summer collection summer collection</a>
                        <p className="collection-sku">SKU: HCB0004</p>
                        <div className="discount-price-v2">
                            <span className="original-price">50.000.000đ</span>
                            <span className="percent-discount">-10%</span>
                        </div>
                        <span className="price-v2">45.000.000đ</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}