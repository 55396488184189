import HomepageBanner from "../Banner/HomepageBanner"
import Header from "../Header"
import DesignBlock from "../DesignBlock"
import HomepageCollection from "../Collection/HomepageCollection"
import HomepagePromotion from "../Promotion/HomepagePromotion"
import HomepageArticle from "../Article/HomepageArticle"
import HomepageProject from "../Project//HomepageProject"
import Service from "../Service"
import Newsletter from "../Newsletter"
import SocialSupportOnlineButtons from "../SocialSupportOnlineButtons"
import Footer from "../Footer"

export default function ChangePassword(){
  return (
    <div className="container-fluid p-0">
      <Header page="empty" />
      <section className="account-login-verification">
        <ChangePasswordContainer />
      </section>
      <Newsletter />
      <SocialSupportOnlineButtons />
      <Footer />
    </div>
    
  )
}

function ChangePasswordContainer(){
  return (
    <div id="login-v2" className="container w-1150 p-0">
        <div className="row">
            <div className="col-12 col-md-6">
                <div className="login-img">
                  <img src={process.env.REACT_APP_PUBLIC_URL + "/images/login.jfif"} alt="Login" />
                </div>
            </div>
            <div className="col-12 col-md-6">
                <div className="login-form account-verification">
                    <h6><strong>THAY ĐỔI MẬT KHẨU</strong></h6>
                    <form action="#">
                        <div className="profile-input profile-password">
                            <input type="password" name="password" id="password" className="password" />
                            <label className="placeholder-password">Mật khẩu (<span>*</span>)</label>
                            <div className="password-showhide">
                                <i className="show-password fa-solid fa-eye"></i>
                                <i className="hide-password fa-solid fa-eye-slash"></i>
                            </div>
                        </div>
                        <div className="profile-input profile-password">
                            <input type="password" name="password" id="password-again" className="password-again" />
                            <label className="placeholder-password-again">Nhập lại mật khẩu (<span>*</span>)</label>
                            <div className="password-showhide">
                                <i className="show-password fa-solid fa-eye"></i>
                                <i className="hide-password fa-solid fa-eye-slash"></i>
                            </div>
                        </div>
                        <button type="submit" className="btn action-forgot">GỬI</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
  )
}