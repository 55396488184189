export default function Article(props){
  const title = props.title
  const link = props.link
  const image = process.env.REACT_APP_PUBLIC_URL + "/images/" + props.image
  const published_at = props.published_at

  return (
    <div className="col-12 col-sm-4 article-v2">
      <a href={link}>
        <div className="img__wrap">
          <img className="img-responsive img__img w-100" src={image} />
          <div className="img__description_layer">
            <button className="img__description img__description__mobile">Xem thêm</button>
          </div>
        </div>
        <span className="wrap-text">{title}</span>
      </a>
      <p>{published_at}</p>
    </div>
  )
}