import HomepageBanner from "../Banner/HomepageBanner"
import Header from "../Header"
import DesignBlock from "../DesignBlock"
import HomepageCollection from "../Collection/HomepageCollection"
import HomepagePromotion from "../Promotion/HomepagePromotion"
import HomepageArticle from "../Article/HomepageArticle"
import HomepageProject from "../Project/HomepageProject"
import Service from "../Service"
import Newsletter from "../Newsletter"
import SocialSupportOnlineButtons from "../SocialSupportOnlineButtons"
import Footer from "../Footer"

export default function WarrantyPolicy(){
  return (
    <div className="container-fluid p-0">
      <Header page="warranty-policy" />
      <section className="checkout">
        <WarrantyPolicyBanner />
        <WarrantyPolicyContainer />
      </section>
      <Newsletter />
      <SocialSupportOnlineButtons />
      <Footer />
    </div>
    
  )
}

function WarrantyPolicyBanner(){
  return (
    <div id="carousel-v2">
        <div className="carousel slide" data-ride="carousel" data-interval="0">
            <div className="carousel-inner">
                <div className="carousel-item active">
                    <img className="d-block w-100 img-responsive" src={process.env.REACT_APP_PUBLIC_URL + "/images/warranty-policy.png"} alt="First slide" />
                </div>
            </div>
        </div>
    </div>
  )
}

function WarrantyPolicyContainer(){
  return (
    <div id="terms-of-use" className="container w-1150 padding-block-l-r-bt pt-70">
        <div className="row">
            <div className="col-12">
                <h1 className="terms-of-use-title">CHÍNH SÁCH GIAO HÀNG</h1>
            </div>
            <div className="col-12">
                <div className="table-responsive exchange">
                    <table className="table table-bordered">
                        <thead className="thead-dark">
                            <tr>
                                <th rowspan="2" scope="col">NHÓM</th>
                                <th rowspan="2" scope="col">KHOẢNG CÁCH KHU VỰC</th>
                                <th rowspan="2" scope="col">XE TẢI 2T/1CHUYẾN</th>
                                <th colspan="4">GHÉP XE</th>
                            </tr>
                            <tr>
                                <td>1 SOFA</td>
                                <td>1 ARMCHAIR</td>
                                <td>1 COFFEE TABLE</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td className="text-align-left">Hóc Môn, Thủ Đức, Quận 9, 12, Gò Vấp</td>
                                <td>700,000đ</td>
                                <td>350,000đ</td>
                                <td>200,000đ</td>
                                <td>100,000đ</td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td className="text-align-left">Nội Thành TPHCM (Quận 1, 2, 3, 4 ,5 ,6 ,7 ,8 ,10, 11, Bình Thạnh, Phú Nhuận, Tân Bình, Tân Phú, Bình Tân)</td>
                                <td>900,000đ</td>
                                <td>450,000đ</td>
                                <td>300,000đ</td>
                                <td>150,000đ</td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td className="text-align-left">Nhà Bè, Bình Chánh, Củ Chi</td>
                                <td>1,100,000đ</td>
                                <td>550,000đ</td>
                                <td>400,000đ</td>
                                <td>220,000đ</td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td className="text-align-left">Vũng Tàu</td>
                                <td>2,000,000đ</td>
                                <th className="bg-94D0D2 text-align-right" colspan="4">Khách hàng vui lòng liên hệ với nhân viên để được báo giá chi tiết</th>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td className="text-align-left">Các tỉnh khác (báo giá tùy trường hợp)</td>
                                <th className="bg-6EBFC2 text-align-right" colspan="5">Khách hàng vui lòng liên hệ với nhân viên để được báo giá chi tiết</th>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="col-12">
                <div className="note-custom">
                    LƯU Ý:<br />
                    Freeship các khu vực nội thành TP.HCM (nhóm 2) cho đơn hàng từ 30 triệu.<br />
                    Giá vận chuyển chưa gồm VAT.<br />
                    Khách Hàng vui lòng chuẩn bị giấy ra vào cổng/chuyển hàng (nếu có) và thanh toán các chi phí phát sinh liên quan đến việc giao nhận, trung chuyển hàng hóa theo quy định của tòa nhà, thang máy tại địa điểm tại nơi giao hàng của Khách Hàng. (Chung cư, tòa
                    nhà,...)
                </div>
            </div>
        </div>
    </div>
  )
}
