import HomepageBanner from "../Banner/HomepageBanner"
import Header from "../Header"
import DesignBlock from "../DesignBlock"
import HomepageCollection from "../Collection/HomepageCollection"
import HomepagePromotion from "../Promotion/HomepagePromotion"
import HomepageArticle from "../Article/HomepageArticle"
import HomepageProject from "../Project//HomepageProject"
import Service from "../Service"
import Newsletter from "../Newsletter"
import SocialSupportOnlineButtons from "../SocialSupportOnlineButtons"
import Footer from "../Footer"

export default function ForgotPassword(){
  return (
    <div className="container-fluid p-0">
      <Header page="empty" />
      <section className="forgot-password-page">
        <ForgotPasswordContainer />
      </section>
      <Newsletter />
      <SocialSupportOnlineButtons />
      <Footer />
    </div>
    
  )
}

function ForgotPasswordContainer(){
  return (
    <div id="login-v2" className="container w-1150 p-0">
        <div className="row">
            <div className="col-12 col-md-6">
                <div className="login-img">
                    <img src={process.env.REACT_APP_PUBLIC_URL + "/images/login.jfif"} alt="Login" />
                </div>
            </div>
            <div className="col-12 col-md-6">
                <div className="login-form">
                    <h6><strong>QUÊN MẬT KHẨU</strong></h6>
                    <form action="#">
                        <div className="profile-input profile-phone">
                            <input type="text" name="phone-number" id="phone-number" className="phone-number" />
                            <label className="placeholder-phone-number">Số điện thoại (<span>*</span>)</label>
                        </div>
                        <button type="submit" className="btn action-forgot">GỬI</button>
                        <a className="back-login" href="#">Trở lại đăng nhập?</a>
                    </form>
                </div>
            </div>
        </div>
    </div>
  )
}
