import Article from "./Article"
import { useState, useEffect } from "react"
import axios from "axios"

export default function HomepageArticle(){
  const [articles, setArticles] = useState([])

  useEffect(() => {
    axios.get("http://localhost:3000/data/articles.json")
      .then(res => {
        setArticles(res.data)
      })
      .catch(err => {
        console.log(err)
      })
  }, [])
  const articlesData = articles.map(item => {
      return (
        <Article
          key={item.id}
          {...item}
        />
      )
    }
  )
  return (
    <div id="article-v2" className="container-fluid w-1150">
      <div className="row">
        <div className="col-12">
          <h4 className="title-article-v2">ARTICLE</h4>
        </div>
        {articlesData}
      </div>
    </div>
  )
}