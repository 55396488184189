
export default function HomepagePromotion(){
  return (
    <div id="countdown-v2" className="container-fluid">
      <div className="row">
        <div className="col-12 col-md-6 p-0" id="img-countdown-v2">
          <img className="w-100 img-responsive" src={process.env.REACT_APP_PUBLIC_URL + "/images/khuyen_mai.jfif"} alt="" />
          <img className="img-vector" src={process.env.REACT_APP_PUBLIC_URL + "/images/Vector.png"} alt="" />
          <div className="price-vector">
            <span className="price-vector-1">1.996.000 VNĐ</span>
            <span className="price-vector-2">998.000</span>
            <span className="price-vector-3">VNĐ</span>
          </div>
        </div>
        <div className="col-12 col-md-6" id="content-countdown-v2">
          <div className="content-countdown-v2">
            <h5 className="title-countdown-v2">KHUYẾN MÃI ĐẶC BIỆT</h5>
            <h6 className="prod-countdown-v2">HEXCO CHAIR</h6>
            <p className="desc-countdown-v2">
              Chương trình mua chung với giá ưu đãi, được bắt đầu từ ngày <strong>19/12</strong><br/>đến
              <strong>25/12/2021</strong>.Với giá giảm <strong>50%</strong>.<br/>Dành cho <strong>50 đơn mua chung đầu
                tiên!</strong>
            </p>
            <div className="countdown-v2">
              <ol>
                <li>
                  <span className="countdown-time cd-day"></span>
                  <span className="countdown-date">ngày</span>
                </li>
                <li>
                  <span className="countdown-time cd-time"></span>
                  <span className="countdown-date">giờ</span>
                </li>
                <li>
                  <span className="countdown-time cd-minute"></span>
                  <span className="countdown-date">phút</span>
                </li>
                <li>
                  <span className="countdown-time cd-second"></span>
                  <span className="countdown-date">giây</span>
                </li>
              </ol>
            </div>
            <a href="#">
              <button className="countdown-btn">mua ngay</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}