import HomepageBanner from "../Banner/HomepageBanner"
import Header from "../Header"
import DesignBlock from "../DesignBlock"
import HomepageCollection from "../Collection/HomepageCollection"
import HomepagePromotion from "../Promotion/HomepagePromotion"
import HomepageArticle from "../Article/HomepageArticle"
import HomepageProject from "../Project/HomepageProject"
import Service from "../Service"
import Newsletter from "../Newsletter"
import SocialSupportOnlineButtons from "../SocialSupportOnlineButtons"
import Footer from "../Footer"

export default function Checkout(){
  return (
    <div className="container-fluid p-0">
      <Header page="checkout" />
      <section className="checkout">
        <CheckoutContainer />
      </section>
      <Newsletter />
      <SocialSupportOnlineButtons />
      <Footer />
    </div>
    
  )
}

function CheckoutContainer(){
  return (
    <div id="dat-hang" className="container w-1150 padding-block-l-r-bt pt-70">
        <div className="row">
            <div className="col-12 col-md-5 col-lg-6 custom-order-2">
                <form action="#">
                    <div className="order-left">
                        <h6 className="order-title order-border-bottom"><strong>ĐẶT HÀNG</strong></h6>
                        <div className="order-desc order-border-bottom">
                            Quý khách vui lòng <a href="#">đặt hàng</a> trước khi mua hàng để được hưởng các quyền lợi và ưu đãi dành cho thành viên!
                        </div>
                        {/* <!-- Thông tin khách hàng --> */}
                        <div className="order-block-info desc-info-cus order-border-bottom">
                            <strong className="order-block-title mb-10">Thông tin khách hàng</strong>
                            <input className="txt-info form-control mb-10" type="text" placeholder="Họ và tên (*)" />
                            <input className="txt-info form-control mb-10" type="text" placeholder="Số điện thoại (*)" />
                            <input className="txt-info form-control mb-25" type="text" placeholder="Email" />
                            <div className="d-flex-align">
                                <input className="txt-checkbox" type="checkbox" value="1" /><span className="txt-checkbox-str">Người khác nhận hàng</span>
                            </div>
                            <div className="other-order">
                                <input className="txt-info form-control mb-10 mt-10" type="text" placeholder="Họ và tên (*)" />
                                <input className="txt-info form-control mb-10" type="text" placeholder="Số điện thoại (*)" />
                                <input className="txt-info form-control" type="text" placeholder="Email" />
                            </div>
                        </div>
                        {/* <!-- Địa chỉ giao hàng --> */}
                        <div className="order-block-info desc-address-cus order-border-bottom">
                            <strong className="order-block-title mb-10">Địa chỉ giao hàng</strong>
                            <div className="row mr-0">
                                <div className="col-12 col-md-12 col-lg-6">
                                    <select className="select-info form-select mb-10" defaultValue={'-1'} >
                                        <option value="-1">Tỉnh/ Thành phố (*)</option>
                                        <option value="1">TP.HCM</option>
                                        <option value="2">TP.Hà Nội</option>
                                        <option value="2">TP.Đà Nẵng</option>
                                    </select>
                                </div>
                                <div className="col-12 col-md-12 col-lg-6">
                                    <select className="select-info form-select mb-10" defaultValue={'-1'} >
                                        <option value="-1">Quận/ Huyện (*)</option>
                                        <option value="1">Quận 1</option>
                                        <option value="2">Quận 2</option>
                                        <option value="2">Quận 3</option>
                                    </select>
                                </div>
                                <div className="col-12 col-md-12 col-lg-6">
                                    <select className="select-info form-select mb-10" defaultValue={'-1'} >
                                        <option value="-1">Phường/ Xã (*)</option>
                                        <option value="1">Bến Nghé</option>
                                        <option value="2">Bến Thành</option>
                                        <option value="2">Cô Giang</option>
                                    </select>
                                </div>
                                <div className="col-12 col-md-12 col-lg-6">
                                    <input className="txt-info form-control mb-10" type="text" placeholder="Số nhà/ Tên đường (*)" />
                                </div>
                            </div>
                            <textarea className="form-control" placeholder="Ghi chú"></textarea>
                        </div>
                        {/* <!-- Chọn hình thức thanh toán --> */}
                        <div className="order-block-info desc-payment order-border-bottom">
                            <strong className="order-block-title mb-10">Chọn hình thức thanh toán</strong>
                            <div className="row mr-0 mb-25">
                                <div className="col-12 col-md-12 col-lg-4 payment-block-bg">
                                    <div className="payment-block unactive">
                                        <img className="img-responsive mb-10 payment-unactive" src={process.env.REACT_APP_PUBLIC_URL + "/images/dat-hang/momo.png"} alt="" />
                                        <img className="img-responsive mb-10 payment-active" src={process.env.REACT_APP_PUBLIC_URL + "/images/dat-hang/momo-active.png"} alt="" />
                                        <p className="title-payment mb-0"><strong>Ví MoMo</strong></p>
                                        <p className="mb-0 fz-14">Bạn phải có tài khoản MoMo</p>
                                    </div>
                                </div>
                                <div className="col-12 col-md-12 col-lg-4 payment-block-bg">
                                    <div className="payment-block unactive">
                                        <img className="img-responsive mb-10 payment-unactive" src={process.env.REACT_APP_PUBLIC_URL + "/images/dat-hang/bank.png"} alt="" />
                                        <img className="img-responsive mb-10 payment-active" src={process.env.REACT_APP_PUBLIC_URL + "/images/dat-hang/bank-active.png"} alt="" />
                                        <p className="title-payment mb-0"><strong>Internet Banking</strong></p>
                                    </div>
                                </div>
                                <div className="col-12 col-md-12 col-lg-4 payment-block-bg">
                                    <div className="payment-block unactive">
                                        <img className="img-responsive mb-10 payment-unactive" src={process.env.REACT_APP_PUBLIC_URL + "/images/dat-hang/credit.png"} alt="" />
                                        <img className="img-responsive mb-10 payment-active" src={process.env.REACT_APP_PUBLIC_URL + "/images/dat-hang/credit-active.png"} alt="" />
                                        <p className="title-payment mb-0"><strong>Thẻ tín dụng/Thẻ ghi nợ</strong></p>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex-align">
                                <input className="txt-checkbox" type="checkbox" value="2" /><span className="txt-checkbox-str">Xuất hoá đơn VAT</span>
                            </div>
                            {/* <!-- Xuất hoá đơn VAT --> */}
                            <div className="invoice-infor">
                                <div className="other-vat">
                                    <strong className="order-block-title mb-10">Thông tin xuất hoá đơn</strong>
                                    <input className="txt-info form-control mb-10 mt-10" type="text" placeholder="Tên công ty (*)" />
                                    <input className="txt-info form-control mb-10" type="text" placeholder="Mã số thuế (*)" />
                                    <input className="txt-info form-control" type="text" placeholder="Địa chỉ công ty (*)" />
                                </div>
                                <div className="other-vat-info">
                                    <strong className="order-block-title mb-10">Thông tin liên lạc</strong>
                                    <input className="txt-info form-control mb-10 mt-10" type="text" placeholder="Họ và tên (*)" />
                                    <input className="txt-info form-control mb-10" type="text" placeholder="Số điện thoại (*)" />
                                    <input className="txt-info form-control" type="text" placeholder="Email nhận HĐ điện tử (*)" />
                                </div>
                            </div>
                        </div>
                        <div className="order-block-info">
                            <div className="d-flex-align mb-20">
                                <input className="txt-checkbox" type="checkbox" value="3" /><span className="txt-checkbox-str">Mã nhân viên tư vấn</span>
                            </div>
                            <input className="txt-info form-control mb-50" type="text" placeholder="Mã cộng tác viên" />
                            <button type="submit" className="btn btn-submit-order">HOÀN TẤT ĐẶT HÀNG</button>
                        </div>
                    </div>
                </form>
            </div>
            <div className="col-12 col-md-7 col-lg-6 custom-order-1">
                <div className="order-prds">
                    <h6 className="order-prd-title p-15-20 mb-0">Thông tin đơn hàng</h6>
                    <h6 className="order-prd-title p-15-20 mb-0 border-0 pb-0">3 Items</h6>
                    <div className="list-order-items">
                        <div className="row list-order-item m-0">
                            <div className="col-3 col-md-3 p-0">
                                <div className="bg-img">
                                    <img className="img-responsive" src={process.env.REACT_APP_PUBLIC_URL + "/images/dat-hang/prd1.png"} alt="" />
                                </div>
                            </div>
                            <div className="col-6 col-md-6 pr-10 pl-10">
                                <p><span className="prd-qty">1</span><span className="prd-x">X</span><span className="prd-name">BABY COLLECTION</span></p>
                                <p><span className="prd-sku">SKU: HCSF028. 16.-F.V114</span></p>
                                <p><span className="prd-color">Pink tone</span></p>
                                <a className="prd-detail" href="#">Xem chi tiết</a>
                            </div>
                            <div className="col-3 col-md-3 p-0 text-align-right">
                                <p><span className="prd-price-sale">48.670.000đ</span></p>
                                <p><span className="prd-price fz-14">50.670.000đ</span></p>
                                <a className="prd-delete" href="#">Xoá</a>
                            </div>
                            <div className="sub-list-order-item w-100">
                                <div className="row child-order-item m-0 mb-10">
                                    <div className="col-1 col-md-1 p-0"></div>
                                    <div className="col-2 col-md-2 p-0">
                                        <div className="bg-img">
                                            <img className="img-responsive" src={process.env.REACT_APP_PUBLIC_URL + "/images/dat-hang/sub-sp1.png"} alt="" />
                                        </div>
                                    </div>
                                    <div className="col-9 col-md-9 pl-10">
                                        <p><span className="prd-qty">1</span><span className="prd-x">X</span><span className="prd-name">ORLA</span></p>
                                        <p><span className="prd-sku">SKU: HCSF028. 16.-F.V114 W1600 - D800 - H800 mm</span></p>
                                    </div>
                                </div>
                                <div className="row child-order-item m-0 mb-10">
                                    <div className="col-1 col-md-1 p-0"></div>
                                    <div className="col-2 col-md-2 p-0">
                                        <div className="bg-img">
                                            <img className="img-responsive" src={process.env.REACT_APP_PUBLIC_URL + "/images/dat-hang/sub-sp2.png"} alt="" />
                                        </div>
                                    </div>
                                    <div className="col-9 col-md-9 pl-10">
                                        <p><span className="prd-qty">1</span><span className="prd-x">X</span><span className="prd-name">ORLA</span></p>
                                        <p><span className="prd-sku">SKU: HCSF028. 16.-F.V114 W1600 - D800 - H800 mm</span></p>
                                    </div>
                                </div>
                                <div className="row child-order-item m-0 mb-10">
                                    <div className="col-1 col-md-1 p-0"></div>
                                    <div className="col-2 col-md-2 p-0">
                                        <div className="bg-img">
                                            <img className="img-responsive" src={process.env.REACT_APP_PUBLIC_URL + "/images/dat-hang/sub-sp3.png"} alt="" />
                                        </div>
                                    </div>
                                    <div className="col-9 col-md-9 pl-10">
                                        <p><span className="prd-qty">1</span><span className="prd-x">X</span><span className="prd-name">CIRCLE</span></p>
                                        <p><span className="prd-sku">SKU: HCSF028. 16.-F.V114 W1600 - D800 - H800 mm</span></p>
                                    </div>
                                </div>
                                <div className="row child-order-item m-0 mb-10">
                                    <div className="col-1 col-md-1 p-0"></div>
                                    <div className="col-2 col-md-2 p-0">
                                        <div className="bg-img">
                                            <img className="img-responsive" src={process.env.REACT_APP_PUBLIC_URL + "/images/dat-hang/sub-sp4.png"} alt="" />
                                        </div>
                                    </div>
                                    <div className="col-9 col-md-9 pl-10">
                                        <p><span className="prd-qty">1</span><span className="prd-x">X</span><span className="prd-name">SHARE</span></p>
                                        <p><span className="prd-sku">SKU: HCSF028. 16.-F.V114 W1600 - D800 - H800 mm</span></p>
                                    </div>
                                </div>
                                <div className="row child-order-item m-0 mb-10">
                                    <div className="col-1 col-md-1 p-0"></div>
                                    <div className="col-2 col-md-2 p-0">
                                        <div className="bg-img">
                                            <img className="img-responsive" src={process.env.REACT_APP_PUBLIC_URL + "/images/dat-hang/sub-sp1.png"} alt="" />
                                        </div>
                                    </div>
                                    <div className="col-9 col-md-9 pl-10">
                                        <p><span className="prd-qty">1</span><span className="prd-x">X</span><span className="prd-name">ORLA</span></p>
                                        <p><span className="prd-sku">SKU: HCSF028. 16.-F.V114 W1600 - D800 - H800 mm</span></p>
                                    </div>
                                </div>
                                <div className="row child-order-item m-0 mb-10">
                                    <div className="col-1 col-md-1 p-0"></div>
                                    <div className="col-2 col-md-2 p-0">
                                        <div className="bg-img">
                                            <img className="img-responsive" src={process.env.REACT_APP_PUBLIC_URL + "/images/dat-hang/sub-sp2.png"} alt="" />
                                        </div>
                                    </div>
                                    <div className="col-9 col-md-9 pl-10">
                                        <p><span className="prd-qty">1</span><span className="prd-x">X</span><span className="prd-name">ORLA</span></p>
                                        <p><span className="prd-sku">SKU: HCSF028. 16.-F.V114 W1600 - D800 - H800 mm</span></p>
                                    </div>
                                </div>
                                <div className="row child-order-item m-0 mb-10">
                                    <div className="col-1 col-md-1 p-0"></div>
                                    <div className="col-2 col-md-2 p-0">
                                        <div className="bg-img">
                                            <img className="img-responsive" src={process.env.REACT_APP_PUBLIC_URL + "/images/dat-hang/sub-sp3.png"} alt="" />
                                        </div>
                                    </div>
                                    <div className="col-9 col-md-9 pl-10">
                                        <p><span className="prd-qty">1</span><span className="prd-x">X</span><span className="prd-name">CIRCLE</span></p>
                                        <p><span className="prd-sku">SKU: HCSF028. 16.-F.V114 W1600 - D800 - H800 mm</span></p>
                                    </div>
                                </div>
                                <div className="row child-order-item m-0 mb-10">
                                    <div className="col-1 col-md-1 p-0"></div>
                                    <div className="col-2 col-md-2 p-0">
                                        <div className="bg-img">
                                            <img className="img-responsive" src={process.env.REACT_APP_PUBLIC_URL + "/images/dat-hang/sub-sp4.png"} alt="" />
                                        </div>
                                    </div>
                                    <div className="col-9 col-md-9 pl-10">
                                        <p><span className="prd-qty">1</span><span className="prd-x">X</span><span className="prd-name">SHARE</span></p>
                                        <p><span className="prd-sku">SKU: HCSF028. 16.-F.V114 W1600 - D800 - H800 mm</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="list-order-item">
                            <div className="row gr-order-item m-0">
                                <div className="col-3 col-md-3 p-0">
                                    <div className="bg-img">
                                        <img className="img-responsive" src={process.env.REACT_APP_PUBLIC_URL + "/images/dat-hang/prd2.png"} alt="" />
                                    </div>
                                </div>
                                <div className="col-6 col-md-6 pr-10 pl-10">
                                    <p><span className="prd-qty">1</span><span className="prd-x">X</span><span className="prd-name">SILLY SOFA</span></p>
                                    <p><span className="prd-sku">SKU: HCSF028</span></p>
                                    <p><span className="prd-color">Leather</span></p>
                                </div>
                                <div className="col-3 col-md-3 p-0 text-align-right">
                                    <p><span className="prd-price-sale">18.800.000đ</span></p>
                                    <a className="prd-delete" href="#">Xoá</a>
                                </div>
                            </div>
                            <div className="row gr-order-item m-0">
                                <div className="col-3 col-md-3 p-0">
                                    <div className="bg-img">
                                        <img className="img-responsive" src={process.env.REACT_APP_PUBLIC_URL + "/images/dat-hang/prd3.png"} alt="" />
                                    </div>
                                </div>
                                <div className="col-6 col-md-6 pr-10 pl-10">
                                    <p><span className="prd-qty">1</span><span className="prd-x">X</span><span className="prd-name">GỐI DA</span></p>
                                    <p><span className="prd-sku">SKU: H11</span></p>
                                    <p><span className="prd-color">Leather</span></p>
                                </div>
                                <div className="col-3 col-md-3 p-0 text-align-right">
                                    <p><span className="prd-price-sale prd-price-string">Tặng kèm</span></p>
                                    <a className="prd-delete" href="#">Xoá</a>
                                </div>
                            </div>
                        </div>
                        <form className="discount-code d-flex-align" action="#">
                            <input className="txt-info form-control mr-15" type="text" placeholder="Mã giảm giá" />
                            <button className="btn">ÁP DỤNG</button>
                        </form>
                        <div className="block-total-amount">
                            <div className="total-amount total-amount-prd">
                                <span className="title-amount">Tổng tiền hàng</span>
                                <span className="prd-amount">66.800.000đ</span>
                            </div>
                            <div className="total-amount promotion-prd">
                                <span className="title-amount">Khuyến mãi</span>
                                <span className="prd-amount">2.000.000đ</span>
                            </div>
                            <div className="total-amount transport-fee">
                                <span className="title-amount">Phí vận chuyển</span>
                                <span className="prd-amount">2.000.000đ</span>
                            </div>
                        </div>
                        <div className="sum-total">
                            <span className="title-amount">Tổng cộng</span>
                            <span className="prd-amount">64.800.000đ</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
