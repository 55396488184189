import HomepageBanner from "../Banner/HomepageBanner"
import Header from "../Header"
import DesignBlock from "../DesignBlock"
import HomepageCollection from "../Collection/HomepageCollection"
import HomepagePromotion from "../Promotion/HomepagePromotion"
import HomepageArticle from "../Article/HomepageArticle"
import HomepageProject from "../Project//HomepageProject"
import Service from "../Service"
import Newsletter from "../Newsletter"
import SocialSupportOnlineButtons from "../SocialSupportOnlineButtons"
import Footer from "../Footer"

export default function Aboutus(){
  return (
    <div className="container-fluid p-0">
      <Header page="gioi-thieu" />
      <section className="about-us">
        <AboutBanner />
        <AboutContent />
      </section>
      <Newsletter />
      <SocialSupportOnlineButtons />
      <Footer />
    </div>
    
  )
}

function AboutBanner(){
  return (
    <div id="carousel-v2">
        <div className="carousel slide" data-bs-ride="carousel" data-bs-interval="0">
            <div className="carousel-inner">
                <div className="carousel-item active">
                    <img className="d-block w-100 img-responsive" src={process.env.REACT_APP_PUBLIC_URL + "/images/introduce.png"} alt="First slide" />
                </div>
            </div>
        </div>
    </div>
  )
}

function AboutContent(){
  return (
    <div id="introduce" className="container w-1150 padding-block-l-r-bt pt-70">
        <div className="row">
            <div className="col-12 col-md-6 col-lg-6 col-xl-4">
                <div className="introduce-img">
                    <img className="img-responsive" src={process.env.REACT_APP_PUBLIC_URL + "/images/project/sp2.png"} alt="" />
                </div>
            </div>
            <div className="col-12 col-md-6 col-lg-6 col-xl-8">
                <div className="introduce-content">
                    <h1 className="introduce-title">GIỚI THIỆU VỀ HOMECLICK.VN</h1>
                    <div className="introduce-desc">
                        <p>Khi cuộc cách mạng công nghệ 4.0 đang dần thay đổi thói quen, hành vi mua sắm truyền thống của mỗi chúng ta thì đại dịch Covid-19 như một tác động “thúc đẩy” không chỉ việc mua sắm mà còn là học tập, làm việc,… cần chuyển đổi sang
                            hình thức trực tuyến nhanh hơn để thích nghi, tồn tại và phát triển. Trong ngành nội thất - xây dựng cũng không ngoại lệ, khi một thị trường có quá nhiều sự chọn lựa như ngày nay, thông tin thiếu kiểm duyệt tràn lan trên mạng
                            internet thì người chịu thiệt thòi và rủi ro nhiều nhất lại chính là người tiêu dùng. Nhiều hình ảnh & thông tin sản phẩm đưa lên chưa phản ánh đúng sự thật mà còn được đánh bóng quá mức để “đẹp mắt” người xem và những trang
                            bán hàng trực tuyến như thế đang “sinh sôi, nảy nở” mỗi ngày, mỗi giờ với tốc độ không ngừng. Vì thế việc mua sắm trực tuyến đối với họ là cả một vấn đề, không chỉ liên quan đến kiến thức, công nghệ mà quan trọng nhất là nhận
                            diện ra trang bán hàng nào uy tín. Vì thế Homeclick.vn ra đời với sứ mệnh ĐEM LẠI SỰ AN TÂM TRONG MỖI LỰA CHỌN cho người tiêu dùng trong việc tìm kiếm những món đồ nội thất chất lượng với giá cả hợp lý.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}