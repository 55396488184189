export default function SocialSupportOnlineButtons(){
  return (
    <div className="social-online">
      <div id="btn-social" className="call-in-action">
        <div className="action-blocks">
          <div className="action-blocks-container">
            <ul className="action-blocks-list">
              <li>
                <a href="https://m.me/Homeclick.vn" className="hover-change-icon" title="Tư vấn qua Facebook" target="_blank"
                  rel="noopener nofollow">
                  <div className="block-image-icon">
                    <div className="ecep-ico ecep-cia-fb-messenger img-hover"></div>
                    <div className="ecep-ico ecep-cia-fb-messenger-hover un-img-hover"></div>
                  </div>
                  <div className="block-label">Tư vấn qua Facebook</div>
                </a>
              </li>
              <li>
                <a href="https://zalo.me/1486109805359143033" className="hover-change-icon" title="Tư vấn qua Zalo"
                  target="_blank" rel="noopener nofollow">
                  <div className="block-image-icon">
                    <div className="ecep-ico ecep-cia-zalo img-hover"></div>
                    <div className="ecep-ico ecep-cia-zalo-hover un-img-hover"></div>
                  </div>
                  <div className="block-label">Tư vấn qua Zalo</div>
                </a>
              </li>
              <li>
                <a href="tel:0936900319" className="hover-change-icon" title="Gọi điện trực tiếp" target="_self"
                  rel="noopener nofollow">
                  <div className="block-image-icon">
                    <div className="ecep-ico ecep-cia-phone img-hover"></div>
                    <div className="ecep-ico ecep-cia-phone-hover un-img-hover"></div>
                  </div>
                  <div className="block-label">Gọi điện trực tiếp</div>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="action-button">
          <div className="action-button-close hide-button">
            <a id="cia-action-button-close" target="_self" title="Đóng" rel="noopener nofollow">
              <div className="action-icon">
                <div className="ecep-ico ecep-cia-close"></div>
              </div>
            </a>
          </div>
          <div className="action-button-open">
            <a id="cia-action-button-open" target="_self" title="Tư vấn trực tuyến" rel="noopener nofollow">
              <div className="action-label">Tư vấn trực tuyến</div>
              <div className="action-icon">
                <div className="ecep-ico ecep-cia-collapse"></div>
              </div>
            </a>
          </div>
        </div>
      </div>
      <img id="btn-back-to-top" src={process.env.REACT_APP_PUBLIC_URL + "/images/icon/scoll up.png"} alt=""></img>
    </div>
  )
}