import './App.css'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Routes
} from "react-router-dom"

import NotFound from './components/Pages/NotFound'
import UseScript from './components/Hooks/UseScript'
import Homepage from './components/Pages/Homepage'
import Aboutus from './components/Pages/Aboutus'
import VerifyAccount from './components/Pages/VerifyAccount'
import ChangePassword from './components/Pages/ChangePassword'
import Products from './components/Pages/Products'
import ProductDetail from './components/Pages/ProductDetail'
import ExchangePolicy from './components/Pages/ExchangePolicy'
import ForgotPassword from './components/Pages/ForgotPassword'
import Login from './components/Pages/Login'
import Checkout from './components/Pages/Checkout'
import CheckoutSuccess from './components/Pages/CheckoutSuccess'
import PaymentGuide from './components/Pages/PaymentGuide'
import PrivacyPolicy from './components/Pages/PrivacyPolicy'
import ShoppingGuide from './components/Pages/ShoppingGuide'
import ProjectDetail from './components/Pages/ProjectDetail'
import Project from './components/Pages/Project'
import Register from './components/Pages/Register'
import TermsOfUse from './components/Pages/TermsOfUse'
import WarrantyPolicy from './components/Pages/WarrantyPolicy'

function App() {
  // load useScript
  UseScript(
    [
      process.env.REACT_APP_PUBLIC_URL + '/js/jquery.min.js',
      // process.env.REACT_APP_PUBLIC_URL + '/js/jquery-3.2.1.slim.min.js',
      process.env.REACT_APP_PUBLIC_URL + '/js/jquery-ui.min.js',
      process.env.REACT_APP_PUBLIC_URL + '/js/popper.min.js',
      process.env.REACT_APP_PUBLIC_URL + '/bootstrap/js/bootstrap.min.js',
      process.env.REACT_APP_PUBLIC_URL + '/bootstrap/js/bootstrap.bundle.min.js',
      process.env.REACT_APP_PUBLIC_URL + '/js/owl.carousel.min.js',
      process.env.REACT_APP_PUBLIC_URL + '/js/fotorama.js',
      process.env.REACT_APP_PUBLIC_URL + '/js/main.js',
      process.env.REACT_APP_PUBLIC_URL + '/js/login.js',
      process.env.REACT_APP_PUBLIC_URL + '/js/design.js',
      process.env.REACT_APP_PUBLIC_URL + '/js/filter_price.js',
      process.env.REACT_APP_PUBLIC_URL + '/js/viewed_products.js',
      process.env.REACT_APP_PUBLIC_URL + '/js/countdown.js',
      process.env.REACT_APP_PUBLIC_URL + '/js/autocomplete.js',
      process.env.REACT_APP_PUBLIC_URL + '/js/dat-hang.js'
    ]
  )

  return (
    <Routes>
      <Route exact path="/" element={ <Homepage /> } />
      <Route path="/gioi-thieu" element={ <Aboutus /> } />
      <Route path="/xac-thuc-tai-khoan" element={ <VerifyAccount /> } />
      <Route path="/thay-doi-mat-khau" element={ <ChangePassword /> } />
      <Route path="/danh-muc-san-pham" element={ <Products /> } />
      <Route path="/chinh-sach-doi-tra-bao-hanh" element={ <ExchangePolicy /> } />
      <Route path="/san-pham/:nameKey" element={ <ProductDetail /> } />
      <Route path="/quen-mat-khau" element={ <ForgotPassword /> } />
      <Route path="/dang-nhap" element={ <Login /> } />
      <Route path="/dang-ky" element={ <Register /> } />
      <Route path="/dat-hang" element={ <Checkout /> } />
      <Route path="/du-an" element={ <Project /> } />
      <Route path="/du-an/:nameKey" element={ <ProjectDetail /> } />
      <Route path="/dat-hang-thanh-cong" element={ <CheckoutSuccess /> } />
      <Route path="/huong-dan-thanh-toan" element={ <PaymentGuide /> } />
      <Route path="/chinh-sach-bao-mat" element={ <PrivacyPolicy /> } />
      <Route path="/huong-dan-mua-hang" element={ <ShoppingGuide /> } />
      <Route path="/dieu-khoan-su-dung" element={ <TermsOfUse /> } />
      <Route path="/chinh-sach-giao-hang" element={ <WarrantyPolicy /> } />
      <Route path="*" element={ <NotFound /> } />
    </Routes>
  );
}

export default App;