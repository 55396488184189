import HomepageBanner from "../Banner/HomepageBanner"
import Header from "../Header"
import DesignBlock from "../DesignBlock"
import HomepageCollection from "../Collection/HomepageCollection"
import HomepagePromotion from "../Promotion/HomepagePromotion"
import HomepageArticle from "../Article/HomepageArticle"
import HomepageProject from "../Project/HomepageProject"
import Service from "../Service"
import Newsletter from "../Newsletter"
import SocialSupportOnlineButtons from "../SocialSupportOnlineButtons"
import Footer from "../Footer"

export default function Register(){
  return (
    <div className="container-fluid p-0">
      <Header page="register" />
      <section className="checkout">
        <RegisterContainer />
      </section>
      <Newsletter />
      <SocialSupportOnlineButtons />
      <Footer />
    </div>
    
  )
}

function RegisterContainer(){
  return (
    <div id="register-v2" class="container w-1150 p-0">
        <div class="row">
            <div class="col-12 col-md-6">
                <div class="login-img">
                    <img src={process.env.REACT_APP_PUBLIC_URL + "/images/login.jfif"} alt="Login" />
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="login-form register-form">
                    <h6><strong>ĐĂNG KÝ THÀNH VIÊN HOMECLICK.VN</strong></h6>
                    <form action="#">
                        <div class="profile-input profile-fullname">
                            <input type="text" name="fullname" id="fullname" class="fullname" />
                            <label class="placeholder-fullname">Họ và tên (<span>*</span>)</label>
                        </div>
                        <div class="profile-input profile-phone">
                            <input type="text" name="phone-number" id="phone-number" class="phone-number" />
                            <label class="placeholder-phone-number">Số điện thoại (<span>*</span>)</label>
                        </div>
                        <div class="profile-input profile-password">
                            <input type="password" name="password" id="password" class="password" />
                            <label class="placeholder-password">Mật khẩu (<span>*</span>)</label>
                            <div class="password-showhide">
                                <i class="show-password fa-solid fa-eye"></i>
                                <i class="hide-password fa-solid fa-eye-slash"></i>
                            </div>
                        </div>
                        <div class="profile-input profile-password">
                            <input type="password" name="password" id="password-again" class="password-again" />
                            <label class="placeholder-password-again">Nhập lại mật khẩu (<span>*</span>)</label>
                            <div class="password-showhide">
                                <i class="show-password fa-solid fa-eye"></i>
                                <i class="hide-password fa-solid fa-eye-slash"></i>
                            </div>
                            <span class="hidden-error">Vui lòng nhập lại mật khẩu</span>
                        </div>
                        <div class="remember-forgot">
                            <div class="remember-login">
                                <input type="checkbox" name="remember-login" id="remember-login" />
                                <span>Tôi đã đọc Điều khoản sử dụng và đồng ý với nội dung này</span>
                            </div>
                        </div>
                        <button type="submit" class="btn action-register">ĐĂNG KÝ</button>
                        <span class="create-acc">Bạn đã có tài khoản? Vui lòng <a href="#"> Đăng nhập</a></span>
                    </form>
                </div>
            </div>
        </div>
    </div>
  )
}
