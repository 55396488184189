import HomepageBanner from "../Banner/HomepageBanner"
import Header from "../Header"
import DesignBlock from "../DesignBlock"
import HomepageCollection from "../Collection/HomepageCollection"
import HomepagePromotion from "../Promotion/HomepagePromotion"
import HomepageArticle from "../Article/HomepageArticle"
import HomepageProject from "../Project/HomepageProject"
import Service from "../Service"
import Newsletter from "../Newsletter"
import SocialSupportOnlineButtons from "../SocialSupportOnlineButtons"
import Footer from "../Footer"

export default function ShoppingGuide(){
  return (
    <div className="container-fluid p-0">
      <Header page="shopping-guide" />
      <section className="checkout">
        <ShoppingGuideBanner />
        <ShoppingGuideContainer />
      </section>
      <Newsletter />
      <SocialSupportOnlineButtons />
      <Footer />
    </div>
    
  )
}

function ShoppingGuideBanner(){
  return (
    <div id="carousel-v2">
        <div className="carousel slide" data-ride="carousel" data-interval="0">
            <div className="carousel-inner">
                <div className="carousel-item active">
                    <img className="d-block w-100 img-responsive" src={process.env.REACT_APP_PUBLIC_URL + "/images/product-buying.png"} alt="First slide" />
                </div>
            </div>
        </div>
    </div>
  )
}

function ShoppingGuideContainer(){
  return (
    <div id="terms-of-use" className="container w-1150 padding-block-l-r-bt pt-70">
        <div className="row">
            <div className="col-12">
                <h1 className="terms-of-use-title">Hướng dẫn mua hàng</h1>
            </div>
            <div className="col-12">
                <div className="product-buying">
                    <p className="step">Bước 1: Chọn sản phẩm</p>
                    <p>Bạn có thể chọn sản phẩm bằng 1 trong 2 cách sau:</p>
                    <br />
                    <div className="away">
                        <p className="step">Cách 1:</p>
                        <p>
                            Gõ tên hoặc mã sản phẩm hoặc cả tên và mã sản phẩm trong “ô tìm kiếm” rồi enter hoặc nhấn chọn “biểu tượng tìm kiếm”
                        </p>
                        <p>Ví dụ: Sofa 2 chỗ</p>
                    </div>
                    <img className="img-responsive" src={process.env.REACT_APP_PUBLIC_URL + "/images/prd-buy/huong-dan-mua-hang-01.png"} alt="" />
                    <div className="away">
                        <p className="step">Cách 2:</p>
                        <p>
                            Từ danh mục sản phẩm, bạn chọn ngành hàng có sản phẩm cần mua
                        </p>
                        <p>Ví dụ: Sofa giường</p>
                    </div>
                    <img className="img-responsive" src={process.env.REACT_APP_PUBLIC_URL + "/images/prd-buy/huong-dan-mua-hang-02.png"} alt="" />
                </div>
                <div className="product-buying">
                    <p>
                        Từ danh sách sản phẩm hiển thị chọn nút “Thêm vào giỏ hàng” ứng với sản phẩm bạn muốn mua. Nếu muốn mua số lượng nhiều hơn 1 sản phẩm thì bạn nhấn vào nút “Thêm vào giỏ hàng” số lần tương ứng. Trên biểu tượng giỏ hàng sẽ hiển thị tổng số lượng sản phẩm
                        bạn đã chọn mua.<br /> Bạn có thể nhấn vào hình hoặc tên sản phẩm để xem thông tin chi tiết trước khi “Thêm vào giỏ hàng”
                    </p>
                    <img className="img-responsive" src={process.env.REACT_APP_PUBLIC_URL + "/images/prd-buy/huong-dan-mua-hang-03.png"} alt="" />
                </div>
                <div className="product-buying">
                    <p>
                        Nếu bạn xem thông tin chi tiết và muốn đặt mua sản phẩm đã chọn, bạn có thể nhấn nút “Mua ngay” thay vì “thêm vào giỏ hàng”
                    </p>
                    <img className="img-responsive" src={process.env.REACT_APP_PUBLIC_URL + "/images/prd-buy/huong-dan-mua-hang-04.png"} alt="" />
                </div>
                <div className="product-buying">
                    <p className="step">Bước 2: Đặt hàng</p>
                    Di chuyển chuột đến biểu tượng giỏ hàng bạn sẽ thấy được danh sách sản phẩm đã chọn, tại đây bạn có thể:<br />
                    Điều chỉnh số lượng đối với mỗi sản phẩm đã chọn<br />
                    Hoặc xóa sản phẩm ra khỏi giỏ hàng nếu bạn không chọn nữa<br />
                    Và chọn “tiến hành đặt hàng”<br />
                    <img className="img-responsive" src={process.env.REACT_APP_PUBLIC_URL + "/images/prd-buy/huong-dan-mua-hang-05.png"} alt="" />
                </div>
                <div className="product-buying">
                    <p className="content-step">
                        Nếu chọn “Tiến hành đặt hàng” trang đặt hàng sẽ hiện ra (hình minh họa bên dưới)
                    </p>
                </div>
                <div className="product-buying">
                    <div className="content-step">
                        <strong className="content-step-title">Để hoàn tất việc đặt hàng, bạn nên và cần:</strong>
                        <div className="mr-0-10">
                            <strong>1. Kiểm tra thông tin đơn hàng</strong><br />
                            <div className="sub-content-step">
                                Bạn vui lòng kiểm tra tên, model và số lượng sản phẩm đặt mua<br />
                                Tại đây bạn có thể:<br />
                                <ul>
                                    <li>
                                        Điều chỉnh số lượng ứng với mỗi sp sản phẩm chọn mua
                                    </li>
                                    <li>
                                        Hoặc xóa sản phẩm ra khỏi giỏ hàng (không chọn nữa)
                                    </li>
                                    <li>
                                        Trường hợp bạn có mã giảm giá/khuyến mãi, thì nhấn chọn “Bạn có mã giảm giá/khuyến mãi?” và nhập mã giảm giá vào ô textbox hiển thị ngay bên dưới
                                    </li>
                                    <li>
                                        Kiểm tra tổng tiền hàng, phí giao hàng, tổng cộng trị giá đơn hàng
                                    </li>
                                </ul>
                                Thời gian giao hàng dự kiến là thời gian tối đa bạn có thể nhận được hàng<br />
                            </div>
                            <div className="mb-20"></div>

                            Lưu ý:<br />
                            <div className="sub-content-step">
                                Bạn nên đăng nhập trước khi chọn “Hoàn tất đặt hàng” hoặc đăng ký trở thành thành viên để được hưởng đầy đủ quyền lợi và ưu đãi dành cho thành viên<br />
                                Trường hợp bạn cần xem lại thông tin sản phẩm thì nhấn chọn vào sản phẩm cần xem<br />
                            </div>
                            <strong>2. Cung cấp thông tin người mua hàng</strong>
                            <div className="sub-content-step">
                                <ul>
                                    <li>
                                        Họ và tên người mua hàng (*)
                                    </li>
                                    <li>
                                        Số điện thoại người mua hàng (*)
                                    </li>
                                </ul>
                            </div>
                            Lưu ý:<br />
                            <div className="sub-content-step">
                                Bạn có thể cung cấp địa chỉ email của bạn để thuận tiện cho việc nhận thông tin đơn hàng và tiến độ giao hàng.<br />
                            </div>
                            <strong>3. Bạn có thể nhờ người khác nhận hàng giúp bạn bằng cách</strong><br />
                            <div className="sub-content-step">

                                Nhấp chọn “Nhận hàng là người khác” và cung cấp thông tin:<br />
                                <ul>
                                    <li>
                                        Họ và tên người nhận hàng (*)
                                    </li>
                                    <li>
                                        Số điện thoại người nhận hàng (*)
                                    </li>
                                </ul>
                            </div>
                            Lưu ý:<br />
                            <div className="sub-content-step">
                                Nhân viên giao hàng chỉ liên hệ trực tiếp với người nhận hàng được cung cấp thông tin trong trường hợp này
                            </div>
                            <strong>4. Cung cấp địa chỉ giao hàng đầy đủ và chính xác, bao gồm</strong>
                            <div className="sub-content-step">
                                <ul>
                                    <li>
                                        Tỉnh/thành phố (*)
                                    </li>
                                    <li>
                                        Quận/huyện (*)
                                    </li>
                                    <li>
                                        Phường/xã (*)
                                    </li>
                                    <li>
                                        Số nhà và tên đường (*)
                                    </li>
                                    <li>
                                        Bạn có thể ghi những yêu cầu hoặc lưu ý cho nhân viên giao hàng trong ô “Ghi chú”
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <img className="img-responsive" src={process.env.REACT_APP_PUBLIC_URL + "/images/prd-buy/huong-dan-mua-hang-07.png"} alt="" />
                </div>
                <div className="product-buying">
                    <div className="content-step">
                        <div className="mr-0-10">
                            <strong>5. Hình thức thanh toán:</strong><br />
                            <div className="sub-content-step">
                                <ul>
                                    <li>
                                        Quý khách vui lòng chuyển khoản đặt cọc 50% giá trị đơn hàng trong vòng 24 giờ kể từ khi đặt hàng.
                                    </li>
                                    <li>
                                        Nội dung thanh toán: [Số đơn hàng] _ [Số điện thoại đặt hàng].
                                    </li>
                                </ul>
                            </div>
                            CÔNG TY TNHH DB GROUP không chịu trách nhiệm nếu bạn chuyển cho 1 công ty hoặc 1 cá nhân nào khác.<br />
                            <div className="mb-20"></div>
                            <strong>6. Yêu cầu xuất hóa đơn</strong><br />
                            Nếu bạn là công ty/doanh nghiệp hoặc bạn đại diện công ty để mua hàng thì bạn có thể chọn “Yêu cầu xuất hóa đơn” và cung cấp cho chúng tôi các thông tin chính xác về:<br />
                            <div className="sub-content-step">
                                <ul>
                                    <li>
                                        Tên công ty
                                    </li>
                                    <li>
                                        Địa chỉ
                                    </li>
                                    <li>
                                        Mã số thuế
                                    </li>
                                    <li>
                                        Email nhận hóa đơn điện tử
                                    </li>
                                </ul>
                            </div>
                            <div className="mb-20"></div>
                            Lưu ý:<br />
                            Giá bán trên hóa đơn sẽ đúng với giá bán thể hiện trên đơn hàng<br />
                            Quý khách sẽ nhận được hóa đơn điện tử tối đa trong vòng 7 ngày kể từ ngày nhận hàng<br />
                            Nếu có thông tin chưa đúng, bạn vui lòng liên hệ với chúng tôi để được giải đáp và điều chỉnh kịp thời<br />
                            <div className="mb-20"></div>
                            <strong>Bước 3: Hoàn tất đặt hàng</strong><br />
                            Sau khi kiểm tra và cung cấp đầy đủ thông tin bắt buộc (thông tin có dấu *, được chúng tôi liệt kê trong hướng dẫn này) thì bạn chọn “ Hoàn tất đặt hàng”<br />
                            Sẽ có 1 thông báo hiển thị trên màn hình xác nhận cho bạn kết quả đặt hàng.<br />
                            Bạn cũng đồng thời nhận được thông tin đơn hàng, mã đơn hàng qua:<br />
                            <div className="sub-content-step">
                                <ul>
                                    <li>
                                        Email, nếu bạn có cung cấp địa chỉ email cho chúng tôi trong phần thông tin người mua
                                    </li>
                                    <li>
                                        Tin nhắn, bằng số điện thoại của bạn đã cung cấp.
                                    </li>
                                </ul>
                            </div>
                            <div className="mb-20"></div>
                            Bạn có thể theo dõi tiến độ giao hàng trực tiếp trên <a href="#">homeclick.vn</a>. Nhân viên sẽ liên hệ với bạn để xác nhận thanh toán và thông tin đơn hàng.<br />
                            <div className="mb-20"></div>

                            <strong>CÔNG TY TNHH DB GROUP</strong><br />
                            Địa chỉ: 68 Nguyễn Huệ, Phường Bến Nghé, Quận 1, TP.Hồ Chí Minh<br />
                            Điện thoại: 028 2212 0027<br />
                            Email: <a href="#">info@homeclick.vn</a><br />
                            Website: <a href="#">https://homeclick.vn</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
