import HomepageBanner from "../Banner/HomepageBanner"
import Header from "../Header"
import DesignBlock from "../DesignBlock"
import HomepageCollection from "../Collection/HomepageCollection"
import HomepagePromotion from "../Promotion/HomepagePromotion"
import HomepageArticle from "../Article/HomepageArticle"
import HomepageProject from "../Project/HomepageProject"
import Service from "../Service"
import Newsletter from "../Newsletter"
import SocialSupportOnlineButtons from "../SocialSupportOnlineButtons"
import Footer from "../Footer"

export default function Project(){
  return (
    <div className="container-fluid p-0">
      <Header page="project" />
      <section className="checkout">
        <ProjectBanner />
        <ProjectContainer />
      </section>
      <Newsletter />
      <SocialSupportOnlineButtons />
      <Footer />
    </div>
    
  )
}

function ProjectBanner(){
  return (
    <div id="carousel-v2">
        <div className="carousel slide" data-ride="carousel" data-interval="0">
            <div className="carousel-inner">
                <div className="carousel-item active">
                    <img className="d-block w-100 img-responsive" src={process.env.REACT_APP_PUBLIC_URL + "/images/project/project.png"} alt="First slide" />
                </div>
            </div>
        </div>
    </div>
  )
}

function ProjectContainer(){
  return (
    <div>
      <div id="filter-v2" className="container w-1150 padding-block-l-r-bt mb-0 pb-0">
          <div className="row">
              <div className="col-12">
                  <h4 className="title-filter-v2 mb-25">dự án</h4>
                  <p className="sub-desc-title mb-20">Sự phát triển vượt trội của những dự án cao tầng thì việc thiết kế nội thất chung cư cao cấp cũng đang dẫn được chú trọng nhiều hơn nhằm hướng đến một không gian sống thẩm mỹ và tiện nghi. Nếu ban đang sở hữu một căn hộ chung cư và còn
                      băn khoăn không biết trang trí như thế nào? Hãy tham khảo các mẫu thiết kế nội thất chung cư đẹp do Homeclick chọn lọc sau đây để tìm ra những ý tưởng phù hợp nhất!</p>
              </div>
              <div className="view-btn-filter">
                  <div className="btn-dropdown">
                      <div id="dropMenuFilter01" className="dropdown" data-value="Địa điểm" data-map="dropMenuFilter01">
                          <button className="btn" type="button" id="dropFilter01" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <span>Địa điểm</span> <i className="fa-solid fa-angle-down"></i>
                      </button>
                          <div className="dropdown-menu" aria-labelledby="dropFilter01">
                              <a className="dropdown-item item-filter-remove" data-value="TP. HCM">TP. HCM</a>
                              <a className="dropdown-item item-filter-remove" data-value="TP. Hà Nội">TP. Hà Nội</a>
                              <a className="dropdown-item item-filter-remove" data-value="TP. Đà Nẵng">TP. Đà Nẵng</a>
                              <a className="dropdown-item item-filter-remove" data-value="TP. Nha Trang">TP. Nha Trang</a>
                              <a className="dropdown-item item-filter-remove" data-value="TP. Hội An">TP. Hội An</a>
                              <a className="action-dropdown item-see-more active">Xem thêm</a>
                              <div className="load-more-item">
                                  <a className="dropdown-item item-filter-remove" data-value="TP. HCM">TP. HCM</a>
                                  <a className="dropdown-item item-filter-remove" data-value="TP. Hà Nội">TP. Hà Nội</a>
                                  <a className="dropdown-item item-filter-remove" data-value="TP. Đà Nẵng">TP. Đà Nẵng</a>
                                  <a className="dropdown-item item-filter-remove" data-value="TP. Nha Trang">TP. Nha Trang</a>
                                  <a className="dropdown-item item-filter-remove" data-value="TP. Hội An">TP. Hội An</a>
                                  <a className="dropdown-item item-filter-remove" data-value="TP. HCM">TP. HCM</a>
                                  <a className="dropdown-item item-filter-remove" data-value="TP. Hà Nội">TP. Hà Nội</a>
                                  <a className="dropdown-item item-filter-remove" data-value="TP. Đà Nẵng">TP. Đà Nẵng</a>
                                  <a className="dropdown-item item-filter-remove" data-value="TP. Nha Trang">TP. Nha Trang</a>
                                  <a className="dropdown-item item-filter-remove" data-value="TP. Hội An">TP. Hội An</a>
                                  <a className="dropdown-item item-filter-remove" data-value="TP. HCM">TP. HCM</a>
                                  <a className="dropdown-item item-filter-remove" data-value="TP. Hà Nội">TP. Hà Nội</a>
                                  <a className="dropdown-item item-filter-remove" data-value="TP. Đà Nẵng">TP. Đà Nẵng</a>
                                  <a className="dropdown-item item-filter-remove" data-value="TP. Nha Trang">TP. Nha Trang</a>
                                  <a className="dropdown-item item-filter-remove" data-value="TP. Hội An">TP. Hội An</a>
                              </div>
                              <a className="action-dropdown item-collapse">Thu gọn</a>
                              <div className="sub-dropdown-menu">
                              </div>
                          </div>
                      </div>
                      <div id="dropMenuFilter02" className="dropdown" data-value="Loại công trình" data-map="dropMenuFilter02">
                          <button className="btn" type="button" id="dropFilter02" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <span>Loại công trình</span> <i className="fa-solid fa-angle-down"></i>
                          </button>
                          <div className="dropdown-menu" aria-labelledby="dropFilter02">
                              <a className="dropdown-item item-filter-remove" data-value="Loại công trình 01">Loại công trình 01</a>
                              <a className="dropdown-item item-filter-remove" data-value="Loại công trình 02">Loại công trình 02</a>
                              <a className="dropdown-item item-filter-remove" data-value="Loại công trình 03">Loại công trình 03</a>
                              <a className="dropdown-item item-filter-remove" data-value="Loại công trình 04">Loại công trình 04</a>
                              <a className="dropdown-item item-filter-remove" data-value="Loại công trình 05">Loại công trình 05</a>
                              <a className="action-dropdown item-see-more active">Xem thêm</a>
                              <div className="load-more-item">
                                  <a className="dropdown-item item-filter-remove" data-value="Loại công trình 01">Loại công trình 01</a>
                                  <a className="dropdown-item item-filter-remove" data-value="Loại công trình 02">Loại công trình 02</a>
                                  <a className="dropdown-item item-filter-remove" data-value="Loại công trình 03">Loại công trình 03</a>
                                  <a className="dropdown-item item-filter-remove" data-value="Loại công trình 04">Loại công trình 04</a>
                                  <a className="dropdown-item item-filter-remove" data-value="Loại công trình 05">Loại công trình 05</a>
                                  <a className="dropdown-item item-filter-remove" data-value="Loại công trình 01">Loại công trình 01</a>
                                  <a className="dropdown-item item-filter-remove" data-value="Loại công trình 02">Loại công trình 02</a>
                                  <a className="dropdown-item item-filter-remove" data-value="Loại công trình 03">Loại công trình 03</a>
                                  <a className="dropdown-item item-filter-remove" data-value="Loại công trình 04">Loại công trình 04</a>
                                  <a className="dropdown-item item-filter-remove" data-value="Loại công trình 05">Loại công trình 05</a>
                                  <a className="dropdown-item item-filter-remove" data-value="Loại công trình 01">Loại công trình 01</a>
                                  <a className="dropdown-item item-filter-remove" data-value="Loại công trình 02">Loại công trình 02</a>
                                  <a className="dropdown-item item-filter-remove" data-value="Loại công trình 03">Loại công trình 03</a>
                                  <a className="dropdown-item item-filter-remove" data-value="Loại công trình 04">Loại công trình 04</a>
                                  <a className="dropdown-item item-filter-remove" data-value="Loại công trình 05">Loại công trình 05</a>
                              </div>
                              <a className="action-dropdown item-collapse">Thu gọn</a>
                              <div className="sub-dropdown-menu">
                                  {/* <!-- call ajax --> */}
                              </div>
                          </div>
                      </div>
                      <div id="dropMenuFilter03" className="dropdown" data-value="Tên dự án" data-map="dropMenuFilter03">
                          <button className="btn" type="button" id="dropFilter02" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <span>Tên dự án</span> <i className="fa-solid fa-angle-down"></i>
                          </button>
                          <div className="dropdown-menu" aria-labelledby="dropFilter02">
                              <a className="dropdown-item item-filter-remove" data-value="Tên dự án 01">Tên dự án 01</a>
                              <a className="dropdown-item item-filter-remove" data-value="Tên dự án 02">Tên dự án 02</a>
                              <a className="dropdown-item item-filter-remove" data-value="Tên dự án 03">Tên dự án 03</a>
                              <a className="dropdown-item item-filter-remove" data-value="Tên dự án 04">Tên dự án 04</a>
                              <a className="dropdown-item item-filter-remove" data-value="Tên dự án 05">Tên dự án 05</a>
                              <a className="action-dropdown item-see-more active">Xem thêm</a>
                              <div className="load-more-item">
                                  <a className="dropdown-item item-filter-remove" data-value="Tên dự án 01">Tên dự án 01</a>
                                  <a className="dropdown-item item-filter-remove" data-value="Tên dự án 02">Tên dự án 02</a>
                                  <a className="dropdown-item item-filter-remove" data-value="Tên dự án 03">Tên dự án 03</a>
                                  <a className="dropdown-item item-filter-remove" data-value="Tên dự án 04">Tên dự án 04</a>
                                  <a className="dropdown-item item-filter-remove" data-value="Tên dự án 05">Tên dự án 05</a>
                                  <a className="dropdown-item item-filter-remove" data-value="Tên dự án 01">Tên dự án 01</a>
                                  <a className="dropdown-item item-filter-remove" data-value="Tên dự án 02">Tên dự án 02</a>
                                  <a className="dropdown-item item-filter-remove" data-value="Tên dự án 03">Tên dự án 03</a>
                                  <a className="dropdown-item item-filter-remove" data-value="Tên dự án 04">Tên dự án 04</a>
                                  <a className="dropdown-item item-filter-remove" data-value="Tên dự án 05">Tên dự án 05</a>
                                  <a className="dropdown-item item-filter-remove" data-value="Tên dự án 01">Tên dự án 01</a>
                                  <a className="dropdown-item item-filter-remove" data-value="Tên dự án 02">Tên dự án 02</a>
                                  <a className="dropdown-item item-filter-remove" data-value="Tên dự án 03">Tên dự án 03</a>
                                  <a className="dropdown-item item-filter-remove" data-value="Tên dự án 04">Tên dự án 04</a>
                                  <a className="dropdown-item item-filter-remove" data-value="Tên dự án 05">Tên dự án 05</a>
                              </div>
                              <a className="action-dropdown item-collapse">Thu gọn</a>
                              <div className="sub-dropdown-menu">
                                  {/* <!-- call ajax --> */}
                              </div>
                          </div>
                      </div>
                      <div id="dropMenuFilter04" className="dropdown" data-value="Chủ đầu tư" data-map="dropMenuFilter04">
                          <button className="btn" type="button" id="dropFilter02" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <span>Chủ đầu tư</span> <i className="fa-solid fa-angle-down"></i>
                          </button>
                          <div className="dropdown-menu" aria-labelledby="dropFilter02">
                              <a className="dropdown-item item-filter-remove" data-value="Chủ đầu tư 01">Chủ đầu tư 01</a>
                              <a className="dropdown-item item-filter-remove" data-value="Chủ đầu tư 02">Chủ đầu tư 02</a>
                              <a className="dropdown-item item-filter-remove" data-value="Chủ đầu tư 03">Chủ đầu tư 03</a>
                              <a className="dropdown-item item-filter-remove" data-value="Chủ đầu tư 04">Chủ đầu tư 04</a>
                              <a className="dropdown-item item-filter-remove" data-value="Chủ đầu tư 05">Chủ đầu tư 05</a>
                              <a className="action-dropdown item-see-more active">Xem thêm</a>
                              <div className="load-more-item">
                                  <a className="dropdown-item item-filter-remove" data-value="Chủ đầu tư 01">Chủ đầu tư 01</a>
                                  <a className="dropdown-item item-filter-remove" data-value="Chủ đầu tư 02">Chủ đầu tư 02</a>
                                  <a className="dropdown-item item-filter-remove" data-value="Chủ đầu tư 03">Chủ đầu tư 03</a>
                                  <a className="dropdown-item item-filter-remove" data-value="Chủ đầu tư 04">Chủ đầu tư 04</a>
                                  <a className="dropdown-item item-filter-remove" data-value="Chủ đầu tư 05">Chủ đầu tư 05</a>
                                  <a className="dropdown-item item-filter-remove" data-value="Chủ đầu tư 01">Chủ đầu tư 01</a>
                                  <a className="dropdown-item item-filter-remove" data-value="Chủ đầu tư 02">Chủ đầu tư 02</a>
                                  <a className="dropdown-item item-filter-remove" data-value="Chủ đầu tư 03">Chủ đầu tư 03</a>
                                  <a className="dropdown-item item-filter-remove" data-value="Chủ đầu tư 04">Chủ đầu tư 04</a>
                                  <a className="dropdown-item item-filter-remove" data-value="Chủ đầu tư 05">Chủ đầu tư 05</a>
                                  <a className="dropdown-item item-filter-remove" data-value="Chủ đầu tư 01">Chủ đầu tư 01</a>
                                  <a className="dropdown-item item-filter-remove" data-value="Chủ đầu tư 02">Chủ đầu tư 02</a>
                                  <a className="dropdown-item item-filter-remove" data-value="Chủ đầu tư 03">Chủ đầu tư 03</a>
                                  <a className="dropdown-item item-filter-remove" data-value="Chủ đầu tư 04">Chủ đầu tư 04</a>
                                  <a className="dropdown-item item-filter-remove" data-value="Chủ đầu tư 05">Chủ đầu tư 05</a>
                              </div>
                              <a className="action-dropdown item-collapse">Thu gọn</a>
                              <div className="sub-dropdown-menu">
                                  {/* <!-- call ajax --> */}
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div id="content-filter" className="content-filter">
              <div className="list-btn-filter"></div>
              {/* <!-- <div>
                  <button className="btn btnDeleteAllFilter">Xoá tất cả</button>
              </div> --> */}
          </div>
      </div>

      <div id="list-item-project" className="container w-1150 padding-block-l-r-bt">
          <div className="list-item-project">
              <div className="row">
                  <div className="col-12 col-md-6 col-lg-6 col-xl-5 p-0">
                      <div className="project-img">
                          <a href="#">
                              <img className="img-responsive" src={process.env.REACT_APP_PUBLIC_URL + "/images/project/sp1.png"} alt="" />
                          </a>
                      </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6 col-xl-7 p-0">
                      <div className="project-info">
                          <a href="#">
                              <h1 className="project-title">DỰ ÁN MŨI NÉ SUMMER LAND RESORT HCM</h1>
                          </a>
                          <span><strong>Chủ đầu tư: </strong> Công ty TNHH Raemian</span>
                          <span><strong>Qui mô: </strong> 14.990 m2</span>
                          <span><strong>Số lượng: </strong> 13.200 căn hộ</span>
                      </div>
                  </div>
              </div>
              <div className="row">
                  <div className="col-12 col-md-6 col-lg-6 col-xl-5 p-0">
                      <div className="project-img">
                          <a href="#">
                              <img className="img-responsive" src={process.env.REACT_APP_PUBLIC_URL + "/images/project/sp2.png"} alt="" />
                          </a>
                      </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6 col-xl-7 p-0">
                      <div className="project-info">
                          <a href="#">
                              <h1 className="project-title">DỰ ÁN MŨI NÉ SUMMER LAND RESORT HCM</h1>
                          </a>
                          <span><strong>Chủ đầu tư: </strong> Công ty TNHH Raemian</span>
                          <span><strong>Qui mô: </strong> 14.990 m2</span>
                          <span><strong>Số lượng: </strong> 13.200 căn hộ</span>
                      </div>
                  </div>
              </div>
              <div className="row">
                  <div className="col-12 col-md-6 col-lg-6 col-xl-5 p-0">
                      <div className="project-img">
                          <a href="#">
                              <img className="img-responsive" src={process.env.REACT_APP_PUBLIC_URL + "/images/project/sp3.png"} alt="" />
                          </a>
                      </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6 col-xl-7 p-0">
                      <div className="project-info">
                          <a href="#">
                              <h1 className="project-title">DỰ ÁN MŨI NÉ SUMMER LAND RESORT HCM</h1>
                          </a>
                          <span><strong>Chủ đầu tư: </strong> Công ty TNHH Raemian</span>
                          <span><strong>Qui mô: </strong> 14.990 m2</span>
                          <span><strong>Số lượng: </strong> 13.200 căn hộ</span>
                      </div>
                  </div>
              </div>
              <div className="row">
                  <div className="col-12 col-md-6 col-lg-6 col-xl-5 p-0">
                      <div className="project-img">
                          <a href="#">
                              <img className="img-responsive" src={process.env.REACT_APP_PUBLIC_URL + "/images/project/sp1.png"} alt="" />
                          </a>
                      </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6 col-xl-7 p-0">
                      <div className="project-info">
                          <a href="#">
                              <h1 className="project-title">DỰ ÁN MŨI NÉ SUMMER LAND RESORT HCM</h1>
                          </a>
                          <span><strong>Chủ đầu tư: </strong> Công ty TNHH Raemian</span>
                          <span><strong>Qui mô: </strong> 14.990 m2</span>
                          <span><strong>Số lượng: </strong> 13.200 căn hộ</span>
                      </div>
                  </div>
              </div>
              <div className="row">
                  <div className="load-more-prd-detail">
                      <button className="btn">
                          Xem thêm
                      </button>
                  </div>
              </div>
          </div>
      </div>

      <div id="request-counselling" className="container-fluid mb-70">
          <div className="row">
              <div className="col-12 col-md-6 p-0">
                  <div className="counselling-img">
                      <img className="img-responsive" src={process.env.REACT_APP_PUBLIC_URL + "/images/project/tu_van.png"} alt="" />
                  </div>
              </div>
              <div className="col-12 col-md-6 p-0">
                  <div className="counselling-info">
                      <h1 className="counselling-title">yêu cầu tư vấn</h1>
                      <span>Để hỗ trợ cho việc tư vấn, xin bạn hãy điền thông tin dưới đây, các tư vấn viên của HOMECLICK sẽ nhanh chóng liên lạc với bạn</span>
                      <button className="btn">BẮT ĐẦU</button>
                  </div>
              </div>
          </div>
      </div>
    </div>
  )
}
