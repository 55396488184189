
export default function Footer(){
  return (
    <div id="footer-v2" className="container-fluid">
      <div className="w-1150">
        <div className="footer-img">
          <img className="d-block mw-200" src={process.env.REACT_APP_PUBLIC_URL + "/images/homeclick logo-04.png"} alt="" />
        </div>
        <div className="footer-content">
          <div className="footer-row footer-content-1">
            <ul className="nav flex-column nav_accordion_footer">
              <li className="nav-item">
                <p className="collapsed footer-row-title" href="#submenu_footer1" data-toggle="collapse"
                  data-target="#submenu_footer1"> Thông tin công ty <i className="fa-solid fa-angle-down"></i></p>
                <div className="collapse submenu" id="submenu_footer1" aria-expanded="false">
                  <ul className="flex-column nav">
                    <li className="nav-item"><a href={process.env.REACT_APP_PUBLIC_URL + "/gioi-thieu"}><span> Giới thiệu </span></a></li>
                    <li className="nav-item"><a href="#"><span> Tuyển dụng </span></a></li>
                    <li className="nav-item">
                      <p className="footer-row-title footer-row-hotline" href="#"><span> HOTLINE </span></p>
                    </li>
                    <li className="nav-item"><a className="footer-hotline" href="#"><span> 0936 900 319 </span></a></li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
          <div className="footer-row footer-content-2">
            <ul className="nav flex-column nav_accordion_footer">
              <li className="nav-item">
                <p className="collapsed footer-row-title" href="#submenu_footer2" data-toggle="collapse"
                  data-target="#submenu_footer2"> Hỗ trợ khách hàng <i className="fa-solid fa-angle-down"></i></p>
                <div className="collapse submenu" id="submenu_footer2" aria-expanded="false">
                  <ul className="flex-column nav">
                    <li className="nav-item"><a href="#"><span> Hướng dẫn mua hàng </span></a></li>
                    <li className="nav-item"><a href="#"><span> Hướng dẫn thanh toán </span></a></li>
                    <li className="nav-item"><a href="#"><span> Chính sách khách hàng thân thiết </span></a></li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
          <div className="footer-row footer-content-3">
            <ul className="nav flex-column nav_accordion_footer">
              <li className="nav-item">
                <p className="collapsed footer-row-title" href="#submenu_footer3" data-toggle="collapse"
                  data-target="#submenu_footer3"> Chính sách bán hàng <i className="fa-solid fa-angle-down"></i></p>
                <div className="collapse submenu" id="submenu_footer3" aria-expanded="false">
                  <ul className="flex-column nav">
                    <li className="nav-item"><a href="#"><span> Điều khoản sử dụng </span></a></li>
                    <li className="nav-item"><a href="#"><span> Chính sách đổi, trả & bảo hành </span></a></li>
                    <li className="nav-item"><a href="#"><span> Chính sách giao hàng </span></a></li>
                    <li className="nav-item"><a href="#"><span> Chính sách bảo mật </span></a></li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
          <div className="footer-row footer-content-4">
            <ul className="nav flex-column nav_accordion_footer">
              <li className="nav-item">
                <p className="collapsed footer-row-title" href="#submenu_footer4" data-toggle="collapse"
                  data-target="#submenu_footer4"> Liên hệ <i className="fa-solid fa-angle-down"></i></p>
                <div className="collapse submenu" id="submenu_footer4" aria-expanded="false">
                  <ul className="flex-column nav">
                    <li className="nav-item"><a href="#"><span> 25/6A Nhị Bình 2, Nhị Bình, Hóc Môn, Tp. Hồ Chí Minh
                        </span></a></li>
                    <li className="nav-item"><a href="#"><span> info@homeclick.vn </span></a></li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
          <div className="footer-row footer-content-5">
            <ul className="nav flex-column nav_accordion_footer">
              <li className="nav-item">
                <p className="footer-row-title"> CỘNG TÁC VIÊN </p>
                <div className="footer-mobile-btn">
                  <ul className="flex-column nav">
                    <li className="nav-item"><button><a href="#">Đăng ký cộng tác viên</a></button></li>
                    <li className="nav-item"><button><a href="#">Đăng nhập cộng tác viên</a></button></li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer-content mb-0">
          <div className="footer-row footer-content-1-1"></div>
          <div className="footer-row footer-content-5-1">
            <ul className="nav flex-column nav_accordion_footer">
              <li className="nav-item">
                <p className="footer-row-title"> CỘNG TÁC VIÊN </p>
                <div className="footer-mobile-btn">
                  <ul className="flex-column nav">
                    <li className="nav-item"><button><a href="#">Đăng ký cộng tác viên</a></button></li>
                    <li className="nav-item"><button><a href="#">Đăng nhập cộng tác viên</a></button></li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-9 footer-bottom-content">
            <div className="footer-bottom">
              <p>homeclick.vn - Trang mua sắm chuyên ngành NỘI THẤT </p>
              <p>© 2021 Công Ty TNHH DB Group GPDKKD số 0314299260 do sở KHĐT Tp. Hồ Chí Minh cấp 21/03/2017</p>
              <p>Đăng ký trụ sở chính: 68 Nguyễn Huệ, Phường Bến Nghé, Quận 1, TP.Hồ Chí Minh - Điện thoại: 0936 900 319 -
                Email: info@homeclick.vn</p>
            </div>
          </div>
          <div className="col-sm-12 col-md-3 footer-bottom-img">
            <div className="footer-bottom">
              <a href="#">
                <img className="mw-150" src={process.env.REACT_APP_PUBLIC_URL + "/images/bo_cong_thuong.png"} alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}