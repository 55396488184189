import { useParams } from "react-router-dom";

import HomepageBanner from "../Banner/HomepageBanner"
import Header from "../Header"
import DesignBlock from "../DesignBlock"
import HomepageCollection from "../Collection/HomepageCollection"
import HomepagePromotion from "../Promotion/HomepagePromotion"
import HomepageArticle from "../Article/HomepageArticle"
import HomepageProject from "../Project//HomepageProject"
import Service from "../Service"
import Newsletter from "../Newsletter"
import SocialSupportOnlineButtons from "../SocialSupportOnlineButtons"
import Footer from "../Footer"

export default function NotFound(){
  const params = useParams()

  return (
    <div className="container-fluid p-0">
      <Header page="san-pham" />
      <section className="page-not-found">
        <div className="container w-1150 pt-5 pb-5 pl-0">Page Not Found</div>
      </section>
      <Newsletter />
      <SocialSupportOnlineButtons />
      <Footer />
    </div>
  )
}

