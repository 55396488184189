import HomepageBanner from "../Banner/HomepageBanner"
import Header from "../Header"
import DesignBlock from "../DesignBlock"
import HomepageCollection from "../Collection/HomepageCollection"
import HomepagePromotion from "../Promotion/HomepagePromotion"
import HomepageArticle from "../Article/HomepageArticle"
import HomepageProject from "../Project//HomepageProject"
import Service from "../Service"
import Newsletter from "../Newsletter"
import SocialSupportOnlineButtons from "../SocialSupportOnlineButtons"
import Footer from "../Footer"

export default function ExchangePolicy(){
  return (
    <div className="container-fluid p-0">
      <Header page="empty" />
      <section className="account-login-verification">
        <ExchangePolicyBanner />
        <ExchangePolicyContainer />
        <ExchangePolicyRules />
      </section>
      <Newsletter />
      <SocialSupportOnlineButtons />
      <Footer />
    </div>
    
  )
}

function ExchangePolicyBanner(){
  return (
    <div id="carousel-v2">
        <div className="carousel slide" data-ride="carousel" data-interval="0">
            <div className="carousel-inner">
                <div className="carousel-item active">
                    <img className="d-block w-100 img-responsive" src={process.env.REACT_APP_PUBLIC_URL + "/images/exchange.png"} alt="First slide" />
                </div>
            </div>
        </div>
    </div>
  )
}

function ExchangePolicyContainer(){
  return (
    <div id="terms-of-use" className="container w-1150 p-0">
        <div className="row">
            <div className="col-12">
                <h1 className="terms-of-use-title">CHÍNH SÁCH ĐỔI, TRẢ & BẢO HÀNH</h1>
            </div>
            <div className="col-12">
                <div className="table-responsive exchange">
                    <table className="table table-bordered">
                        <thead className="thead-dark">
                            <tr>
                                <th scope="col">CHI TIẾT TÌNH TRẠNG HÀNG HÓA</th>
                                <th scope="col">THỜI GIAN TỪ LÚC NHẬN HÀNG</th>
                                <th scope="col">CHÍNH SÁCH ÁP DỤNG</th>
                                <th scope="col">PHÍ ÁP DỤNG ĐỐI VỚI KHÁCH HÀNG</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="text-left">Tình trạng hàng hóa KHÔNG nguyên vẹn như: trầy xước, nứt, gãy, bong tróc sơn, rỉ sét, rách vải, hoặc; Không đúng màu sắc, mẫu mã Khách Hàng đã đặt trên website.</td>
                                <td>Ngay khi nhận hàng</td>
                                <td>Trả hàng / đổi hàng</td>
                                <td><strong>Miễn phí</strong></td>
                            </tr>
                            <tr>
                                <th className="text-left" rowSpan="2">NGUYÊN NHÂN <br /> - Sản phẩm không đúng như mô tả/giới thiệu trên website. <br /> - Sản phẩm không đúng mẫu mã, chủng loại như đơn đặt hàng. <br /> - Sản phẩm có tình trạng bên ngoài KHÔNG giống sản phẩm mới như: trầy xước,
                                    nứt, gãy, bong tróc sơn, rỉ sét, rách vải, … mà bằng mắt thường có thể nhận biết được rõ ràng. <br />
                                    <br /> ĐIỀU KIỆN <br />- Tình trạng hàng hóa nguyên vẹn.<br /> - Sản phẩm mới 100%, chưa lắp đặt, chưa qua sử dụng và KHÔNG có các dấu hiệu bị:<br />
                                    <ul>
                                        <li>Tháo mở, tùy chỉnh.</li>
                                        <li>Tác động ngoại lực/ va chạm mạnh, rơi vỡ.</li>
                                        <li>Thấm nước, ẩm mốc do môi trường bảo quản, bén lửa (thuốc lá,…) Có vết bẩn.</li>
                                    </ul>
                                    - Đầy đủ phụ kiện, hàng/quà khuyến mãi (nếu có)
                                    <br /> - Tem/phiếu bảo hành nguyên vẹn & đầy đủ (nếu có).
                                </th>
                                <td>1 ngày</td>
                                <td>Trả hàng</td>
                                <td><strong>Miễn phí</strong></td>
                            </tr>
                            <tr>
                                <td>7 ngày</td>
                                <td>Đổi hàng</td>
                                <td><strong>Miễn phí</strong></td>
                            </tr>
                            <tr className="bg-D9D9D9">
                                <td className="text-left">- Tình trạng hàng hóa nguyên vẹn.<br /> - Sản phẩm mới và KHÔNG có các dấu hiệu bị:<br /> Tháo mở, tùy chỉnh. <br />Tác động ngoại lực/ va chạm mạnh, rơi vỡ.<br /> Thấm nước, ẩm mốc do môi trường sử dụng, bén lửa (thuốc lá,…)
                                    <br />Có vết bẩn. <br />- Đầy đủ phụ kiện, hàng/quà khuyến mãi (nếu có)<br /> - Tem/phiếu bảo hành nguyên vẹn & đầy đủ (nếu có).</td>
                                <td>30 ngày</td>
                                <td>Trả hàng</td>
                                <td><strong>50% ++</strong></td>
                            </tr>
                            <tr>
                                <th className="text-left" rowSpan="2">Sản phẩm bị lỗi kỹ thuật và/hoặc lỗi lắp ráp của nhà sản xuất.</th>
                                <td>7 ngày</td>
                                <td>Đổi hàng / bảo hành</td>
                                <td><strong>Miễn phí</strong></td>
                            </tr>
                            <tr>
                                <td className="text-left">Trong thời gian bảo hành</td>
                                <td>Bảo hành</td>
                                <td><strong>Miễn phí</strong></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
  )
}

function ExchangePolicyRules(){
  return (
    <div id="general-rules" className="container w-1150 p-0 padding-block-l-r-bt">
        <div className="row">
            <div className="col-12">
                <h1 className="general-rules-title">QUY ĐỊNH CHUNG</h1>
            </div>
            <div className="col-12">
                1. Chính sách ĐỔI, TRẢ & BẢO HÀNH chỉ áp dụng đối với sản phẩm mua từ <a href="#">homeclick.vn</a> và KHÔNG sử dụng cho mục đích thương mại.<br />
                <br />
                <strong>2. Lỗi kỹ thuật, lỗi lắp ráp của nhà sản xuất KHÔNG bao gồm các lỗi do:</strong><br />
                <div className="line-indent">
                    a. Sử dụng sai chức năng.<br />
                    b. Lắp đặt hoặc vận chuyển không đúng cách.<br />
                    c. Lưu trữ và bảo quản trong điều kiện không phù hợp.<br />
                </div>
                <br />
                <strong>3. Thời gian hoàn tiền:</strong> (đối với các sản phẩm khách hàng đã thanh toán & Công ty đã nhận được hàng trả thỏa chính sách TRẢ HÀNG).<br />
                <div className="line-indent">
                    a. Nếu khách hàng <strong>thanh toán bằng tiền mặt khi nhận hàng,</strong> thời gian hoàn tiền tối đa <strong>30 ngày.</strong><br />
                    b. Nếu khách hàng <strong>thanh toán bằng nộp tiền mặt hoặc chuyển khoản trực tiếp vào tài khoản Công ty</strong>, thời gian hoàn tiền tối đa <strong>7 ngày</strong>.<br />
                </div>
                <br />
                <strong>4. PHÍ áp dụng trong chính sách BẢO HÀNH</strong> không bao gồm chi phí vận chuyển Quý khách gởi hàng về đến Công ty hoặc Công ty đến vận chuyển tận nơi.<br />
                <br />
                <strong>5. Thông tin liên hệ đổi, trả & bảo hành</strong><br />
                <div className="line-indent">
                    Công Ty TNHH DB Group<br />
                    Trụ sở chính: 68 Nguyễn Huệ, Phường Bến Nghé, Quận 1, TP. Hồ Chí Minh<br />
                    Điện thoại: 0936 900 319<br />
                    Email: <a href="#">info@homeclick.vn</a><br />
                </div>
                <br />
                <strong>6. Quy trình Đổi, Trả & Bảo hành</strong><br />
                <div className="line-indent">
                    Trong quá trinh sử dụng nếu sản phẩm bị lỗi, khách hàng liên hệ trực tiếp qua số điện thoại hoặc email của Công Ty TNHH DB Group để được tư vấn.<br />
                    Sau khi xác nhận được lỗi, <a href="#">homeclick.vn</a> sẽ thông báo với khách hàng về việc nhân viên của Homeclick sẽ bảo trì/sửa chữa tận nơi hoặc <a href="#">homeclick.vn</a> vận chuyển về công ty để sửa chữa. Và bao gồm chi phí
                    phát sinh (nếu có).
                </div>
                <br />
                <strong>7. Thời gian tiếp nhận và phản hồi</strong><br />
                <div className="line-indent">
                    Thời gian tiếp nhận yêu cầu <strong>ĐỔI, TRẢ & BẢO HÀNH</strong> từ 09:00 đến 18:00 từ Thứ 2 đến Thứ 7 hàng tuần, không áp dụng cho Chủ nhật & các ngày nghỉ lễ tết theo quy định của Nhà nước.<br />
                    Thời gian <strong>ĐỔI HÀNG</strong> tối đa 15 ngày làm việc kể từ khi Công ty nhận được sản phẩm cần đổi thỏa chính sách đổi hàng.<br />
                    Thời gian <strong>TRẢ HÀNG BẢO HÀNH tối đa 7 ngày làm việc</strong> kể từ lúc Công ty nhận được đầy đủ hàng hóa và thông tin mô tả chi tiết lỗi từ quý khách.
                </div>
            </div>
        </div>
    </div>
  )
}