import HomepageBanner from "../Banner/HomepageBanner"
import Header from "../Header"
import DesignBlock from "../DesignBlock"
import HomepageCollection from "../Collection/HomepageCollection"
import HomepagePromotion from "../Promotion/HomepagePromotion"
import HomepageArticle from "../Article/HomepageArticle"
import HomepageProject from "../Project/HomepageProject"
import Service from "../Service"
import Newsletter from "../Newsletter"
import SocialSupportOnlineButtons from "../SocialSupportOnlineButtons"
import Footer from "../Footer"

export default function PaymentGuide(){
  return (
    <div className="container-fluid p-0">
      <Header page="payment-guide" />
      <section className="checkout">
        <PaymentGuideBanner />
        <PaymentGuideContainer />
      </section>
      <Newsletter />
      <SocialSupportOnlineButtons />
      <Footer />
    </div>
    
  )
}

function PaymentGuideBanner(){
  return (
    <div id="carousel-v2">
        <div className="carousel slide" data-ride="carousel" data-interval="0">
            <div className="carousel-inner">
                <div className="carousel-item active">
                    <img className="d-block w-100 img-responsive" src={process.env.REACT_APP_PUBLIC_URL + "/images/payment-guide.png"} alt="First slide" />
                </div>
            </div>
        </div>
    </div>
  )
}

function PaymentGuideContainer(){
  return (
    <div id="terms-of-use" className="container w-1150 padding-block-l-r-bt pt-70">
        <div className="row">
            <div className="col-12">
                <h1 className="terms-of-use-title">Hướng dẫn thanh toán</h1>
            </div>
            <div className="col-12">
                <div className="product-buying">
                    <p className="step">Chuyển tiền qua ngân hàng</p>
                    <br />
                    <p className="step">Đối với một số Quý khách ở tỉnh khác (ngoài Tp.HCM) hoặc đơn hàng có số lượng lớn, trị giá lớn, chúng tôi sẽ đề nghị Quý khách chuyển tiền qua ngân hàng trước khi chúng tôi giao hàng.</p>
                    <br />
                    Chuyển tiền qua ngân hàng<br />
                    Thanh toán bằng Internet Banking/Mobile banking<br />
                    Chuyển khoản tại ATM.<br />
                    Chuyển khoản tại quầy giao dịch ngân hàng<br />
                    Nộp tiền mặt vào tài khoản của CÔNG TY TNHH DB GROUP<br />
                    Quý khách vui lòng chuyển tiền đến tài khoản của CÔNG TY TNHH DB GROUP được mở tại một trong các ngân hàng dưới đây:<br />
                    <br />
                    <div className="d-flex">
                        <img className="img-responsive w-54 h-58" src={process.env.REACT_APP_PUBLIC_URL + "/images/acb/A.png"} alt="" />
                        <img className="img-responsive w-54 h-58" src={process.env.REACT_APP_PUBLIC_URL + "/images/acb/C.png"} alt="" />
                        <img className="img-responsive vector" src={process.env.REACT_APP_PUBLIC_URL + "/images/acb/Vector.png"} alt="" />
                        <img className="img-responsive w-54 h-58" src={process.env.REACT_APP_PUBLIC_URL + "/images/acb/B.png"} alt="" />
                        <div className="mr-20-40">
                            <p className="step mb-0">Ngân hàng thương mại cổ phần Á Châu (ACB)</p>
                            <p className="step mb-0">- Phòng Giao Dịch TÂN PHONG</p>
                            <p className="step mb-0">- Số tài khoản: 8828688</p>
                        </div>
                    </div>
                    Nội dung chuyển khoản: Quý khách vui lòng ghi <strong>mã số đơn hàng - số điện thoại</strong> đã dùng đặt hàng<br />
                    Ví dụ: 100 – 0936 900 319<br />
                    <br />
                    Khách hàng lưu ý:<br />
                    Đối với hình thức chuyển tiền qua ngân hàng, Quý khách vui lòng chọn 1 tài khoản ngân hàng của CÔNG TY TNHH DB GROUP và chuyển đúng theo tài khoản đã chọn trong vòng 24 giờ kể từ thời điểm hoàn thành đơn đặt hàng<br />
                    <img className="img-responsive" src={process.env.REACT_APP_PUBLIC_URL + "/images/icon_hinh-thuc-thanh-toan 1.png"} alt="" />
                    <ul>
                        <li>Số tiền CÔNG TY TNHH DB GROUP nhận được bằng với số tiền “Tổng cộng” thể hiện trên đơn hàng</li>
                        <li>
                            Trong vòng 48 giờ sau khi đã thanh toán nếu Quý khách không thấy đơn hàng chuyển qua trạng thái “Đang giao hàng” hoặc không thấy nhân viên CÔNG TY TNHH DB GROUP liên hệ thì báo ngay qua số hotline của công ty: 0936 900 319 hoặc kiểm tra lại với ngân hàng
                            giao dịch của Quý khách.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
  )
}
