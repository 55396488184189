import HomepageBanner from "../Banner/HomepageBanner"
import Header from "../Header"
import DesignBlock from "../DesignBlock"
import HomepageCollection from "../Collection/HomepageCollection"
import HomepagePromotion from "../Promotion/HomepagePromotion"
import HomepageArticle from "../Article/HomepageArticle"
import HomepageProject from "../Project/HomepageProject"
import Service from "../Service"
import Newsletter from "../Newsletter"
import SocialSupportOnlineButtons from "../SocialSupportOnlineButtons"
import Footer from "../Footer"

export default function PrivacyPolicy(){
  return (
    <div className="container-fluid p-0">
      <Header page="privacy-policy" />
      <section className="checkout">
        <PrivacyPolicyBanner />
        <PrivacyPolicyContainer />
      </section>
      <Newsletter />
      <SocialSupportOnlineButtons />
      <Footer />
    </div>
    
  )
}

function PrivacyPolicyBanner(){
  return (
    <div id="carousel-v2">
        <div className="carousel slide" data-ride="carousel" data-interval="0">
            <div className="carousel-inner">
                <div className="carousel-item active">
                    <img className="d-block w-100 img-responsive" src={process.env.REACT_APP_PUBLIC_URL + "/images/privacy-policy.png"} alt="First slide" />
                </div>
            </div>
        </div>
    </div>
  )
}

function PrivacyPolicyContainer(){
  return (
    <div id="terms-of-use" className="container w-1150 padding-block-l-r-bt pt-70">
        <div className="row">
            <div className="col-12">
                <h1 className="terms-of-use-title">Chính sách bảo mật</h1>
            </div>
            <div className="col-12">
                <ul className="nav flex-column" id="nav_accordion">
                    <li className="nav-item">
                        <a className="nav-link collapsed" href="#terms1" data-bs-toggle="collapse" data-bs-target="#terms1">  1. Mục đích và phạm vi thu thập thông tin <i className="fa-solid fa-angle-down"></i></a>
                        <div className="submenu collapse show" id="terms1" aria-expanded="false">
                            <a href="#">Homeclick.vn</a> không bán, chia sẻ hay trao đổi thông tin cá nhân của khách hàng thu thập trên trang web cho một bên thứ ba nào khác. Chúng tôi bảo lưu quyền sửa đổi, bổ sung nhằm hoàn thiện đối với Chính Sách
                            này vào bất kỳ thời điểm nào. Chúng tôi khuyến khích bạn thường xuyên xem lại Chính Sách Bảo Mật Thông Tin Cá Nhân này để có được những cập nhật mới nhất đảm bảo bạn biết và thực hiện quyền quản lý thông tin cá nhân của bạn.<br />
                            <br /> Thông tin cá nhân thu thập được sẽ chỉ được sử dụng trong nội bộ công ty.<br />
                            <br /> Khi bạn liên hệ đăng ký dịch vụ, thông tin cá nhân mà <a href="#">Homeclick.vn</a> thu thập bao gồm:<br />
                            <ul>
                                <li>Họ và tên</li>
                                <li>Địa chỉ</li>
                                <li>Điện thoại</li>
                                <li>Email</li>
                            </ul>
                            <br />
                            Ngoài thông tin cá nhân là các thông tin về dịch vụ<br />
                            <ul>
                                <li>Tên sản phẩm</li>
                                <li>Số lượng</li>
                                <li>Thời gian giao nhận sản phẩm</li>
                            </ul>
                        </div>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link collapsed" href="#terms2" data-bs-toggle="collapse" data-bs-target="#terms2" aria-expanded="false">  2. Phạm vi sử dụng thông tin <i className="fa-solid fa-angle-down"></i></a>
                        <div className="collapse submenu" id="terms2" aria-expanded="false">
                            Thông tin cá nhân thu thập được sẽ chỉ được <a href="#">Homeclick.vn</a> sử dụng trong nội bộ công ty và cho một hoặc tất cả các mục đích sau đây:<br /> Hỗ trợ khách hàng<br /> Cung cấp thông tin liên quan đến dịch vụ<br /> Xử
                            lý đơn đặt hàng và cung cấp dịch vụ và thông tin qua trang web của chúng tôi theo yêu cầu của bạn<br /> Chúng tôi có thể sẽ gửi thông tin sản phẩm, dịch vụ mới, thông tin về các sự kiện sắp tới hoặc thông tin tuyển dụng nếu
                            quý khách đăng kí nhận email thông báo.<br /> Ngoài ra, chúng tôi sẽ sử dụng thông tin bạn cung cấp để hỗ trợ quản lý tài khoản khách hàng; xác nhận và thực hiện các giao dịch tài chính liên quan đến các khoản thanh toán trực
                            tuyến của bạn
                        </div>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link collapsed" href="#terms3" data-bs-toggle="collapse" data-bs-target="#terms3"> 3. Thời gian lưu trữ thông tin <i className="fa-solid fa-angle-down"></i></a>
                        <div className="collapse submenu" id="terms3" aria-expanded="false">
                            Đối với thông tin cá nhân, <a href="#">Homeclick.vn</a> chỉ xóa đi dữ liệu này nếu khách hàng có yêu cầu, khách hàng yêu cầu gửi mail về <a href="#">info@homeclick.vn</a>
                        </div>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link collapsed" href="#terms4" data-bs-toggle="collapse" data-bs-target="#terms4">  4. Những người hoặc tổ chức có thể được tiếp cận với thông tin cá nhân <i className="fa-solid fa-angle-down"></i></a>
                        <div className="collapse submenu" id="terms4" aria-expanded="false">
                            Đối tượng được tiếp cận với thông tin cá nhân của khách hàng thuộc một trong những trường hợp sau:<br /> CÔNG TY TNHH DB GROUP<br /> Các đối tác có ký hợp động thực hiện 1 phần dịch vụ do CÔNG TY TNHH DB GROUP. Các đối tác này
                            sẽ nhận được những thông tin theo thỏa thuận hợp đồng (có thể 1phần hoặc toàn bộ thông tin tuy theo điều khoản hợp đồng) để tiến hành hỗ trợ người dùng sử dụng dịch vụ do Công ty cung cấp.
                        </div>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link collapsed" href="#terms5" data-bs-toggle="collapse" data-bs-target="#terms5"> 5. Địa chỉ của đơn vị thu thập và quản lý thông tin cá nhân <i className="fa-solid fa-angle-down"></i></a>
                        <div className="collapse submenu" id="terms5" aria-expanded="false">
                            Công ty TNHH DB Group<br /> Địa chỉ: 68 Nguyễn Huệ, P. Bến Nghé, Q.1, Tp. Hồ Chí Minh<br /> Điện thoại: 0936900319<br /> Website: <a href="#">Homeclick.vn</a><br /> Email: <a href="#">info@homeclick.vn</a>
                        </div>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link collapsed" href="#terms6" data-bs-toggle="collapse" data-bs-target="#terms6"> 6. Phương tiện và công cụ để người dùng tiếp cận và chỉnh sửa dữ liệu cá nhân của mình <i className="fa-solid fa-angle-down"></i></a>
                        <div className="collapse submenu" id="terms6" aria-expanded="false">
                            <a href="#">Homeclick.vn</a> không thu thập thông tin khách hàng qua trang web, thông tin cá nhân khách hàng được thực hiện thu thập qua email liên hệ đặt mua sản phẩm, dịch vụ gửi về hộp mail của chúng tôi: <a href="#">info@homeclick.vn</a>hoặc
                            số điện thoại liên hệ đặt mua sản phẩm gọi về 0936900319 Bạn có thể liên hệ địa chỉ email cùng số điện thoại trên để yêu cầu <a href="#">Homeclick.vn</a> chỉnh sửa dữ liệu cá nhân của mình.
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
  )
}
