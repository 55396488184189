import { useParams } from "react-router-dom";

import HomepageBanner from "../Banner/HomepageBanner"
import Header from "../Header"
import DesignBlock from "../DesignBlock"
import HomepageCollection from "../Collection/HomepageCollection"
import HomepagePromotion from "../Promotion/HomepagePromotion"
import HomepageArticle from "../Article/HomepageArticle"
import HomepageProject from "../Project//HomepageProject"
import Service from "../Service"
import Newsletter from "../Newsletter"
import SocialSupportOnlineButtons from "../SocialSupportOnlineButtons"
import Footer from "../Footer"

export default function ProductDetail(){
  const {nameKey} = useParams()

  return (
    <div className="container-fluid p-0">
      <Header page="san-pham" />
      <section className="product-detail">
        <ProductDetailData productNameKey={nameKey} />
        <HotProductData />
        <ViewedProductData />
      </section>
      <Newsletter />
      <SocialSupportOnlineButtons />
      <Footer />
    </div>
  )
}

function ProductDetailData(props){
  console.log(props)
  return (
    <div id="product-detail-v2" className="container w-1150 p-0">
        <div className="row">
            <div className="col-md-7">
                <div className="show-mobile">
                    <h1 className="prd-detail-title">Tamaha</h1>
                    <div className="prd-sku-share">
                        <span className="prd-sku">SKU: HCB0001</span>
                        <div className="prd-share">
                            <a href="#"><i className="fa-solid fa-share-nodes"></i></a>
                            <a href="#"><i className="fa-solid fa-heart"></i></a>
                        </div>
                    </div>
                </div>
                <div className="fotorama fotorama-custom" data-nav="thumbs" data-height="450">
                    <img src={process.env.REACT_APP_PUBLIC_URL + "/images/detail/detail.png"} data-fit="scaledown" data-thumbfit="scaledown" data-thumbwidth="200" data-thumbheight="100" />
                    <img src={process.env.REACT_APP_PUBLIC_URL + "/images/detail/detail1.png"} data-thumbfit="scaledown" data-thumbwidth="200" data-thumbheight="100" />
                    <img src={process.env.REACT_APP_PUBLIC_URL + "/images/detail/detail2.png"} data-thumbfit="scaledown" data-thumbwidth="200" data-thumbheight="100" />
                    <img src={process.env.REACT_APP_PUBLIC_URL + "/images/detail/detail3.png"} data-thumbfit="scaledown" data-thumbwidth="200" data-thumbheight="100" />
                    <img src={process.env.REACT_APP_PUBLIC_URL + "/images/detail/detail4.png"} data-thumbfit="scaledown" data-thumbwidth="200" data-thumbheight="100" />
                    <img src={process.env.REACT_APP_PUBLIC_URL + "/images/detail/detail5.png"} data-thumbfit="scaledown" data-thumbwidth="200" data-thumbheight="100" />
                </div>
                <div className="khuyen-mai show-desktop">
                    <p>Nhập mã <span className="code">HCFT</span> giảm 100,000đ cho đơn hàng trên 1,000,000đ.</p>
                    <p className="c-a8a8a8">Chỉ áp dụng 01 lần/01 khách hàng và không áp dụng cùng với các chương trình khuyến mại khác.</p>
                    <p className="c-a8a8a8">Hạn sử dụng: 30/06/2022 (*)</p>
                </div>
            </div>
            <div className="col-md-5">
                <div>
                    <div className="show-desktop">
                        <h1 className="prd-detail-title">Tamaha</h1>
                        <div className="prd-sku-share">
                            <span className="prd-sku">SKU: HCB0001</span>
                            <div className="prd-share">
                                <a href="#"><i className="fa-solid fa-share-nodes"></i></a>
                                <a href="#"><i className="fa-solid fa-heart"></i></a>
                            </div>
                        </div>
                    </div>
                    <div className="prd-price">
                        <div className="price-sale-off">
                            <span className="price-cost">19.000.000đ</span>
                            <span className="price-percent">15% Off</span>
                        </div>
                        <div className="price-vat">
                            <span className="price-official">15.296.000đ</span>
                            <span className="price-include-vat">(Đã bao gồm VAT)</span>
                        </div>
                    </div>
                    <div className="product-detail-option">
                        <ul className="nav flex-column">
                            <li className="nav-item">
                                <a className="nav-link" data-bs-toggle="modal" data-bs-target="#modalKickThuoc">  Kích thước  <span className="separation">-</span>  <strong className="prd-option-value">2m4</strong> <i className="fa-solid fa-angle-right"></i></a>
                                {/* <!-- <a className="nav-link collapsed" href="#prdOption1" data-bs-toggle="collapse" data-bs-target="#prdOption1">  Kích thước  <span className="separation">-</span>  <strong className="prd-option-value">2m4</strong> <i className="fa-solid fa-angle-right"></i></a> --> */}
                                {/* <!-- <div className="prd-size collapse submenu dropdown" id="prdOption1" aria-expanded="false">
                                    <ul className="nav">
                                        <span>1m2</span>
                                        <span>2m</span>
                                        <span className="active">2m4</span>
                                        <span>2m8</span>
                                    </ul>
                                </div> --> */}
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" data-bs-toggle="modal" data-bs-target="#modalFlexibility">  Flexibility  <span className="separation">-</span>  <strong className="prd-option-value">Tama 3-Piece Sofa</strong> <i className="fa-solid fa-angle-right"></i></a>
                                {/* <!-- <div className="prd-size collapse submenu dropdown" id="prdOption2" aria-expanded="false">
                                    <ul className="nav">
                                        <span data-bs-toggle="modal" data-bs-target="#modalFlexibility">Tama 1-Piece Sofa</span>
                                        <span data-bs-toggle="modal" data-bs-target="#modalFlexibility">Tama 2-Piece Sofa</span>
                                        <span className="active" data-bs-toggle="modal" data-bs-target="#modalFlexibility">Tama 3-Piece Sofa</span>
                                    </ul>
                                </div> --> */}
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" data-bs-toggle="modal" data-bs-target="#modalChatLieu">  Chất liệu <span className="separation">-</span>  <strong className="prd-option-value">Vải  -  Nâu</strong> <i className="fa-solid fa-angle-right"></i></a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" data-bs-toggle="modal" data-bs-target="#modalLegFinish">  Leg finish <span className="separation">-</span>  <strong className="prd-option-value">Walnut</strong> <i className="fa-solid fa-angle-right"></i></a>
                            </li>
                        </ul>
                    </div>
                    <div className="bundled-products">
                        <p className="title-right-detail m-0 p-20-0"><strong>Sản phẩm mua kèm</strong></p>
                        <div className="list-bundled-products">
                            <ul className="p-0 m-0">
                                <li>
                                    <input type="checkbox" name="" id="" />
                                    <span>Moveable chaise</span>
                                    <span className="c-a8a8a8">+1.750.000đ</span>
                                </li>
                                <li>
                                    <input type="checkbox" name="" id="" />
                                    <span>Ottoman</span>
                                    <span className="c-a8a8a8">+1.350.000đ</span>
                                </li>
                                <li>
                                    <input type="checkbox" name="" id="" />
                                    <span>Lumber pillow, set of 3</span>
                                    <span className="c-a8a8a8">+850.000đ</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="list-btn-detail">
                        <div className="btn-mua-ngay">
                            <button className="btn">mua ngay</button>
                        </div>
                        <div className="btn-them-cart">
                            <button className="btn">thêm vào giỏ hàng</button>
                            <div className="gr-btn-text">
                                <p className="phone">0936 900 319</p>
                                <p>Gọi đặt mua hàng, tư vấn</p>
                                <p className="c-a8a8a8">(8:00 - 20:00 | t2-t7)</p>
                            </div>
                        </div>
                    </div>
                    <div className="khuyen-mai show-mobile">
                        <p>Nhập mã <span className="code">HCFT</span> giảm 100,000đ cho đơn hàng trên 1,000,000đ.</p>
                        <p className="c-a8a8a8">Chỉ áp dụng 01 lần/01 khách hàng và không áp dụng cùng với các chương trình khuyến mại khác.</p>
                        <p className="c-a8a8a8">Hạn sử dụng: 30/06/2022 (*)</p>
                    </div>
                </div>
            </div>
            <div className="col-12 tab-content-detail">
                <ul className="nav nav-pills mb-50" id="pills-tab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Thông số kỹ thuật</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Mô tả sản phẩm</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Cách bảo quản</button>
                    </li>
                </ul>
                <div className="tab-content" id="pills-tabContent">
                    <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                        <div className="row">
                            <h4 className="tab-pane-title-block show-mobile">Thông số kỹ thuật</h4>
                            <div className="tskt-content-l col-12 col-md-6">
                                <ul className="reset-ul">
                                    <li><span className="tskt-l">Kích thước tổng</span><span className="tskt-r c-a8a8a8">2400 L x 900 D x 800 H</span></li>
                                    <li><span className="tskt-l">Chiều cao ngồi</span><span className="tskt-r c-a8a8a8">450mm</span></li>
                                    <li><span className="tskt-l">Chiều cao tay</span><span className="tskt-r c-a8a8a8">700mm</span></li>
                                    <li><span className="tskt-l">Chiều sâu ngồi</span><span className="tskt-r c-a8a8a8">600mm</span></li>
                                    <li><span className="tskt-l">Chiều cao chân</span><span className="tskt-r c-a8a8a8">100mm</span></li>
                                    <li><span className="tskt-l">Bảo hành</span><span className="tskt-r c-a8a8a8">1 năm</span></li>
                                </ul>
                            </div>
                            <div className="tskt-content-r col-12 col-md-6">
                                <img className="img-responsive" src={process.env.REACT_APP_PUBLIC_URL + "/images/detail/Frame.png"} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                        <div className="row">
                            <h4 className="tab-pane-title-block show-mobile">Mô tả sản phẩm</h4>
                            <div className="col-12">
                                Sofa sử dụng da thật chống nhăn, kháng bụi bẩn và nấm mốc, dễ dàng vệ sinh.<br/>
                                Sofa sử dụng chất liệu gỗ sồi tự nhiên giúp ghế sofa TAMAHA có khả năng chịu lực tốt và độ bền cao, và mang tính thẩm mỹ.<br/>
                                Các thiết kế từ bộ sưu tập nội thất TAMAHA lấy ý tưởng từ kinh đô thời trang thế giới – nước Ý, truyền tải hơi thở hiện đại và tinh tế trong từng đường nét.<br/>
                                Ghế sofa có kết thể kết hợp đa dạng phong cách.<br/>
                                Ghế có gỗ tự nhiên và thiết kế tối giản dễ dàng kết hợp với các mẫu nội thất khác.<br/>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                        <div className="row">
                            <h4 className="tab-pane-title-block show-mobile">Cách bảo quản</h4>
                            <div className="col-12">
                                Sau một thời gian sử dụng, bạn nên đổi mặt phần nệm ngồi sofa để phần chịu áp lực sẽ phân bổ đều cho chiếc sofa, tránh tình trạng bị xẹp và lệch bên.<br/>
                                Trong quá trình vệ sinh không được sử dụng chất tẩy rửa mạnh một cách tùy tiện để tránh làm ảnh hưởng đến màu sắc và có thể làm hư hỏng bộ sofa vĩnh viễn.<br/>
                                Tránh đặt sofa ở những nơi có ánh nắng trực tiếp hoặc những nơi ẩm thấp, sẽ làm giảm tuổi thọ của sản phẩm.<br/>
                                Tránh đặt ghế sofa gần nguồn điện hoặc những nơi có nguồn nhiệt để tránh trường hợp xảy ra cháy nổ.<br/>
                                Nếu ghế sofa bị tuột chỉ hay sổ một vài sợi vải sau một thời gian dài sử dụng, bạn phải tìm mọi cách nối lại chỗ tuột chỉ hoặc khéo léo cắt chỗ chỉ đó, tránh dùng tay giặt đứt làm ảnh hưởng đến các sợi vải bên cạnh.<br/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        {/* <!-- Modal kich thuoc --> */}
        <div className="modal right fade" id="modalKickThuoc" tabIndex="" role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <span>Kích thước - 2m4</span>
                        <button type="button" data-dismiss="modal" aria-label="Close">
                                    <i className="fa-solid fa-xmark"></i>
                                </button>
                    </div>
                    <div className="modal-body">
                        <nav className="sidebar">
                            <div className="modalFilterItem">
                                <div className="row m-0">
                                    <div className="col-6 col-md-6 item-prd-modal">
                                        <div className="image-prd-modal">
                                            <img className="img-responsive" src={process.env.REACT_APP_PUBLIC_URL + "/images/detail/prd 4.png"} alt="" />
                                        </div>
                                        <div className="content-prd-modal">
                                            <p className="content-prd-title-modal"><strong>1 chỗ</strong></p>
                                            <p className="content-prd-sku-modal">Ngang 700mm</p>
                                            <p className="content-prd-price-modal"><strong>4.606.000đ</strong></p>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-6 item-prd-modal">
                                        <div className="image-prd-modal">
                                            <img className="img-responsive" src={process.env.REACT_APP_PUBLIC_URL + "/images/detail/prd 1.png"} alt="" />
                                        </div>
                                        <div className="content-prd-modal">
                                            <p className="content-prd-title-modal"><strong>2 chỗ</strong></p>
                                            <p className="content-prd-sku-modal">Ngang 1m2</p>
                                            <p className="content-prd-price-modal"><strong>8.996.000đ</strong></p>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-6 item-prd-modal">
                                        <div className="image-prd-modal">
                                            <img className="img-responsive" src={process.env.REACT_APP_PUBLIC_URL + "/images/detail/prd 3.png"} alt="" />
                                        </div>
                                        <div className="content-prd-modal">
                                            <p className="content-prd-title-modal"><strong>3 chỗ</strong></p>
                                            <p className="content-prd-sku-modal">Ngang 2m4</p>
                                            <p className="content-prd-price-modal"><strong>12.906.000đ</strong></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
                {/* <!-- modal-content --> */}
            </div>
            {/* <!-- modal-dialog --> */}
        </div>
        {/* <!-- modal --> */}

        {/* <!-- Modal Flexibility --> */}
        <div className="modal right fade" id="modalFlexibility" tabIndex="" role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <span>Flexibility - 4 piece</span>
                        <button type="button" data-dismiss="modal" aria-label="Close">
                                <i className="fa-solid fa-xmark"></i>
                            </button>
                    </div>
                    <div className="modal-body">
                        <nav className="sidebar">
                            <div className="modalFilterItem">
                                <div className="row m-0">
                                    <div className="col-6 col-md-6 item-prd-modal">
                                        <div className="image-prd-modal">
                                            <img className="img-responsive" src={process.env.REACT_APP_PUBLIC_URL + "/images/detail/prd 4.png"} alt="" />
                                        </div>
                                        <div className="content-prd-modal">
                                            <p className="content-prd-title-modal"><strong>Tama 1-Piece</strong></p>
                                            <p className="content-prd-sku-modal">SKU: HCB0001</p>
                                            <p className="content-prd-sku-modal">W1800 - D1000 - H700</p>
                                            <p className="content-prd-price-modal"><strong>4.606.000đ</strong></p>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-6 item-prd-modal">
                                        <div className="image-prd-modal">
                                            <img className="img-responsive" src={process.env.REACT_APP_PUBLIC_URL + "/images/detail/prd 1.png"} alt="" />
                                        </div>
                                        <div className="content-prd-modal">
                                            <p className="content-prd-title-modal"><strong>Tama 2-Piece</strong></p>
                                            <p className="content-prd-sku-modal">SKU: HCB0001</p>
                                            <p className="content-prd-sku-modal">W1800 - D1000 - H700</p>
                                            <p className="content-prd-price-modal"><strong>8.996.000đ</strong></p>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-6 item-prd-modal">
                                        <div className="image-prd-modal">
                                            <img className="img-responsive" src={process.env.REACT_APP_PUBLIC_URL + "/images/detail/prd 3.png"} alt="" />
                                        </div>
                                        <div className="content-prd-modal">
                                            <p className="content-prd-title-modal"><strong>Tama Left otto</strong></p>
                                            <p className="content-prd-sku-modal">SKU: HCB0001</p>
                                            <p className="content-prd-sku-modal">W1800 - D1000 - H700</p>
                                            <p className="content-prd-price-modal"><strong>12.906.000đ</strong></p>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-6 item-prd-modal">
                                        <div className="image-prd-modal">
                                            <img className="img-responsive" src={process.env.REACT_APP_PUBLIC_URL + "/images/detail/prd 2.png"} alt="" />
                                        </div>
                                        <div className="content-prd-modal">
                                            <p className="content-prd-title-modal"><strong>Tama right otto</strong></p>
                                            <p className="content-prd-sku-modal">SKU: HCB0001</p>
                                            <p className="content-prd-sku-modal">W1800 - D1000 - H700</p>
                                            <p className="content-prd-price-modal"><strong>12.896.000đ</strong></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
                {/* <!-- modal-content --> */}
            </div>
            {/* <!-- modal-dialog --> */}
        </div>
        {/* <!-- modal --> */}

        {/* <!-- chất liệu modal --> */}
        <div className="modal right fade" id="modalChatLieu" tabIndex="" role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <span>Màu vải - 12</span>
                        <button type="button" data-dismiss="modal" aria-label="Close">
                            <i className="fa-solid fa-xmark"></i>
                        </button>
                    </div>
                    <div className="modal-body">
                        <nav className="sidebar">
                            <div className="modalFilterItem">
                                <ul className="nav flex-column" id="nav_accordion">
                                    <li className="nav-item">
                                        <a className="nav-link collapsed" href="#filterVai" data-bs-toggle="collapse" data-bs-target="#filterVai">  Vải <i className="fa-solid fa-angle-down"></i></a>
                                        <div className="collapse submenu dropdown" id="filterVai" aria-expanded="false">
                                            <ul className="flex-column nav">
                                                <div className="plp-color-filter">
                                                    <div className="plp-color-filter__item "><label htmlFor="white" type="label" className="plp-color-filter__label"><span>white</span><span aria-label="653 products">653</span></label><input id="white" name="white" type="checkbox" className="plp-color-filter__input" />
                                                        <div className="plp-color-filter__visual" data-id="10156"></div>
                                                    </div>
                                                    <div className="plp-color-filter__item "><label htmlFor="beige" type="label" className="plp-color-filter__label"><span>beige</span><span aria-label="501 products">501</span></label><input id="beige" name="beige" type="checkbox" className="plp-color-filter__input" />
                                                        <div className="plp-color-filter__visual" data-id="10003"></div>
                                                    </div>
                                                    <div className="plp-color-filter__item "><label htmlFor="grey" type="label" className="plp-color-filter__label"><span>grey</span><span aria-label="439 products">439</span></label><input id="grey" name="grey" type="checkbox" className="plp-color-filter__input" />
                                                        <div className="plp-color-filter__visual" data-id="10028"></div>
                                                    </div>
                                                    <div className="plp-color-filter__item "><label htmlFor="brown" type="label" className="plp-color-filter__label"><span>brown</span><span aria-label="387 products">387</span></label><input id="brown" name="brown" type="checkbox" className="plp-color-filter__input" />
                                                        <div className="plp-color-filter__visual" data-id="10019"></div>
                                                    </div>
                                                    <div className="plp-color-filter__item "><label htmlFor="black" type="label" className="plp-color-filter__label"><span>black</span><span aria-label="240 products">240</span></label><input id="black" name="black" type="checkbox" className="plp-color-filter__input" />
                                                        <div className="plp-color-filter__visual" data-id="10139"></div>
                                                    </div>
                                                    <div className="plp-color-filter__item "><label htmlFor="green" type="label" className="plp-color-filter__label"><span>green</span><span aria-label="149 products">149</span></label><input id="green" name="green" type="checkbox" className="plp-color-filter__input" />
                                                        <div className="plp-color-filter__visual" data-id="10033"></div>
                                                    </div>
                                                    <div className="plp-color-filter__item "><label htmlFor="blue" type="label" className="plp-color-filter__label"><span>blue</span><span aria-label="134 products">134</span></label><input id="blue" name="blue" type="checkbox" className="plp-color-filter__input" />
                                                        <div className="plp-color-filter__visual" data-id="10007"></div>
                                                    </div>
                                                </div>
                                            </ul>
                                        </div>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link collapsed" href="#filterDa" data-bs-toggle="collapse" data-bs-target="#filterDa">  Da <i className="fa-solid fa-angle-down"></i></a>
                                        <div className="collapse submenu dropdown" id="filterDa" aria-expanded="false">
                                            <ul className="flex-column nav">
                                                <div className="plp-color-filter">
                                                    <div className="plp-color-filter__item "><label htmlFor="red" type="label" className="plp-color-filter__label"><span>red</span><span aria-label="76 products">76</span></label><input id="red" name="red" type="checkbox" className="plp-color-filter__input" />
                                                        <div className="plp-color-filter__visual" data-id="10124"></div>
                                                    </div>
                                                    <div className="plp-color-filter__item "><label htmlFor="pink" type="label" className="plp-color-filter__label"><span>pink</span><span aria-label="49 products">49</span></label><input id="pink" name="pink" type="checkbox" className="plp-color-filter__input" />
                                                        <div className="plp-color-filter__visual" data-id="10119"></div>
                                                    </div>
                                                    <div className="plp-color-filter__item "><label htmlFor="multicolour" type="label" className="plp-color-filter__label"><span>multicolour</span><span aria-label="40 products">40</span></label><input id="multicolour" name="multicolour" type="checkbox" className="plp-color-filter__input" />
                                                        <div className="plp-color-filter__visual" data-id="10583"></div>
                                                    </div>
                                                    <div className="plp-color-filter__item "><label htmlFor="turquoise" type="label" className="plp-color-filter__label"><span>turquoise</span><span aria-label="31 products">31</span></label><input id="turquoise" name="turquoise" type="checkbox" className="plp-color-filter__input" />
                                                        <div className="plp-color-filter__visual" data-id="10152"></div>
                                                    </div>
                                                    <div className="plp-color-filter__item "><label htmlFor="lilac" type="label" className="plp-color-filter__label"><span>lilac</span><span aria-label="8 products">8</span></label><input id="lilac" name="lilac" type="checkbox" className="plp-color-filter__input" />
                                                        <div className="plp-color-filter__visual" data-id="10064"></div>
                                                    </div>
                                                </div>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
                {/* <!-- modal-content --> */}
            </div>
        </div>

        {/* <!-- modalLegFinish modal --> */}
        <div className="modal right fade" id="modalLegFinish" tabIndex="" role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <span>Leg finish - 6</span>
                        <button type="button" data-dismiss="modal" aria-label="Close">
                            <i className="fa-solid fa-xmark"></i>
                        </button>
                    </div>
                    <div className="modal-body">
                        <nav className="sidebar">
                            <div className="modalFilterItem">
                                <ul className="nav flex-column" id="nav_accordion">
                                    <li className="nav-item">
                                        <a className="nav-link collapsed" href="#filterGo" data-bs-toggle="collapse" data-bs-target="#filterGo">  Gỗ 4 <i className="fa-solid fa-angle-down"></i></a>
                                        <div className="collapse submenu dropdown" id="filterGo" aria-expanded="false">
                                            <ul className="flex-column nav">
                                                <div className="plp-color-filter">
                                                    <div className="plp-color-filter__item "><label htmlFor="white" type="label" className="plp-color-filter__label"><span>white</span><span aria-label="653 products">653</span></label><input id="white" name="white" type="checkbox" className="plp-color-filter__input" />
                                                        <div className="plp-color-filter__visual" data-id="10156"></div>
                                                    </div>
                                                    <div className="plp-color-filter__item "><label htmlFor="beige" type="label" className="plp-color-filter__label"><span>beige</span><span aria-label="501 products">501</span></label><input id="beige" name="beige" type="checkbox" className="plp-color-filter__input" />
                                                        <div className="plp-color-filter__visual" data-id="10003"></div>
                                                    </div>
                                                    <div className="plp-color-filter__item "><label htmlFor="grey" type="label" className="plp-color-filter__label"><span>grey</span><span aria-label="439 products">439</span></label><input id="grey" name="grey" type="checkbox" className="plp-color-filter__input" />
                                                        <div className="plp-color-filter__visual" data-id="10028"></div>
                                                    </div>
                                                    <div className="plp-color-filter__item "><label htmlFor="brown" type="label" className="plp-color-filter__label"><span>brown</span><span aria-label="387 products">387</span></label><input id="brown" name="brown" type="checkbox" className="plp-color-filter__input" />
                                                        <div className="plp-color-filter__visual" data-id="10019"></div>
                                                    </div>
                                                    <div className="plp-color-filter__item "><label htmlFor="black" type="label" className="plp-color-filter__label"><span>black</span><span aria-label="240 products">240</span></label><input id="black" name="black" type="checkbox" className="plp-color-filter__input" />
                                                        <div className="plp-color-filter__visual" data-id="10139"></div>
                                                    </div>
                                                    <div className="plp-color-filter__item "><label htmlFor="green" type="label" className="plp-color-filter__label"><span>green</span><span aria-label="149 products">149</span></label><input id="green" name="green" type="checkbox" className="plp-color-filter__input" />
                                                        <div className="plp-color-filter__visual" data-id="10033"></div>
                                                    </div>
                                                    <div className="plp-color-filter__item "><label htmlFor="blue" type="label" className="plp-color-filter__label"><span>blue</span><span aria-label="134 products">134</span></label><input id="blue" name="blue" type="checkbox" className="plp-color-filter__input" />
                                                        <div className="plp-color-filter__visual" data-id="10007"></div>
                                                    </div>
                                                </div>
                                            </ul>
                                        </div>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link collapsed" href="#filterKimLoai" data-bs-toggle="collapse" data-bs-target="#filterKimLoai">  Kim loại 3 <i className="fa-solid fa-angle-down"></i></a>
                                        <div className="collapse submenu dropdown" id="filterKimLoai" aria-expanded="false">
                                            <ul className="flex-column nav">
                                                <div className="plp-color-filter">
                                                    <div className="plp-color-filter__item "><label htmlFor="red" type="label" className="plp-color-filter__label"><span>red</span><span aria-label="76 products">76</span></label><input id="red" name="red" type="checkbox" className="plp-color-filter__input" />
                                                        <div className="plp-color-filter__visual" data-id="10124"></div>
                                                    </div>
                                                    <div className="plp-color-filter__item "><label htmlFor="pink" type="label" className="plp-color-filter__label"><span>pink</span><span aria-label="49 products">49</span></label><input id="pink" name="pink" type="checkbox" className="plp-color-filter__input" />
                                                        <div className="plp-color-filter__visual" data-id="10119"></div>
                                                    </div>
                                                    <div className="plp-color-filter__item "><label htmlFor="multicolour" type="label" className="plp-color-filter__label"><span>multicolour</span><span aria-label="40 products">40</span></label><input id="multicolour" name="multicolour" type="checkbox" className="plp-color-filter__input" />
                                                        <div className="plp-color-filter__visual" data-id="10583"></div>
                                                    </div>
                                                    <div className="plp-color-filter__item "><label htmlFor="turquoise" type="label" className="plp-color-filter__label"><span>turquoise</span><span aria-label="31 products">31</span></label><input id="turquoise" name="turquoise" type="checkbox" className="plp-color-filter__input" />
                                                        <div className="plp-color-filter__visual" data-id="10152"></div>
                                                    </div>
                                                    <div className="plp-color-filter__item "><label htmlFor="lilac" type="label" className="plp-color-filter__label"><span>lilac</span><span aria-label="8 products">8</span></label><input id="lilac" name="lilac" type="checkbox" className="plp-color-filter__input" />
                                                        <div className="plp-color-filter__visual" data-id="10064"></div>
                                                    </div>
                                                </div>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
                {/* <!-- modal-content --> */}
            </div>
        </div>
    </div>
  )
}

function HotProductData(){
  return (
    <div id="collection-v2" className="container w-1150 p-0">
        <div className="row">
            <div className="col-12">
                <h4 className="title-collection-v2">Có thể bạn quan tâm</h4>
            </div>
            <div className="col-6 col-md-3 collection-v2">
                <div className="img__wrap">
                    <img className="img-responsive img__img w-100" src={process.env.REACT_APP_PUBLIC_URL + "/images/detail/sofa_PNG6918.png"} />
                    <div className="img__description_layer">
                        <div>
                            <button className="img__description img__description__mobile"><a href="#">Xem thêm</a></button>
                            <div className="img__description__cart__heart">
                                <button className="img__description img__description__cart"><a href="#"><i className="fa-solid fa-cart-shopping"></i></a></button>
                                <button className="img__description img__description__heart"><a href="#"><i className="fa-solid fa-heart"></i></a></button>
                            </div>
                        </div>
                    </div>
                </div>
                <a href="#" className="collection-title wrap-text">summer collection summer collection summer collection summer collection summer collection summer collection</a>
                <p className="collection-sku">SKU: HCB0001</p>
                <div className="discount-price-v2">
                    <span className="original-price">32.980.000đ</span>
                    <span className="percent-discount">-5%</span>
                </div>
                <span className="price-v2">29.996.000đ</span>
            </div>
            <div className="col-6 col-md-3 collection-v2">
                <div className="img__wrap">
                    <img className="img-responsive img__img w-100" src={process.env.REACT_APP_PUBLIC_URL + "/images/detail/sofa_PNG6930.png"} />
                    <div className="img__description_layer">
                        <div>
                            <button className="img__description img__description__mobile"><a href="#">Xem thêm</a></button>
                            <div className="img__description__cart__heart">
                                <button className="img__description img__description__cart"><a href="#"><i className="fa-solid fa-cart-shopping"></i></a></button>
                                <button className="img__description img__description__heart"><a href="#"><i className="fa-solid fa-heart"></i></a></button>
                            </div>
                        </div>
                    </div>
                </div>
                <a href="#" className="collection-title wrap-text">milano collection</a>
                <p className="collection-sku">SKU: HCB0002</p>
                <span className="price-v2">19.296.000đ</span>
            </div>
            <div className="col-6 col-md-3 collection-v2">
                <div className="img__wrap">
                    <img className="img-responsive img__img w-100" src={process.env.REACT_APP_PUBLIC_URL + "/images/detail/sofa_PNG6942.png"} />
                    <div className="img__description_layer">
                        <div>
                            <button className="img__description img__description__mobile"><a href="#">Xem thêm</a></button>
                            <div className="img__description__cart__heart">
                                <button className="img__description img__description__cart"><a href="#"><i className="fa-solid fa-cart-shopping"></i></a></button>
                                <button className="img__description img__description__heart"><a href="#"><i className="fa-solid fa-heart"></i></a></button>
                            </div>
                        </div>
                    </div>
                </div>
                <a href="#" className="collection-title wrap-text">yello collection</a>
                <p className="collection-sku">SKU: HCB0003</p>
                <span className="price-v2">27.296.000đ</span>
            </div>
            <div className="col-6 col-md-3 collection-v2">
                <div className="img__wrap">
                    <img className="img-responsive img__img w-100" src={process.env.REACT_APP_PUBLIC_URL + "/images/detail/sofa_PNG6918.png"} />
                    <div className="img__description_layer">
                        <div>
                            <button className="img__description img__description__mobile"><a href="#">Xem thêm</a></button>
                            <div className="img__description__cart__heart">
                                <button className="img__description img__description__cart"><a href="#"><i className="fa-solid fa-cart-shopping"></i></a></button>
                                <button className="img__description img__description__heart"><a href="#"><i className="fa-solid fa-heart"></i></a></button>
                            </div>
                        </div>
                    </div>
                </div>
                <a href="#" className="collection-title wrap-text">yello collection</a>
                <p className="collection-sku">SKU: HCB0003</p>
                <span className="price-v2">27.296.000đ</span>
            </div>
        </div>
    </div>
  )
}

function ViewedProductData(){
  return (
    <div id="viewed-products-v2" className="container w-1150 p-0">
        <h4 className="title-viewed-product">Sản phẩm đã xem</h4>
        <div className="mhn-slide owl-carousel">
            <div className="mhn-item">
                <div className="mhn-inner">
                    <div className="mhn-prd-img">
                        <img src={process.env.REACT_APP_PUBLIC_URL + "/images/prd1.png"} />
                    </div>
                    <div className="mhn-img">
                        <div className="loader-circle">
                            <div className="loader-stroke-left"></div>
                            <div className="loader-stroke-right"></div>
                        </div>
                    </div>
                    <div className="mhn-text collection-v2">
                        <a href="#" className="collection-title wrap-text">summer collection summer collection summer collection summer collection summer collection summer collection</a>
                        <p className="collection-sku">SKU: HCB0001</p>
                        <div className="discount-price-v2">
                            <span className="original-price">32.980.000đ</span>
                            <span className="percent-discount">-5%</span>
                        </div>
                        <span className="price-v2">29.996.000đ</span>
                    </div>
                </div>
            </div>
            <div className="mhn-item">
                <div className="mhn-inner">
                    <div className="mhn-prd-img">
                        <img src={process.env.REACT_APP_PUBLIC_URL + "/images/prd2.png"} />
                    </div>
                    <div className="mhn-img">
                        <div className="loader-circle">
                            <div className="loader-stroke-left"></div>
                            <div className="loader-stroke-right"></div>
                        </div>
                    </div>
                    <div className="mhn-text collection-v2">
                        <a href="#" className="collection-title wrap-text">milano collection</a>
                        <p className="collection-sku">SKU: HCB0002</p>
                        <span className="price-v2">19.296.000đ</span>
                    </div>
                </div>
            </div>
            <div className="mhn-item">
                <div className="mhn-inner">
                    <div className="mhn-prd-img">
                        <img src={process.env.REACT_APP_PUBLIC_URL + "/images/prd3.png"} />
                    </div>
                    <div className="mhn-img">
                        <div className="loader-circle">
                            <div className="loader-stroke-left"></div>
                            <div className="loader-stroke-right"></div>
                        </div>
                    </div>
                    <div className="mhn-text collection-v2">
                        <a href="#" className="collection-title wrap-text">yello collection</a>
                        <p className="collection-sku">SKU: HCB0003</p>
                        <span className="price-v2">27.296.000đ</span>
                    </div>
                </div>
            </div>
            <div className="mhn-item">
                <div className="mhn-inner">
                    <div className="mhn-prd-img">
                        <img src={process.env.REACT_APP_PUBLIC_URL + "/images/prd4.png"} />
                    </div>
                    <div className="mhn-img">
                        <div className="loader-circle">
                            <div className="loader-stroke-left"></div>
                            <div className="loader-stroke-right"></div>
                        </div>
                    </div>
                    <div className="mhn-text collection-v2">
                        <a href="#" className="collection-title wrap-text">summer collection summer collection summer collection summer collection summer collection summer collection</a>
                        <p className="collection-sku">SKU: HCB0004</p>
                        <div className="discount-price-v2">
                            <span className="original-price">50.000.000đ</span>
                            <span className="percent-discount">-10%</span>
                        </div>
                        <span className="price-v2">45.000.000đ</span>
                    </div>
                </div>
            </div>
            <div className="mhn-item">
                <div className="mhn-inner">
                    <div className="mhn-prd-img">
                        <img src={process.env.REACT_APP_PUBLIC_URL + "/images/prd1.png"} />
                    </div>
                    <div className="mhn-img">
                        <div className="loader-circle">
                            <div className="loader-stroke-left"></div>
                            <div className="loader-stroke-right"></div>
                        </div>
                    </div>
                    <div className="mhn-text collection-v2">
                        <a href="#" className="collection-title wrap-text">summer collection summer collection summer collection summer collection summer collection summer collection</a>
                        <p className="collection-sku">SKU: HCB0001</p>
                        <div className="discount-price-v2">
                            <span className="original-price">32.980.000đ</span>
                            <span className="percent-discount">-5%</span>
                        </div>
                        <span className="price-v2">29.996.000đ</span>
                    </div>
                </div>
            </div>
            <div className="mhn-item">
                <div className="mhn-inner">
                    <div className="mhn-prd-img">
                        <img src={process.env.REACT_APP_PUBLIC_URL + "/images/prd2.png"} />
                    </div>
                    <div className="mhn-img">
                        <div className="loader-circle">
                            <div className="loader-stroke-left"></div>
                            <div className="loader-stroke-right"></div>
                        </div>
                    </div>
                    <div className="mhn-text collection-v2">
                        <a href="#" className="collection-title wrap-text">milano collection</a>
                        <p className="collection-sku">SKU: HCB0002</p>
                        <span className="price-v2">19.296.000đ</span>
                    </div>
                </div>
            </div>
            <div className="mhn-item">
                <div className="mhn-inner">
                    <div className="mhn-prd-img">
                        <img src={process.env.REACT_APP_PUBLIC_URL + "/images/prd3.png"} />
                    </div>
                    <div className="mhn-img">
                        <div className="loader-circle">
                            <div className="loader-stroke-left"></div>
                            <div className="loader-stroke-right"></div>
                        </div>
                    </div>
                    <div className="mhn-text collection-v2">
                        <a href="#" className="collection-title wrap-text">yello collection</a>
                        <p className="collection-sku">SKU: HCB0003</p>
                        <span className="price-v2">27.296.000đ</span>
                    </div>
                </div>
            </div>
            <div className="mhn-item">
                <div className="mhn-inner">
                    <div className="mhn-prd-img">
                        <img src={process.env.REACT_APP_PUBLIC_URL + "/images/prd4.png"} />
                    </div>
                    <div className="mhn-img">
                        <div className="loader-circle">
                            <div className="loader-stroke-left"></div>
                            <div className="loader-stroke-right"></div>
                        </div>
                    </div>
                    <div className="mhn-text collection-v2">
                        <a href="#" className="collection-title wrap-text">summer collection summer collection summer collection summer collection summer collection summer collection</a>
                        <p className="collection-sku">SKU: HCB0004</p>
                        <div className="discount-price-v2">
                            <span className="original-price">50.000.000đ</span>
                            <span className="percent-discount">-10%</span>
                        </div>
                        <span className="price-v2">45.000.000đ</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}