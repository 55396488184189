
export default function DesignBlock(){
  return (
    <div id="design-v2" className="container-fluid w-1150">
      <div className="row">
        <div className="col-12">
          <h4 className="title-design-v2">THIẾT KẾ</h4>
        </div>
        <div className="col-12 col-sm-4 design-v2">
          <a href="#" className="hover-change-icon">
            <img className="img-hover" src={process.env.REACT_APP_PUBLIC_URL + "/images/icon/SP icon.png"} alt="" />
            <img className="un-img-hover" src={process.env.REACT_APP_PUBLIC_URL + "/images/icon/Frame.png"} alt="" />
            <span>THIẾT KẾ SẢN PHẨM</span>
          </a>
        </div>
        <div className="col-12 col-sm-4 design-v2">
          <a href="#" className="hover-change-icon">
            <img className="img-hover" src={process.env.REACT_APP_PUBLIC_URL + "/images/icon/LAYOUT icon.png"} alt="" />
            <img className="un-img-hover" src={process.env.REACT_APP_PUBLIC_URL + "/images/icon/Frame-1.png"} alt="" />
            <span>THIẾT KẾ LAYOUT</span>
          </a>
        </div>
        <div className="col-12 col-sm-4 design-v2">
          <a href="#" className="hover-change-icon">
            <img className="img-hover" src={process.env.REACT_APP_PUBLIC_URL + "/images/icon/MM icon.png"} alt="" />
            <img className="un-img-hover" src={process.env.REACT_APP_PUBLIC_URL + "/images/icon/Frame-2.png"} alt="" />
            <span>THIẾT KẾ MIX & MATCH</span>
          </a>
        </div>
      </div>
    </div>
  )
}