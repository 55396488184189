
export default function HomepageProject(){
  return (
    <div id="top-project-v2" className="container-fluid w-1150">
      <div className="row">
        <div className="col-12">
          <h4 className="title-top-project-v2">DỰ ÁN NỔI BẬT</h4>
        </div>
        <div className="col-12">
          <div id="carouselHomepage1" className="carousel slide" data-bs-ride="carousel" data-interval="5000">
            <div className="carousel-inner">
              <div className="row carousel-item active">
                <div className="col-12 col-md-6 content-top-project-v2">
                  {/* <h4 className="title-top-project-v2">DỰ ÁN NỔI BẬT</h4> */}
                  <h6 className="wrap-text">title 1</h6>
                  <p className="wrap-text">Phong khách có thiết kế linh hoạt theo dạng module lắp ghép, bạn dễ dàng thay đổi
                    được các góc đặt cũng như sắp xếp sao cho cho phù hợp nhất với không gian của mình. Sự nổi bật khác
                    ngoài mà u sắc của Marshmallow Sofa là
                    các góc ghế được bo tròn tinh tế. Phong khách có thiết kế linh hoạt theo dạng module lắp ghép, bạn dễ
                    dàng thay đổi được các góc đặt cũng như sắp xếp sao cho cho phù hợp nhất với không gian của mình. Sự
                    nổi bật khác
                    ngoài mà u sắc của Marshmallow Sofa là các góc ghế được bo tròn tinh tế. Phong khách có thiết kế linh
                    hoạt theo dạng module lắp ghép, bạn dễ dàng thay đổi được các góc đặt cũng như sắp xếp sao cho cho phù
                    hợp nhất với
                    không gian của mình. Sự nổi bật khác ngoài mà u sắc của Marshmallow Sofa là các góc ghế được bo tròn
                    tinh tế. Phong khách có thiết kế linh hoạt theo dạng module lắp ghép, bạn dễ dàng thay đổi được các
                    góc đặt cũng như
                    sắp xếp sao cho cho phù hợp nhất với không gian của mình. Sự nổi bật khác ngoài mà u sắc của
                    Marshmallow Sofa là các góc ghế được bo tròn tinh tế. Phong khách có thiết kế linh hoạt theo dạng
                    module lắp ghép, bạn dễ
                    dàng thay đổi được các góc đặt cũng như sắp xếp sao cho cho phù hợp nhất với không gian của mình. Sự
                    nổi bật khác ngoài mà u sắc của Marshmallow Sofa là các góc ghế được bo tròn tinh tế. Phong khách có
                    thiết kế linh
                    hoạt theo dạng module lắp ghép, bạn dễ dàng thay đổi được các góc đặt cũng như sắp xếp sao cho cho phù
                    hợp nhất với không gian của mình. Sự nổi bật khác ngoài mà u sắc của Marshmallow Sofa là các góc ghế
                    được bo tròn
                    tinh tế. Phong khách có thiết kế linh hoạt theo dạng module lắp ghép, bạn dễ dàng thay đổi được các
                    góc đặt cũng như sắp xếp sao cho cho phù hợp nhất với không gian của mình. Sự nổi bật khác ngoài mà u
                    sắc của Marshmallow
                    Sofa là các góc ghế được bo tròn tinh tế.</p>
                  <button><a href="#">Đến dự án</a></button>
                </div>
                <div className="col-12 col-md-6 img-top-project-v2">
                  <img className="img-responsive d-block w-100" src={process.env.REACT_APP_PUBLIC_URL + "/images/slide.jfif"} alt="First" />
                </div>
              </div>
              <div className="row carousel-item">
                <div className="col-12 col-md-6 content-top-project-v2">
                  {/* <h4 className="title-top-project-v2">DỰ ÁN NỔI BẬT</h4> */}
                  <h6 className="wrap-text">title 2</h6>
                  <p className="wrap-text">Phong khách có thiết kế linh hoạt theo dạng module lắp ghép, bạn dễ dàng thay đổi
                    được các góc đặt cũng như sắp xếp sao cho cho phù hợp nhất với không gian của mình. Sự nổi bật khác
                    ngoài mà u sắc của Marshmallow Sofa là
                    các góc ghế được bo tròn tinh tế. Phong khách có thiết kế linh hoạt theo dạng module lắp ghép, bạn dễ
                    dàng thay đổi được các góc đặt cũng như sắp xếp sao cho cho phù hợp nhất với không gian của mình. Sự
                    nổi bật khác
                    ngoài mà u sắc của Marshmallow Sofa là các góc ghế được bo tròn tinh tế. Phong khách có thiết kế linh
                    hoạt theo dạng module lắp ghép, bạn dễ dàng thay đổi được các góc đặt cũng như sắp xếp sao cho cho phù
                    hợp nhất với
                    không gian của mình.</p>
                  <button><a href="#">Đến dự án</a></button>
                </div>
                <div className="col-12 col-md-6 img-top-project-v2">
                  <img className="img-responsive d-block w-100" src={process.env.REACT_APP_PUBLIC_URL + "/images/slide2.jfif"} alt="Second" />
                </div>
              </div>
              <div className="row carousel-item">
                <div className="col-12 col-md-6 content-top-project-v2">
                  {/* <h4 className="title-top-project-v2">DỰ ÁN NỔI BẬT</h4> */}
                  <h6 className="wrap-text">title 3</h6>
                  <p className="wrap-text">Phong khách có thiết kế linh hoạt theo dạng module lắp ghép, bạn dễ dàng thay đổi
                    được các góc đặt cũng như sắp xếp sao cho cho phù hợp nhất với không gian của mình. Sự nổi bật khác
                    ngoài mà u sắc của Marshmallow Sofa là
                    các góc ghế được bo tròn tinh tế. Phong khách có thiết kế linh hoạt theo dạng module lắp ghép, bạn dễ
                    dàng thay đổi được các góc đặt cũng như sắp xếp sao cho cho phù hợp nhất với không gian của mình. Sự
                    nổi bật khác
                    ngoài mà u sắc của Marshmallow Sofa là các góc ghế được bo tròn tinh tế. Phong khách có thiết kế linh
                    hoạt theo dạng module lắp ghép, bạn dễ dàng thay đổi được các góc đặt cũng như sắp xếp sao cho cho phù
                    hợp nhất với
                    không gian của mình. Sự nổi bật khác ngoài mà u sắc của Marshmallow Sofa là các góc ghế được bo tròn
                    tinh tế. Phong khách có thiết kế linh hoạt theo dạng module lắp ghép, bạn dễ dàng thay đổi được các
                    góc đặt cũng như
                    sắp xếp sao cho cho phù hợp nhất với không gian của mình. Sự nổi bật khác ngoài mà u sắc của
                    Marshmallow Sofa là các góc ghế được bo tròn tinh tế. Phong khách có thiết kế linh hoạt theo dạng
                    module lắp ghép, bạn dễ
                    dàng thay đổi được các góc đặt cũng như sắp xếp sao cho cho phù hợp nhất với không gian của mình. Sự
                    nổi bật khác ngoài mà u sắc của Marshmallow Sofa là các góc ghế được bo tròn tinh tế. Phong khách có
                    thiết kế linh
                    hoạt theo dạng module lắp ghép, bạn dễ dàng thay đổi được các góc đặt cũng như sắp xếp sao cho cho phù
                    hợp nhất với không gian của mình. Sự nổi bật khác ngoài mà u sắc của Marshmallow Sofa là các góc ghế
                    được bo tròn
                    tinh tế. Phong khách có thiết kế linh hoạt theo dạng module lắp ghép, bạn dễ dàng thay đổi được các
                    góc đặt cũng như sắp xếp sao cho cho phù hợp nhất với không gian của mình. Sự nổi bật khác ngoài mà u
                    sắc của Marshmallow
                    Sofa là các góc ghế được bo tròn tinh tế.</p>
                  <button><a href="#">Đến dự án</a></button>
                </div>
                <div className="col-12 col-md-6 img-top-project-v2">
                  <img className="img-responsive d-block w-100" src={process.env.REACT_APP_PUBLIC_URL + "/images/slide 3.jfif"} alt="Third" />
                </div>
              </div>
            </div>
            <a className="carousel-control-next" href="#carouselHomepage1" role="button" data-bs-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="sr-only">Next</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}