import HomepageBanner from "../Banner/HomepageBanner"
import Header from "../Header"
import DesignBlock from "../DesignBlock"
import HomepageCollection from "../Collection/HomepageCollection"
import HomepagePromotion from "../Promotion/HomepagePromotion"
import HomepageArticle from "../Article/HomepageArticle"
import HomepageProject from "../Project//HomepageProject"
import Service from "../Service"
import Newsletter from "../Newsletter"
import SocialSupportOnlineButtons from "../SocialSupportOnlineButtons"
import Footer from "../Footer"

export default function VerifyAccount(){
  return (
    <div className="container-fluid p-0">
      <Header page="empty" />
      <section className="account-login-verification">
        <VerifyAccountContainer />
      </section>
      <Newsletter />
      <SocialSupportOnlineButtons />
      <Footer />
    </div>
    
  )
}

function VerifyAccountContainer(){
  return (
    <div id="login-v2" className="container w-1150 p-0">
        <div className="row">
            <div className="col-12 col-md-6">
                <div className="login-img">
                    <img src={process.env.REACT_APP_PUBLIC_URL + "/images/login.jfif"} alt="Login" />
                </div>
            </div>
            <div className="col-12 col-md-6">
                <div className="login-form account-verification">
                    <h6><strong>VUI LÒNG NHẬP MÃ XÁC THỰC</strong></h6>
                    <span className="veri-send">Mã xác thực của bạn sẽ được gửi bằng tin nhắn đến</span>
                    <span className="veri-phone"><strong>090x xxx xxx</strong></span>
                    <form action="#">
                        <div className="profile-input profile-phone">
                            <input type="text" name="phone-number" id="phone-number" className="phone-number" />
                            <label className="placeholder-phone-number">Mã xác thực (<span>*</span>)</label>
                        </div>
                        <span className="veri-count">Vui lòng chờ 02:59 để gửi lại mã xác thực</span>
                        <span className="create-acc mb-30">Bạn không nhận được mã?  <a href="#"> Gửi lại</a></span>
                        <button type="submit" className="btn action-login">ĐĂNG NHẬP</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
  )
}