export default function Service(){
  return (
    <div id="consulting-service" className="container-fluid w-1150">
      <div className="row">
        <div className="col-12 col-md-6 pr-45">
          <h4 className="title-consulting-service title-consulting-service-mobile">DỊCH VỤ tư vấn</h4>
          <div className="carousel slide" data-ride="carousel">
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img className="img-responsive d-block w-100" src={process.env.REACT_APP_PUBLIC_URL + "/images/slide 3.jfif"} alt="First slide" />
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 pl-45">
          <h4 className="title-consulting-service">DỊCH VỤ tư vấn</h4>
          <div className="content-consulting-service">
            <h6 className="wrap-text">Bất kỳ một thiết kế nào đều phải phải có mục đích cụ thể và được thực hiện bởi con
              người, xoay quanh con người đồng thời phải trả lời được cho câu hỏi: Nó để làm gì? Thiết kế là để làm rõ lý
              do ẩn khuất xung quanh sự sáng tạo ban đầu
              và là sự giải thích cuối cùng cho sự tồn tại của thiết kế đó, đồng thời là những cân nhắc về các khía cạnh
              thẩm mỹ, công năng, tôn giáo, triết học, địa lý, chính trị và văn hóa của cả đối tượng được thiết kế lẫn quá
              trình thiết
              kế
            </h6>
            <p className="wrap-text">Phong khách có thiết kế linh hoạt theo dạng module lắp ghép, bạn dễ dàng thay đổi được
              các góc đặt cũng như sắp xếp sao cho cho phù hợp nhất với không gian của mình. Sự nổi bật khác ngoài màu sắc
              của Marshmallow Sofa là các góc ghế
              được bo tròn tinh tế. Phong khách có thiết kế linh hoạt theo dạng module lắp ghép, bạn dễ dàng thay đổi được
              các góc đặt cũng như sắp xếp sao cho cho phù hợp nhất với không gian của mình. Sự nổi bật khác ngoài màu sắc
              của Marshmallow
              Sofa là các góc ghế được bo tròn tinh tế. Phong khách có thiết kế linh hoạt theo dạng module lắp ghép, bạn
              dễ dàng thay đổi được các góc đặt cũng như sắp xếp sao cho cho phù hợp nhất với không gian của mình. Sự nổi
              bật khác ngoài
              màu sắc của Marshmallow Sofa là các góc ghế được bo tròn tinh tế. Phong khách có thiết kế linh hoạt theo
              dạng module lắp ghép, bạn dễ dàng thay đổi được các góc đặt cũng như sắp xếp sao cho cho phù hợp nhất với
              không gian của mình.
              Sự nổi bật khác ngoài màu sắc của Marshmallow Sofa là các góc ghế được bo tròn tinh tế. Phong khách có thiết
              kế linh hoạt theo dạng module lắp ghép, bạn dễ dàng thay đổi được các góc đặt cũng như sắp xếp sao cho cho
              phù hợp nhất
              với không gian của mình. Sự nổi bật khác ngoài màu sắc của Marshmallow Sofa là các góc ghế được bo tròn tinh
              tế. Phong khách có thiết kế linh hoạt theo dạng module lắp ghép, bạn dễ dàng thay đổi được các góc đặt cũng
              như sắp xếp
              sao cho cho phù hợp nhất với không gian của mình. Sự nổi bật khác ngoài màu sắc của Marshmallow Sofa là các
              góc ghế được bo tròn tinh tế. Phong khách có thiết kế linh hoạt theo dạng module lắp ghép, bạn dễ dàng thay
              đổi được các
              góc đặt cũng như sắp xếp sao cho cho phù hợp nhất với không gian của mình. Sự nổi bật khác ngoài màu sắc của
              Marshmallow Sofa là các góc ghế được bo tròn tinh tế. Phong khách có thiết kế linh hoạt theo dạng module lắp
              ghép, bạn
              dễ dàng thay đổi được các góc đặt cũng như sắp xếp sao cho cho phù hợp nhất với không gian của mình. Sự nổi
              bật khác ngoài màu sắc của Marshmallow Sofa là các góc ghế được bo tròn tinh tế. Phong khách có thiết kế
              linh hoạt theo
              dạng module lắp ghép, bạn dễ dàng thay đổi được các góc đặt cũng như sắp xếp sao cho cho phù hợp nhất với
              không gian của mình. Sự nổi bật khác ngoài màu sắc của Marshmallow Sofa là các góc ghế được bo tròn tinh tế.
              Phong khách
              có thiết kế linh hoạt theo dạng module lắp ghép, bạn dễ dàng thay đổi được các góc đặt cũng như sắp xếp sao
              cho cho phù hợp nhất với không gian của mình. Sự nổi bật khác ngoài màu sắc của Marshmallow Sofa là các góc
              ghế được bo
              tròn tinh tế. Phong khách có thiết kế linh hoạt theo dạng module lắp ghép, bạn dễ dàng thay đổi được các góc
              đặt cũng như sắp xếp sao cho cho phù hợp nhất với không gian của mình. Sự nổi bật khác ngoài màu sắc của
              Marshmallow Sofa
              là các góc ghế được bo tròn tinh tế</p>
            <button><a href="#">Xem thêm</a></button>
          </div>
        </div>
      </div>
    </div>
  )
}