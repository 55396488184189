import HomepageBanner from "../Banner/HomepageBanner"
import Header from "../Header"
import DesignBlock from "../DesignBlock"
import HomepageCollection from "../Collection/HomepageCollection"
import HomepagePromotion from "../Promotion/HomepagePromotion"
import HomepageArticle from "../Article/HomepageArticle"
import HomepageProject from "../Project//HomepageProject"
import Service from "../Service"
import Newsletter from "../Newsletter"
import SocialSupportOnlineButtons from "../SocialSupportOnlineButtons"
import Footer from "../Footer"

export default function Homepage(){
  return (
    <div className="container-fluid p-0">
      <Header page="homepage" />
      <HomepageBanner />
      <DesignBlock />
      <HomepagePromotion />
      <HomepageArticle />
      <HomepageCollection />
      <HomepageProject />
      <Service />
      <Newsletter />
      <SocialSupportOnlineButtons />
      <Footer />
    </div>
  )
}