import HomepageBanner from "../Banner/HomepageBanner"
import Header from "../Header"
import DesignBlock from "../DesignBlock"
import HomepageCollection from "../Collection/HomepageCollection"
import HomepagePromotion from "../Promotion/HomepagePromotion"
import HomepageArticle from "../Article/HomepageArticle"
import HomepageProject from "../Project/HomepageProject"
import Service from "../Service"
import Newsletter from "../Newsletter"
import SocialSupportOnlineButtons from "../SocialSupportOnlineButtons"
import Footer from "../Footer"

export default function ProjectDetail(){
  return (
    <div className="container-fluid p-0">
      <Header page="project-detail" />
      <section className="checkout">
        <ProjectDetailContainer />
      </section>
      <Newsletter />
      <SocialSupportOnlineButtons />
      <Footer />
    </div>
    
  )
}

function ProjectDetailContainer(){
  return (
    <div>
      <div id="filter-v2" className="container w-1150 padding-block-l-r-bt mb-0 pb-0 pt-30">
          <div className="row">
              <div className="col-12">
                  <h4 className="title-filter-v2 mb-25">Dự án Mũi Né Summer resort Phan Thiết</h4>
                  <p className="sub-desc-title mb-20">Chủ đầu tư: tập đoàn Hưng Lộc Phát đến một không gian sống thẩm mỹ và tiện nghi. Nếu ban đang sở hữu một căn hộ chung cư và còn băn khoăn không biết trang trí như thế nào? Hãy tham khảo các mẫu thiết kế nội thất chung cư đẹp do Homeclick
                      chọn lọc sau đây để tìm ra những ý tưởng phù hợp nhất!</p>
              </div>
              <div className="view-btn-filter">
                  <div className="btn-dropdown">
                      <div id="dropMenuFilter01" className="dropdown" data-value="Loại căn hộ" data-map="dropMenuFilter01">
                          <button className="btn" type="button" id="dropFilter01" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <span>Loại căn hộ</span> <i className="fa-solid fa-angle-down"></i>
                      </button>
                          <div className="dropdown-menu" aria-labelledby="dropFilter01">
                              <a className="dropdown-item item-filter" data-value="Căn hộ 1 phòng ngủ (1BR)">Căn hộ 1 phòng ngủ (1BR)</a>
                              <a className="dropdown-item item-filter" data-value="Căn hộ 2 phòng ngủ (2BR)">Căn hộ 2 phòng ngủ (2BR)</a>
                              <a className="dropdown-item item-filter" data-value="Căn hộ 3 phòng ngủ (3BR)">Căn hộ 3 phòng ngủ (3BR)</a>
                              <a className="dropdown-item item-filter" data-value="Căn hộ 4 phòng ngủ (4BR)">Căn hộ 4 phòng ngủ (4BR)</a>
                              <a className="dropdown-item item-filter" data-value="Căn hộ 5 phòng ngủ (5BR)">Căn hộ 5 phòng ngủ (5BR)</a>
                              <a className="action-dropdown item-see-more active">Xem thêm</a>
                              <div className="load-more-item">
                                  <a className="dropdown-item item-filter" data-value="Căn hộ 1 phòng ngủ (1BR)">Căn hộ 1 phòng ngủ (1BR)</a>
                                  <a className="dropdown-item item-filter" data-value="Căn hộ 2 phòng ngủ (2BR)">Căn hộ 2 phòng ngủ (2BR)</a>
                                  <a className="dropdown-item item-filter" data-value="Căn hộ 3 phòng ngủ (3BR)">Căn hộ 3 phòng ngủ (3BR)</a>
                                  <a className="dropdown-item item-filter" data-value="Căn hộ 4 phòng ngủ (4BR)">Căn hộ 4 phòng ngủ (4BR)</a>
                                  <a className="dropdown-item item-filter" data-value="Căn hộ 5 phòng ngủ (5BR)">Căn hộ 5 phòng ngủ (5BR)</a>
                                  <a className="dropdown-item item-filter" data-value="Căn hộ 1 phòng ngủ (1BR)">Căn hộ 1 phòng ngủ (1BR)</a>
                                  <a className="dropdown-item item-filter" data-value="Căn hộ 2 phòng ngủ (2BR)">Căn hộ 2 phòng ngủ (2BR)</a>
                                  <a className="dropdown-item item-filter" data-value="Căn hộ 3 phòng ngủ (3BR)">Căn hộ 3 phòng ngủ (3BR)</a>
                                  <a className="dropdown-item item-filter" data-value="Căn hộ 4 phòng ngủ (4BR)">Căn hộ 4 phòng ngủ (4BR)</a>
                                  <a className="dropdown-item item-filter" data-value="Căn hộ 5 phòng ngủ (5BR)">Căn hộ 5 phòng ngủ (5BR)</a>
                                  <a className="dropdown-item item-filter" data-value="Căn hộ 1 phòng ngủ (1BR)">Căn hộ 1 phòng ngủ (1BR)</a>
                                  <a className="dropdown-item item-filter" data-value="Căn hộ 2 phòng ngủ (2BR)">Căn hộ 2 phòng ngủ (2BR)</a>
                                  <a className="dropdown-item item-filter" data-value="Căn hộ 3 phòng ngủ (3BR)">Căn hộ 3 phòng ngủ (3BR)</a>
                                  <a className="dropdown-item item-filter" data-value="Căn hộ 4 phòng ngủ (4BR)">Căn hộ 4 phòng ngủ (4BR)</a>
                                  <a className="dropdown-item item-filter" data-value="Căn hộ 5 phòng ngủ (5BR)">Căn hộ 5 phòng ngủ (5BR)</a>
                              </div>
                              <a className="action-dropdown item-collapse">Thu gọn</a>
                              <div className="sub-dropdown-menu">
                              </div>
                          </div>
                      </div>
                      <div id="dropMenuFilter02" className="dropdown" data-value="Diện tích" data-map="dropMenuFilter02">
                          <button className="btn" type="button" id="dropMenuFilter02" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <span>Diện tích</span> <i className="fa-solid fa-angle-down"></i>
                          </button>
                          <div id="dropPrice" className="dropdown-menu filterArea" aria-labelledby="dropMenuFilter02">
                              <div className="time-range time-range-area">
                                  <div className="sliders_step1">
                                      <div className="slider-range slider-range-area">
                                          <span tabIndex="0" className="span-range-from ui-slider-handle ui-corner-all ui-state-default"></span>
                                          <span tabIndex="0" className="span-range-to ui-slider-handle ui-corner-all ui-state-default"></span>
                                      </div>
                                  </div>
                              </div>
                              <div className="btn-range">
                                  <span>Từ</span>
                                  <input step="1" type="number" id="amount-min" className="input-range-from" />
                                  <span className="to-range">đến</span>
                                  <input step="1" type="number" id="amount-max" className="input-range-to" />
                                  <span>m2</span>
                              </div>
                          </div>
                      </div>
                      <div id="dropMenuFilter03" className="dropdown" data-value="Phong cách" data-map="dropMenuFilter03">
                          <button className="btn" type="button" id="dropFilter03" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <span>Phong cách</span> <i className="fa-solid fa-angle-down"></i>
                      </button>
                          <div className="dropdown-menu" aria-labelledby="dropFilter03">
                              <a className="dropdown-item item-filter" data-value="Căn hộ 1 phòng ngủ">Căn hộ 1 phòng ngủ </a>
                              <a className="dropdown-item item-filter" data-value="Căn hộ 2 phòng ngủ">Căn hộ 2 phòng ngủ</a>
                              <a className="dropdown-item item-filter" data-value="Căn hộ Studio">Căn hộ Studio</a>
                              <a className="dropdown-item item-filter" data-value="Căn hộ Dual key">Căn hộ Dual key</a>
                              <a className="dropdown-item item-filter" data-value="Căn hộ Duplex">Căn hộ Duplex</a>
                              <a className="action-dropdown item-see-more active">Xem thêm</a>
                              <div className="load-more-item">
                                  <a className="dropdown-item item-filter" data-value="Căn hộ 1 phòng ngủ">Căn hộ 1 phòng ngủ </a>
                                  <a className="dropdown-item item-filter" data-value="Căn hộ 2 phòng ngủ">Căn hộ 2 phòng ngủ</a>
                                  <a className="dropdown-item item-filter" data-value="Căn hộ Studio">Căn hộ Studio</a>
                                  <a className="dropdown-item item-filter" data-value="Căn hộ Dual key">Căn hộ Dual key</a>
                                  <a className="dropdown-item item-filter" data-value="Căn hộ Duplex">Căn hộ Duplex</a>
                                  <a className="dropdown-item item-filter" data-value="Căn hộ 1 phòng ngủ">Căn hộ 1 phòng ngủ </a>
                                  <a className="dropdown-item item-filter" data-value="Căn hộ 2 phòng ngủ">Căn hộ 2 phòng ngủ</a>
                                  <a className="dropdown-item item-filter" data-value="Căn hộ Studio">Căn hộ Studio</a>
                                  <a className="dropdown-item item-filter" data-value="Căn hộ Dual key">Căn hộ Dual key</a>
                                  <a className="dropdown-item item-filter" data-value="Căn hộ Duplex">Căn hộ Duplex</a>
                                  <a className="dropdown-item item-filter" data-value="Căn hộ 1 phòng ngủ">Căn hộ 1 phòng ngủ </a>
                                  <a className="dropdown-item item-filter" data-value="Căn hộ 2 phòng ngủ">Căn hộ 2 phòng ngủ</a>
                                  <a className="dropdown-item item-filter" data-value="Căn hộ Studio">Căn hộ Studio</a>
                                  <a className="dropdown-item item-filter" data-value="Căn hộ Dual key">Căn hộ Dual key</a>
                                  <a className="dropdown-item item-filter" data-value="Căn hộ Duplex">Căn hộ Duplex</a>
                              </div>
                              <a className="action-dropdown item-collapse">Thu gọn</a>
                              <div className="sub-dropdown-menu">
                                  {/* <!-- call ajax --> */}
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div id="content-filter" className="content-filter mb-0">
              <div className="list-btn-filter">
                  <button className="btn btn-remove btn-filter-area btn_dropMenuFilter02">
                      <span>50 - 100 m2</span>
                      <i className="fa-solid fa-xmark"></i>
                  </button>
              </div>
          </div>
          <div id="layout-v2" className="mb-60">
              <div className="mhn-slide owl-carousel">
                  <div className="mhn-item">
                      <div className="mhn-inner">
                          <div className="mhn-prd-img">
                              <a href="#">
                                  <img src={process.env.REACT_APP_PUBLIC_URL + "/images/project-detail/l-1.png"} />
                              </a>
                          </div>
                          <div className="mhn-img">
                              <div className="loader-circle">
                                  <div className="loader-stroke-left"></div>
                                  <div className="loader-stroke-right"></div>
                              </div>
                          </div>
                          <div className="mhn-text collection-v2">
                              <p className="layout-title">Căn hộ 1 phòng ngủ 1 (1BR)</p>
                          </div>
                      </div>
                  </div>
                  <div className="mhn-item">
                      <div className="mhn-inner">
                          <div className="mhn-prd-img">
                              <a href="#">
                                  <img src={process.env.REACT_APP_PUBLIC_URL + "/images/project-detail/l-2.png"} />
                              </a>
                          </div>
                          <div className="mhn-img">
                              <div className="loader-circle">
                                  <div className="loader-stroke-left"></div>
                                  <div className="loader-stroke-right"></div>
                              </div>
                          </div>
                          <div className="mhn-text collection-v2">
                              <p className="layout-title">Căn hộ 1 phòng ngủ 2 (2BR)</p>
                          </div>
                      </div>
                  </div>
                  <div className="mhn-item">
                      <div className="mhn-inner">
                          <div className="mhn-prd-img">
                              <a href="#">
                                  <img src={process.env.REACT_APP_PUBLIC_URL + "/images/project-detail/l-3.png"} />
                              </a>
                          </div>
                          <div className="mhn-img">
                              <div className="loader-circle">
                                  <div className="loader-stroke-left"></div>
                                  <div className="loader-stroke-right"></div>
                              </div>
                          </div>
                          <div className="mhn-text collection-v2">
                              <p className="layout-title">Căn hộ 1 phòng ngủ 3 (3BR)</p>
                          </div>
                      </div>
                  </div>
                  <div className="mhn-item">
                      <div className="mhn-inner">
                          <div className="mhn-prd-img">
                              <a href="#">
                                  <img src={process.env.REACT_APP_PUBLIC_URL + "/images/project-detail/l-4.png"} />
                              </a>
                          </div>
                          <div className="mhn-img">
                              <div className="loader-circle">
                                  <div className="loader-stroke-left"></div>
                                  <div className="loader-stroke-right"></div>
                              </div>
                          </div>
                          <div className="mhn-text collection-v2">
                              <p className="layout-title">Căn hộ 1 phòng ngủ 1 (4BR)</p>
                          </div>
                      </div>
                  </div>
                  <div className="mhn-item">
                      <div className="mhn-inner">
                          <div className="mhn-prd-img">
                              <a href="#">
                                  <img src={process.env.REACT_APP_PUBLIC_URL + "/images/project-detail/l-1.png"} />
                              </a>
                          </div>
                          <div className="mhn-img">
                              <div className="loader-circle">
                                  <div className="loader-stroke-left"></div>
                                  <div className="loader-stroke-right"></div>
                              </div>
                          </div>
                          <div className="mhn-text collection-v2">
                              <p className="layout-title">Căn hộ 1 phòng ngủ 1 (1BR)</p>
                          </div>
                      </div>
                  </div>
                  <div className="mhn-item">
                      <div className="mhn-inner">
                          <div className="mhn-prd-img">
                              <a href="#">
                                  <img src={process.env.REACT_APP_PUBLIC_URL + "/images/project-detail/l-2.png"} />
                              </a>
                          </div>
                          <div className="mhn-img">
                              <div className="loader-circle">
                                  <div className="loader-stroke-left"></div>
                                  <div className="loader-stroke-right"></div>
                              </div>
                          </div>
                          <div className="mhn-text collection-v2">
                              <p className="layout-title">Căn hộ 1 phòng ngủ 2 (2BR)</p>
                          </div>
                      </div>
                  </div>
                  <div className="mhn-item">
                      <div className="mhn-inner">
                          <div className="mhn-prd-img">
                              <a href="#">
                                  <img src={process.env.REACT_APP_PUBLIC_URL + "/images/project-detail/l-3.png"} />
                              </a>
                          </div>
                          <div className="mhn-img">
                              <div className="loader-circle">
                                  <div className="loader-stroke-left"></div>
                                  <div className="loader-stroke-right"></div>
                              </div>
                          </div>
                          <div className="mhn-text collection-v2">
                              <p className="layout-title">Căn hộ 1 phòng ngủ 3 (3BR)</p>
                          </div>
                      </div>
                  </div>
                  <div className="mhn-item">
                      <div className="mhn-inner">
                          <div className="mhn-prd-img">
                              <a href="#">
                                  <img src={process.env.REACT_APP_PUBLIC_URL + "/images/project-detail/l-4.png"} />
                              </a>
                          </div>
                          <div className="mhn-img">
                              <div className="loader-circle">
                                  <div className="loader-stroke-left"></div>
                                  <div className="loader-stroke-right"></div>
                              </div>
                          </div>
                          <div className="mhn-text collection-v2">
                              <p className="layout-title">Căn hộ 1 phòng ngủ 1 (4BR)</p>
                          </div>
                      </div>
                  </div>
                  <div className="mhn-item">
                      <div className="mhn-inner">
                          <div className="mhn-prd-img">
                              <a href="#">
                                  <img src={process.env.REACT_APP_PUBLIC_URL + "/images/project-detail/l-1.png"} />
                              </a>
                          </div>
                          <div className="mhn-img">
                              <div className="loader-circle">
                                  <div className="loader-stroke-left"></div>
                                  <div className="loader-stroke-right"></div>
                              </div>
                          </div>
                          <div className="mhn-text collection-v2">
                              <p className="layout-title">Căn hộ 1 phòng ngủ 1 (1BR)</p>
                          </div>
                      </div>
                  </div>
                  <div className="mhn-item">
                      <div className="mhn-inner">
                          <div className="mhn-prd-img">
                              <a href="#">
                                  <img src={process.env.REACT_APP_PUBLIC_URL + "/images/project-detail/l-2.png"} />
                              </a>
                          </div>
                          <div className="mhn-img">
                              <div className="loader-circle">
                                  <div className="loader-stroke-left"></div>
                                  <div className="loader-stroke-right"></div>
                              </div>
                          </div>
                          <div className="mhn-text collection-v2">
                              <p className="layout-title">Căn hộ 1 phòng ngủ 2 (2BR)</p>
                          </div>
                      </div>
                  </div>
                  <div className="mhn-item">
                      <div className="mhn-inner">
                          <div className="mhn-prd-img">
                              <a href="#">
                                  <img src={process.env.REACT_APP_PUBLIC_URL + "/images/project-detail/l-3.png"} />
                              </a>
                          </div>
                          <div className="mhn-img">
                              <div className="loader-circle">
                                  <div className="loader-stroke-left"></div>
                                  <div className="loader-stroke-right"></div>
                              </div>
                          </div>
                          <div className="mhn-text collection-v2">
                              <p className="layout-title">Căn hộ 1 phòng ngủ 3 (3BR)</p>
                          </div>
                      </div>
                  </div>
                  <div className="mhn-item">
                      <div className="mhn-inner">
                          <div className="mhn-prd-img">
                              <a href="#">
                                  <img src={process.env.REACT_APP_PUBLIC_URL + "/images/project-detail/l-4.png"} />
                              </a>
                          </div>
                          <div className="mhn-img">
                              <div className="loader-circle">
                                  <div className="loader-stroke-left"></div>
                                  <div className="loader-stroke-right"></div>
                              </div>
                          </div>
                          <div className="mhn-text collection-v2">
                              <p className="layout-title">Căn hộ 1 phòng ngủ 1 (4BR)</p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>

      <div id="collection-v2" className="container w-1150 padding-block-l-r-bt">
          <div className="row">
              <div className="col-6 col-md-6 col-lg-4 collection-v2">
                  <div className="img__wrap">
                      <img className="img-responsive img__img w-100 p-0 height-auto" src={process.env.REACT_APP_PUBLIC_URL + "/images/project/image 117.png"} />
                      <div className="img__description_layer">
                          <div>
                              <button className="img__description img__description__mobile"><a href="#">Xem thêm</a></button>
                              <div className="img__description__cart__heart">
                                  <button className="img__description img__description__cart"><a href="#"><i className="fa-solid fa-cart-shopping"></i></a></button>
                                  <button className="img__description img__description__heart"><a href="#"><i className="fa-solid fa-heart"></i></a></button>
                              </div>
                          </div>
                      </div>
                  </div>
                  <a href="#" className="collection-title wrap-text">summer</a>
                  <span className="detail-project">Dự án Mũi Né Summer Land Resort Phan Thiết</span>
                  <span className="detail-project">1 Phòng ngủ - 1BR</span>
              </div>
              <div className="col-6 col-md-6 col-lg-4 collection-v2">
                  <div className="img__wrap">
                      <img className="img-responsive img__img w-100 p-0 height-auto" src={process.env.REACT_APP_PUBLIC_URL + "/images/project/image 118.png"} />
                      <div className="img__description_layer">
                          <div>
                              <button className="img__description img__description__mobile"><a href="#">Xem thêm</a></button>
                              <div className="img__description__cart__heart">
                                  <button className="img__description img__description__cart"><a href="#"><i className="fa-solid fa-cart-shopping"></i></a></button>
                                  <button className="img__description img__description__heart"><a href="#"><i className="fa-solid fa-heart"></i></a></button>
                              </div>
                          </div>
                      </div>
                  </div>
                  <a href="#" className="collection-title wrap-text">milano</a>
                  <span className="detail-project">Dự án Mũi Né Milano Land Resort Phan Thiết</span>
                  <span className="detail-project">2 Phòng ngủ - 2BR</span>
              </div>
              <div className="col-6 col-md-6 col-lg-4 collection-v2">
                  <div className="img__wrap">
                      <img className="img-responsive img__img w-100 p-0 height-auto" src={process.env.REACT_APP_PUBLIC_URL + "/images/project/image 121.png"} />
                      <div className="img__description_layer">
                          <div>
                              <button className="img__description img__description__mobile"><a href="#">Xem thêm</a></button>
                              <div className="img__description__cart__heart">
                                  <button className="img__description img__description__cart"><a href="#"><i className="fa-solid fa-cart-shopping"></i></a></button>
                                  <button className="img__description img__description__heart"><a href="#"><i className="fa-solid fa-heart"></i></a></button>
                              </div>
                          </div>
                      </div>
                  </div>
                  <a href="#" className="collection-title wrap-text">YELLO</a>
                  <span className="detail-project">Dự án Mũi Né YELLO Land Resort Phan Thiết</span>
                  <span className="detail-project">2 Phòng ngủ - 2BR</span>
              </div>
              <div className="col-6 col-md-6 col-lg-4 collection-v2">
                  <div className="img__wrap">
                      <img className="img-responsive img__img w-100 p-0 height-auto" src={process.env.REACT_APP_PUBLIC_URL + "/images/project/image 117.png"} />
                      <div className="img__description_layer">
                          <div>
                              <button className="img__description img__description__mobile"><a href="#">Xem thêm</a></button>
                              <div className="img__description__cart__heart">
                                  <button className="img__description img__description__cart"><a href="#"><i className="fa-solid fa-cart-shopping"></i></a></button>
                                  <button className="img__description img__description__heart"><a href="#"><i className="fa-solid fa-heart"></i></a></button>
                              </div>
                          </div>
                      </div>
                  </div>
                  <a href="#" className="collection-title wrap-text">summer</a>
                  <span className="detail-project">Dự án Mũi Né Summer Land Resort Phan Thiết</span>
                  <span className="detail-project">1 Phòng ngủ - 1BR</span>
              </div>
              <div className="col-6 col-md-6 col-lg-4 collection-v2">
                  <div className="img__wrap">
                      <img className="img-responsive img__img w-100 p-0 height-auto" src={process.env.REACT_APP_PUBLIC_URL + "/images/project/image 118.png"} />
                      <div className="img__description_layer">
                          <div>
                              <button className="img__description img__description__mobile"><a href="#">Xem thêm</a></button>
                              <div className="img__description__cart__heart">
                                  <button className="img__description img__description__cart"><a href="#"><i className="fa-solid fa-cart-shopping"></i></a></button>
                                  <button className="img__description img__description__heart"><a href="#"><i className="fa-solid fa-heart"></i></a></button>
                              </div>
                          </div>
                      </div>
                  </div>
                  <a href="#" className="collection-title wrap-text">milano</a>
                  <span className="detail-project">Dự án Mũi Né Milano Land Resort Phan Thiết</span>
                  <span className="detail-project">2 Phòng ngủ - 2BR</span>
              </div>
              <div className="col-6 col-md-6 col-lg-4 collection-v2">
                  <div className="img__wrap">
                      <img className="img-responsive img__img w-100 p-0 height-auto" src={process.env.REACT_APP_PUBLIC_URL + "/images/project/image 121.png"} />
                      <div className="img__description_layer">
                          <div>
                              <button className="img__description img__description__mobile"><a href="#">Xem thêm</a></button>
                              <div className="img__description__cart__heart">
                                  <button className="img__description img__description__cart"><a href="#"><i className="fa-solid fa-cart-shopping"></i></a></button>
                                  <button className="img__description img__description__heart"><a href="#"><i className="fa-solid fa-heart"></i></a></button>
                              </div>
                          </div>
                      </div>
                  </div>
                  <a href="#" className="collection-title wrap-text">YELLO</a>
                  <span className="detail-project">Dự án Mũi Né YELLO Land Resort Phan Thiết</span>
                  <span className="detail-project">2 Phòng ngủ - 2BR</span>
              </div>
              <div className="col-6 col-md-6 col-lg-4 collection-v2">
                  <div className="img__wrap">
                      <img className="img-responsive img__img w-100 p-0 height-auto" src={process.env.REACT_APP_PUBLIC_URL + "/images/project/image 117.png"} />
                      <div className="img__description_layer">
                          <div>
                              <button className="img__description img__description__mobile"><a href="#">Xem thêm</a></button>
                              <div className="img__description__cart__heart">
                                  <button className="img__description img__description__cart"><a href="#"><i className="fa-solid fa-cart-shopping"></i></a></button>
                                  <button className="img__description img__description__heart"><a href="#"><i className="fa-solid fa-heart"></i></a></button>
                              </div>
                          </div>
                      </div>
                  </div>
                  <a href="#" className="collection-title wrap-text">summer</a>
                  <span className="detail-project">Dự án Mũi Né Summer Land Resort Phan Thiết</span>
                  <span className="detail-project">1 Phòng ngủ - 1BR</span>
              </div>
              <div className="col-6 col-md-6 col-lg-4 collection-v2">
                  <div className="img__wrap">
                      <img className="img-responsive img__img w-100 p-0 height-auto" src={process.env.REACT_APP_PUBLIC_URL + "/images/project/image 118.png"} />
                      <div className="img__description_layer">
                          <div>
                              <button className="img__description img__description__mobile"><a href="#">Xem thêm</a></button>
                              <div className="img__description__cart__heart">
                                  <button className="img__description img__description__cart"><a href="#"><i className="fa-solid fa-cart-shopping"></i></a></button>
                                  <button className="img__description img__description__heart"><a href="#"><i className="fa-solid fa-heart"></i></a></button>
                              </div>
                          </div>
                      </div>
                  </div>
                  <a href="#" className="collection-title wrap-text">milano</a>
                  <span className="detail-project">Dự án Mũi Né Milano Land Resort Phan Thiết</span>
                  <span className="detail-project">2 Phòng ngủ - 2BR</span>
              </div>
              <div className="col-6 col-md-6 col-lg-4 collection-v2">
                  <div className="img__wrap">
                      <img className="img-responsive img__img w-100 p-0 height-auto" src={process.env.REACT_APP_PUBLIC_URL + "/images/project/image 121.png"} />
                      <div className="img__description_layer">
                          <div>
                              <button className="img__description img__description__mobile"><a href="#">Xem thêm</a></button>
                              <div className="img__description__cart__heart">
                                  <button className="img__description img__description__cart"><a href="#"><i className="fa-solid fa-cart-shopping"></i></a></button>
                                  <button className="img__description img__description__heart"><a href="#"><i className="fa-solid fa-heart"></i></a></button>
                              </div>
                          </div>
                      </div>
                  </div>
                  <a href="#" className="collection-title wrap-text">YELLO</a>
                  <span className="detail-project">Dự án Mũi Né YELLO Land Resort Phan Thiết</span>
                  <span className="detail-project">2 Phòng ngủ - 2BR</span>
              </div>
          </div>
          <div className="row">
              <div className="load-more-prd-detail">
                  <button className="btn">
                      Xem thêm
                  </button>
              </div>
          </div>
      </div>

      <div id="request-counselling" className="container-fluid mb-70">
          <div className="row">
              <div className="col-12 col-md-6 p-0">
                  <div className="counselling-img">
                      <img className="img-responsive" src={process.env.REACT_APP_PUBLIC_URL + "/images/project/tu_van.png"} alt="" />
                  </div>
              </div>
              <div className="col-12 col-md-6 p-0">
                  <div className="counselling-info">
                      <h1 className="counselling-title">yêu cầu tư vấn</h1>
                      <span>Để hỗ trợ cho việc tư vấn, xin bạn hãy điền thông tin dưới đây, các tư vấn viên của HOMECLICK sẽ nhanh chóng liên lạc với bạn</span>
                      <button className="btn">BẮT ĐẦU</button>
                  </div>
              </div>
          </div>
      </div>
    </div>
  )
}
