import HomepageBanner from "../Banner/HomepageBanner"
import Header from "../Header"
import DesignBlock from "../DesignBlock"
import HomepageCollection from "../Collection/HomepageCollection"
import HomepagePromotion from "../Promotion/HomepagePromotion"
import HomepageArticle from "../Article/HomepageArticle"
import HomepageProject from "../Project/HomepageProject"
import Service from "../Service"
import Newsletter from "../Newsletter"
import SocialSupportOnlineButtons from "../SocialSupportOnlineButtons"
import Footer from "../Footer"

export default function CheckoutSuccess(){
  return (
    <div className="container-fluid p-0">
      <Header page="checkout-success" />
      <section className="checkout">
        <CheckoutSuccessContainer />
      </section>
      <Newsletter />
      <SocialSupportOnlineButtons />
      <Footer />
    </div>
    
  )
}

function CheckoutSuccessContainer(){
  return (
    <div id="dat-hang" className="container w-1150 padding-block-l-r-bt pt-70">
        <div className="row">
            <div className="col-12 custom-order-2">
                <h1 className="order-success-title mb-40"><strong>CẢM ƠN BẠN ĐÃ ĐẶT HÀNG TẠI HOMECLICK.VN</strong></h1>
            </div>
            <div className="col-12 col-md-6 custom-order-2">
                <div className="order-left">
                    <div className="order-desc pb-70">
                        <div className="sum-total sum-total-success">
                            <span className="title-amount order-id">Đơn hàng: #000001144</span>
                            <span className="prd-amount">64.800.000đ</span>
                        </div>
                        <div className="total-amount order-date">
                            <span className="title-amount">Ngày đặt hàng: 09/10/2022</span>
                            <span className="prd-amount">3 sản phẩm</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 col-md-6 custom-order-2">
            </div>
            <div className="col-12 col-md-5 col-lg-6 custom-order-2">
                <div className="order-left order-border-top">
                    {/* <!-- Thông tin khách hàng --> */}
                    <div className="order-block-info desc-info-cus order-border-bottom mt-20">
                        <strong className="order-block-title mb-10">Thông tin khách hàng</strong> Lê Tấn Minh<br />
                        0908 999 777<br />
                        minhmeomeo@gmail.com<br />
                        <p className="mb-2-"></p>
                        <strong className="order-block-title mb-10">Người nhận hàng</strong> Baby La La<br />
                        0908 229 888<br />
                    </div>
                    {/* <!-- Địa chỉ giao hàng --> */}
                    <div className="order-block-info desc-info-cus order-border-bottom">
                        <strong className="order-block-title mb-10">Địa chỉ giao hàng</strong> 123 Tô Hiến Thành, Phường 14, Quận 3, TP.HCM<br />
                        Ghi chú: Gọi trước khi giao<br />
                    </div>
                    {/* <!-- Chọn hình thức thanh toán --> */}
                    <div className="order-block-info desc-info-cus order-border-bottom">
                        <strong className="order-block-title mb-10">Chọn hình thức thanh toán</strong> Thanh toán qua MoMo
                    </div>
                    {/* <!-- Thông tin xuất hoá đơn --> */}
                    <div className="order-block-info desc-info-cus order-border-bottom">
                        <strong className="order-block-title mb-10">Thông tin xuất hoá đơn</strong> Tên công ty: Công ty TNHH Ánh Dương<br />
                        MST: 034092398<br />
                        Địa chỉ: 123 Tô Hiến Thành, Phường 14, Quận 3, TP.HCM<br />
                        Thông tin liên lạc<br />
                        <ul>
                            <li>Nguyên Phạm</li>
                            <li>Số điện thoại: 090 x xxx xxx</li>
                            <li>Email: xxx@xxx.xxx</li>
                        </ul>
                    </div>
                    <div className="order-block-info desc-info-cus order-border-bottom">
                        Mã nhân viên tư vấn: CTV094932
                    </div>
                    <div className="order-block-info desc-info-cus">
                        Thông tin chi tiết về đơn hàng đã được gửi đến <strong>email xxx@xxx.xxx</strong>. Nếu không tìm thấy, vui lòng tra hộp thư Spam hoặc Junk Folder<br />
                        Mọi thắc mắc về đơn hàng. Quý khách vui lòng liên hệ với bộ phận chăm sóc khách hàng của <strong>Homeclick.vn</strong> qua số điện thoại <strong>0936 900 319</strong> (09:00 - 18:00 từ Thứ 2 đến thứ 7). hoặc email: <strong>info@homeclick.vn</strong>
                    </div>
                </div>
            </div>
            <div className="col-12 col-md-7 col-lg-6 custom-order-1">
                <div className="order-prds">
                    <h6 className="order-prd-title p-15-20 mb-0">Thông tin đơn hàng</h6>
                    <h6 className="order-prd-title p-15-20 mb-0 border-0 pb-0">3 Items</h6>
                    <div className="list-order-items">
                        <div className="row list-order-item m-0">
                            <div className="col-3 col-md-3 p-0">
                                <div className="bg-img">
                                    <img className="img-responsive" src={process.env.REACT_APP_PUBLIC_URL + "/images/dat-hang/prd1.png"} alt="" />
                                </div>
                            </div>
                            <div className="col-6 col-md-6 pr-10 pl-10">
                                <p><span className="prd-qty">1</span><span className="prd-x">X</span><span className="prd-name">BABY COLLECTION</span></p>
                                <p><span className="prd-sku">SKU: HCSF028. 16.-F.V114</span></p>
                                <p><span className="prd-color">Pink tone</span></p>
                                <a className="prd-detail" href="#">Xem chi tiết</a>
                            </div>
                            <div className="col-3 col-md-3 p-0 text-align-right">
                                <p><span className="prd-price-sale">48.670.000đ</span></p>
                                <p><span className="prd-price fz-14">50.670.000đ</span></p>
                                <a className="prd-delete" href="#">Xoá</a>
                            </div>
                            <div className="sub-list-order-item w-100">
                                <div className="row child-order-item m-0 mb-10">
                                    <div className="col-1 col-md-1 p-0"></div>
                                    <div className="col-2 col-md-2 p-0">
                                        <div className="bg-img">
                                            <img className="img-responsive" src={process.env.REACT_APP_PUBLIC_URL + "/images/dat-hang/sub-sp1.png"} alt="" />
                                        </div>
                                    </div>
                                    <div className="col-9 col-md-9 pl-10">
                                        <p><span className="prd-qty">1</span><span className="prd-x">X</span><span className="prd-name">ORLA</span></p>
                                        <p><span className="prd-sku">SKU: HCSF028. 16.-F.V114 W1600 - D800 - H800 mm</span></p>
                                    </div>
                                </div>
                                <div className="row child-order-item m-0 mb-10">
                                    <div className="col-1 col-md-1 p-0"></div>
                                    <div className="col-2 col-md-2 p-0">
                                        <div className="bg-img">
                                            <img className="img-responsive" src={process.env.REACT_APP_PUBLIC_URL + "/images/dat-hang/sub-sp2.png"} alt="" />
                                        </div>
                                    </div>
                                    <div className="col-9 col-md-9 pl-10">
                                        <p><span className="prd-qty">1</span><span className="prd-x">X</span><span className="prd-name">ORLA</span></p>
                                        <p><span className="prd-sku">SKU: HCSF028. 16.-F.V114 W1600 - D800 - H800 mm</span></p>
                                    </div>
                                </div>
                                <div className="row child-order-item m-0 mb-10">
                                    <div className="col-1 col-md-1 p-0"></div>
                                    <div className="col-2 col-md-2 p-0">
                                        <div className="bg-img">
                                            <img className="img-responsive" src={process.env.REACT_APP_PUBLIC_URL + "/images/dat-hang/sub-sp3.png"} alt="" />
                                        </div>
                                    </div>
                                    <div className="col-9 col-md-9 pl-10">
                                        <p><span className="prd-qty">1</span><span className="prd-x">X</span><span className="prd-name">CIRCLE</span></p>
                                        <p><span className="prd-sku">SKU: HCSF028. 16.-F.V114 W1600 - D800 - H800 mm</span></p>
                                    </div>
                                </div>
                                <div className="row child-order-item m-0 mb-10">
                                    <div className="col-1 col-md-1 p-0"></div>
                                    <div className="col-2 col-md-2 p-0">
                                        <div className="bg-img">
                                            <img className="img-responsive" src={process.env.REACT_APP_PUBLIC_URL + "/images/dat-hang/sub-sp4.png"} alt="" />
                                        </div>
                                    </div>
                                    <div className="col-9 col-md-9 pl-10">
                                        <p><span className="prd-qty">1</span><span className="prd-x">X</span><span className="prd-name">SHARE</span></p>
                                        <p><span className="prd-sku">SKU: HCSF028. 16.-F.V114 W1600 - D800 - H800 mm</span></p>
                                    </div>
                                </div>
                                <div className="row child-order-item m-0 mb-10">
                                    <div className="col-1 col-md-1 p-0"></div>
                                    <div className="col-2 col-md-2 p-0">
                                        <div className="bg-img">
                                            <img className="img-responsive" src={process.env.REACT_APP_PUBLIC_URL + "/images/dat-hang/sub-sp1.png"} alt="" />
                                        </div>
                                    </div>
                                    <div className="col-9 col-md-9 pl-10">
                                        <p><span className="prd-qty">1</span><span className="prd-x">X</span><span className="prd-name">ORLA</span></p>
                                        <p><span className="prd-sku">SKU: HCSF028. 16.-F.V114 W1600 - D800 - H800 mm</span></p>
                                    </div>
                                </div>
                                <div className="row child-order-item m-0 mb-10">
                                    <div className="col-1 col-md-1 p-0"></div>
                                    <div className="col-2 col-md-2 p-0">
                                        <div className="bg-img">
                                            <img className="img-responsive" src={process.env.REACT_APP_PUBLIC_URL + "/images/dat-hang/sub-sp2.png"} alt="" />
                                        </div>
                                    </div>
                                    <div className="col-9 col-md-9 pl-10">
                                        <p><span className="prd-qty">1</span><span className="prd-x">X</span><span className="prd-name">ORLA</span></p>
                                        <p><span className="prd-sku">SKU: HCSF028. 16.-F.V114 W1600 - D800 - H800 mm</span></p>
                                    </div>
                                </div>
                                <div className="row child-order-item m-0 mb-10">
                                    <div className="col-1 col-md-1 p-0"></div>
                                    <div className="col-2 col-md-2 p-0">
                                        <div className="bg-img">
                                            <img className="img-responsive" src={process.env.REACT_APP_PUBLIC_URL + "/images/dat-hang/sub-sp3.png"} alt="" />
                                        </div>
                                    </div>
                                    <div className="col-9 col-md-9 pl-10">
                                        <p><span className="prd-qty">1</span><span className="prd-x">X</span><span className="prd-name">CIRCLE</span></p>
                                        <p><span className="prd-sku">SKU: HCSF028. 16.-F.V114 W1600 - D800 - H800 mm</span></p>
                                    </div>
                                </div>
                                <div className="row child-order-item m-0 mb-10">
                                    <div className="col-1 col-md-1 p-0"></div>
                                    <div className="col-2 col-md-2 p-0">
                                        <div className="bg-img">
                                            <img className="img-responsive" src={process.env.REACT_APP_PUBLIC_URL + "/images/dat-hang/sub-sp4.png"} alt="" />
                                        </div>
                                    </div>
                                    <div className="col-9 col-md-9 pl-10">
                                        <p><span className="prd-qty">1</span><span className="prd-x">X</span><span className="prd-name">SHARE</span></p>
                                        <p><span className="prd-sku">SKU: HCSF028. 16.-F.V114 W1600 - D800 - H800 mm</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="list-order-item">
                            <div className="row gr-order-item m-0">
                                <div className="col-3 col-md-3 p-0">
                                    <div className="bg-img">
                                        <img className="img-responsive" src={process.env.REACT_APP_PUBLIC_URL + "/images/dat-hang/prd2.png"} alt="" />
                                    </div>
                                </div>
                                <div className="col-6 col-md-6 pr-10 pl-10">
                                    <p><span className="prd-qty">1</span><span className="prd-x">X</span><span className="prd-name">SILLY SOFA</span></p>
                                    <p><span className="prd-sku">SKU: HCSF028</span></p>
                                    <p><span className="prd-color">Leather</span></p>
                                </div>
                                <div className="col-3 col-md-3 p-0 text-align-right">
                                    <p><span className="prd-price-sale">18.800.000đ</span></p>
                                    <a className="prd-delete" href="#">Xoá</a>
                                </div>
                            </div>
                            <div className="row gr-order-item m-0">
                                <div className="col-3 col-md-3 p-0">
                                    <div className="bg-img">
                                        <img className="img-responsive" src={process.env.REACT_APP_PUBLIC_URL + "/images/dat-hang/prd3.png"} alt="" />
                                    </div>
                                </div>
                                <div className="col-6 col-md-6 pr-10 pl-10">
                                    <p><span className="prd-qty">1</span><span className="prd-x">X</span><span className="prd-name">GỐI DA</span></p>
                                    <p><span className="prd-sku">SKU: H11</span></p>
                                    <p><span className="prd-color">Leather</span></p>
                                </div>
                                <div className="col-3 col-md-3 p-0 text-align-right">
                                    <p><span className="prd-price-sale prd-price-string">Tặng kèm</span></p>
                                    <a className="prd-delete" href="#">Xoá</a>
                                </div>
                            </div>
                        </div>
                        <form className="discount-code d-flex-align" action="#">
                            <input className="txt-info form-control mr-15" type="text" placeholder="Mã giảm giá" />
                            <button className="btn">ÁP DỤNG</button>
                        </form>
                        <div className="block-total-amount">
                            <div className="total-amount total-amount-prd">
                                <span className="title-amount">Tổng tiền hàng</span>
                                <span className="prd-amount">66.800.000đ</span>
                            </div>
                            <div className="total-amount promotion-prd">
                                <span className="title-amount">Khuyến mãi</span>
                                <span className="prd-amount">2.000.000đ</span>
                            </div>
                            <div className="total-amount transport-fee">
                                <span className="title-amount">Phí vận chuyển</span>
                                <span className="prd-amount">2.000.000đ</span>
                            </div>
                        </div>
                        <div className="sum-total">
                            <span className="title-amount">Tổng cộng</span>
                            <span className="prd-amount">64.800.000đ</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
