
export default function HomepageBanner(){
  return (
    <div id="carousel-v2">
      <div id="carouselHomepage" className="carousel slide" data-ride="carousel" data-interval="500000">
        <ol className="carousel-indicators">
          <li data-target="#carouselHomepage" data-slide-to="0" className="active"></li>
          <li data-target="#carouselHomepage" data-slide-to="1"></li>
          {/* <li data-target="#carouselHomepage" data-slide-to="2"></li> */}
        </ol>
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img className="d-block w-100 img-responsive" src={process.env.REACT_APP_PUBLIC_URL + "/images/2021_Januari 1.png"} alt="First slide" />
            <div className="carousel-caption d-md-block">
              <h1>furniture products 2022</h1>
              <span>OUR FAVOURITE LOOKS FOR YOUR STYLE</span>
            </div>
          </div>
          <div className="carousel-item">
            <img className="d-block w-100 img-responsive" src={process.env.REACT_APP_PUBLIC_URL + "/images/darshan-patel-DfzzpBRZCT0-unsplash.png"} alt="Second slide" />
            <div className="carousel-caption d-md-block">
              <h1>furniture products 2022</h1>
              <span>OUR FAVOURITE LOOKS FOR YOUR STYLE</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}