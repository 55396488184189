
export default function Header(props){
  const logo_homepage_url = process.env.REACT_APP_PUBLIC_URL + '/images/homeclick logo-03.png'
  return (
    <div id="header-v2">
      <div id="navbar_top" className="header-info h-65">
        <div className="w-1150 navbar_top_width">
          <div className="header-info-left">
            <div className="navbar_top">
              <button data-bs-toggle="modal" data-bs-target="#modalCustomV2" className="navbar-toggler-custom" type="button">
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>
            <a className="logo-fixed-top" href={process.env.REACT_APP_PUBLIC_URL}>
              <img className="img-responsive w-100 mw-200" src={logo_homepage_url} alt="" />
            </a>
            <div className="navbar_top_hidden">
              <a href="">0936 900 319</a>
              <a href="">info@homeclick.vn</a>
              <a href="">Dịch vụ tư vấn</a>
              <a href="">Công cụ thiết kế 2D</a>
              <a href="">Báo giá</a>
              <a href="">Tin tức</a>
            </div>
          </div>
          <a className="header-v2-logo" href="#">
            <img className="img-responsive w-100 mw-200" src={logo_homepage_url} alt="" />
          </a>
          <div className="header-info-right">
            <form className="header-search" autoComplete="off" action="#">
              <div className="autocomplete">
                <input id="myInput" type="text" name="myCountry" placeholder="Tìm kiếm" />
              </div>
              <button type="submit">
                <img src={process.env.REACT_APP_PUBLIC_URL + "/images/icon/search icon.png"} alt="" />
              </button>
            </form>
            <a href="#" className="hover-change-icon">
              <img className="img-hover" src={process.env.REACT_APP_PUBLIC_URL + "/images/icon/tracking icon.png"} alt="" />
              <img className="un-img-hover" src={process.env.REACT_APP_PUBLIC_URL + "/images/icon/tracking hover.png"} alt="" />
            </a>
            <a className="header-v2-user active-user" href="#">
              <img className="img-hover" src={process.env.REACT_APP_PUBLIC_URL + "/images/icon/account icon.png"} alt="" />
              <img className="un-img-hover" src={process.env.REACT_APP_PUBLIC_URL + "/images/icon/account hover.png"} alt=""/>
            </a>
            <a href="#" className="hover-change-icon">
              <img className="img-hover" src={process.env.REACT_APP_PUBLIC_URL + "/images/icon/cart icon.png"} alt="" />
              <img className="un-img-hover img-cart-hover" src={process.env.REACT_APP_PUBLIC_URL + "/images/icon/cart hover no items.png"} alt="" />
            </a>
          </div>
        </div>
      </div>
      <div className="border-bottom-DCDCDC"></div>
      <div className="header-service h-60 header-service-mobile">
        <div className="header-service-left w-50"></div>
        <div className="header-service-right w-50">
          <form className="header-search header-search-mobile" autoComplete="off" action="#">
            <div className="autocomplete">
              <input id="myInput1" type="text" name="myCountry" placeholder="Tìm kiếm" />
            </div>
            <button type="submit">
              <img src={process.env.REACT_APP_PUBLIC_URL + "/images/icon/search icon.png"} alt="" />
            </button>
          </form>
        </div>
      </div>
      <div className="header-menu w-1150">
        <div className="header-menu-content-v2">
          <nav className="navbar navbar-expand-lg navbar-expand-md navbar-dark bg-white">
            <div className="container-fluid">
              <a className="navbar-brand" href={process.env.REACT_APP_PUBLIC_URL}>
                <img className="img-responsive w-100 mw-200" src={logo_homepage_url} alt="" />
              </a>
              <div className="collapse navbar-collapse" id="main_nav">
                <ul className="navbar-nav">
                  <li className="nav-item active"> <a className="nav-link" href={process.env.REACT_APP_PUBLIC_URL}> Trang chủ </a> </li>
                  <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown">  Sản phẩm  </a>
                    <ul className="dropdown-menu">
                      <li>
                        <a className="dropdown-item has-submenu" href="#"> PHÒNG KHÁCH <i className="fa-solid fa-angle-right"></i> </a>
                        <ul className="submenu dropdown-menu">
                          <li>
                            <a className="dropdown-item has-submenu" href="#"> SOFA <i className="fa-solid fa-angle-right"></i> </a>
                            <ul className="submenu dropdown-menu">
                              <li><a className="dropdown-item" href="#">2 chỗ</a></li>
                              <li><a className="dropdown-item" href="#">3 chỗ</a></li>
                              <li><a className="dropdown-item" href="#">Sofa góc</a></li>
                            </ul>
                          </li>
                          <li><a className="dropdown-item" href="#">ARMCHAIR</a></li>
                          <li><a className="dropdown-item" href="#">POUF</a></li>
                          <li><a className="dropdown-item" href="#">COFFEE TABLE</a></li>
                          <li><a className="dropdown-item" href="#">SIDE TABLE</a></li>
                          <li><a className="dropdown-item" href="#">TIVI CABINET</a></li>
                        </ul>
                      </li>
                      <li><a className="dropdown-item has-submenu" href="#"> PHÒNG ĂN <i className="fa-solid fa-angle-right"></i> </a>
                        <ul className="submenu dropdown-menu">
                          <li><a className="dropdown-item" href="#">DINING CHAIR</a></li>
                          <li><a className="dropdown-item" href="#">DINNING TABLE</a></li>
                          <li><a className="dropdown-item" href="#">CUPBOARD</a></li>
                        </ul>
                      </li>
                      <li><a className="dropdown-item has-submenu" href="#"> PHÒNG NGỦ <i className="fa-solid fa-angle-right"></i> </a>
                        <ul className="submenu dropdown-menu">
                          <li><a className="dropdown-item" href="#">BED</a></li>
                          <li><a className="dropdown-item" href="#">NIGHT STAND</a></li>
                          <li><a className="dropdown-item" href="#">DRESSING TABLE</a></li>
                        </ul>
                      </li>
                      <li><a className="dropdown-item" href="#"> COMBO </a></li>
                    </ul>
                  </li>
                  <li className="nav-item"><a className="nav-link" href="#"> Dự án </a></li>
                  <li className="nav-item"><a className="nav-link" href="#"> Combo </a></li>
                  <li className="nav-item"><a className="nav-link" href="#"> Khuyến mãi </a></li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>
      <HeaderBreadcrumbs page={props.page} />
      <div className="modal left fade" id="modalCustomV2" tabIndex="" role="dialog" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <span></span>
              <button type="button" data-bs-dismiss="modal" aria-label="Close">
                <i className="fa-solid fa-xmark"></i>
              </button>
            </div>
            <div className="modal-body">
              <div className="flex-sm-column flex-row">
                <div className="modal-image">
                  <a href={process.env.REACT_APP_PUBLIC_URL}>
                    <img className="mw-200" src={logo_homepage_url} alt="" />
                  </a>
                </div>
                <nav className="sidebar">
                  {/* <!-- https://www.codeply.com/go/q0b6ILuRyx/bootstrap-4-vertical-sidebar-nav-with-submenu --> */}
                  <ul className="nav flex-column" id="nav_accordion">
                    <li className="nav-item nav-item-user">
                      <a className="nav-link collapsed" href="#submenu_user" data-toggle="collapse" data-target="#submenu_user">
                        <img src={process.env.REACT_APP_PUBLIC_URL + "/images/icon/account hover.png"} alt="" />
                        <span className="hello-user-v2">Xin chào, Lê Tấn Minh</span>
                        <i className="fa-solid fa-angle-down"></i>
                      </a>
                      <div className="collapse submenu" id="submenu_user" aria-expanded="false">
                        <ul className="flex-column nav">
                          <li className="nav-item">
                            <a className="nav-link" href="#"> Thông tin tài khoản </a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" href="#"> Đơn hàng của tôi </a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" href="#"> Mã giảm giá/ khuyến mãi </a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" href="#"> Báo giá của tôi </a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" href="#"> Tài khoản thành viên </a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" href="#"> Thoát tài khoản </a>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href={process.env.REACT_APP_PUBLIC_URL}> Trang chủ</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link collapsed" href="#submenu1" data-toggle="collapse" data-target="#submenu1"> Sản
                        phẩm <i className="fa-solid fa-angle-down"></i></a>
                      <div className="collapse submenu" id="submenu1" aria-expanded="false">
                        <ul className="flex-column nav">
                          <li className="nav-item">
                            <a className="nav-link collapsed" href="#submenu1sub1" data-toggle="collapse"
                              data-target="#submenu1sub1"><span> PHÒNG KHÁCH </span><i
                                className="fa-solid fa-angle-down"></i></a>
                            <div className="collapse submenu" id="submenu1sub1" aria-expanded="false">
                              <ul className="flex-column nav">
                                <li className="nav-item">
                                  <a className="nav-link collapsed" href="#submenu1sub21" data-toggle="collapse"
                                    data-target="#submenu1sub21"><span> SOFA </span><i
                                      className="fa-solid fa-angle-down"></i></a>
                                  <div className="collapse submenu" id="submenu1sub21" aria-expanded="false">
                                    <ul className="flex-column nav">
                                      <li className="nav-item">
                                        <a className="nav-link" href="#"> 2 chỗ </a>
                                      </li>
                                      <li className="nav-item">
                                        <a className="nav-link" href="#"> 3 chỗ </a>
                                      </li>
                                      <li className="nav-item">
                                        <a className="nav-link" href="#"> Sofa góc </a>
                                      </li>
                                    </ul>
                                  </div>
                                </li>
                                <li className="nav-item">
                                  <a className="nav-link" href="#"> ARMCHAIR </a>
                                </li>
                                <li className="nav-item">
                                  <a className="nav-link" href="#"> POUF </a>
                                </li>
                                <li className="nav-item">
                                  <a className="nav-link" href="#"> COFFEE TABLE </a>
                                </li>
                                <li className="nav-item">
                                  <a className="nav-link" href="#"> SIDE TABLE </a>
                                </li>
                                <li className="nav-item">
                                  <a className="nav-link" href="#"> TIVI CABINET </a>
                                </li>
                              </ul>
                            </div>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link collapsed" href="#submenu1sub2" data-toggle="collapse"
                              data-target="#submenu1sub2"><span> PHÒNG ĂN </span><i className="fa-solid fa-angle-down"></i></a>
                            <div className="collapse submenu" id="submenu1sub2" aria-expanded="false">
                              <ul className="flex-column nav">
                                <li className="nav-item">
                                  <a className="nav-link" href="#"> DINING CHAIR </a>
                                </li>
                                <li className="nav-item">
                                  <a className="nav-link" href="#"> DINNING TABLE </a>
                                </li>
                                <li className="nav-item">
                                  <a className="nav-link" href="#"> CUPBOARD </a>
                                </li>
                              </ul>
                            </div>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link collapsed" href="#submenu1sub3" data-toggle="collapse"
                              data-target="#submenu1sub3"><span> PHÒNG NGỦ </span><i className="fa-solid fa-angle-down"></i></a>
                            <div className="collapse submenu" id="submenu1sub3" aria-expanded="false">
                              <ul className="flex-column nav">
                                <li className="nav-item">
                                  <a className="nav-link" href="#"> BED </a>
                                </li>
                                <li className="nav-item">
                                  <a className="nav-link" href="#"> NIGHT STAND </a>
                                </li>
                                <li className="nav-item">
                                  <a className="nav-link" href="#"> DRESSING TABLE </a>
                                </li>
                              </ul>
                            </div>
                          </li>
                          <li className="nav-item"><a className="nav-link py-0" href="#"><span> COMBO </span></a></li>
                        </ul>
                      </div>
                    </li>
                    <li className="nav-item"><a className="nav-link" href="#"> Dự án </a></li>
                    <li className="nav-item"><a className="nav-link" href="#"> Combo </a></li>
                    <li className="nav-item"><a className="nav-link" href="#"> Khuyến mãi </a></li>
                  </ul>
                </nav>
                <div className="modal-content-2 mb-25">
                  <ul className="nav flex-column">
                    <li><a className="modal-phone" href="#">0936 900 319</a></li>
                    <li><a href="#">info@homeclick.vn</a></li>
                  </ul>
                </div>
                <div className="modal-content-2 mb-65">
                  <ul className="nav flex-column">
                    <li><a href="#">Dịch vụ tư vấn</a></li>
                    <li><a href="#">Công cụ thiết kế 2D</a></li>
                    <li><a href="#">Báo giá</a></li>
                    <li><a href="#">Tin tức</a></li>
                  </ul>
                </div>
                <div className="modal-content-2">
                  <span>Theo dõi chúng tôi</span>
                  <div className="modal-subscribe-icon">
                    <a href="#"><i className="fa-brands fa-facebook-f"></i></a>
                    <a href="#"><i className="fa-brands fa-instagram"></i></a>
                    <a href="#"><i className="fa-brands fa-linkedin-in"></i></a>
                    <a href="#"><i className="fa-brands fa-pinterest"></i></a>
                    <a href="#"><i className="fa-brands fa-youtube"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function HeaderBreadcrumbs(props){
  switch(props.page) {
    case "gioi-thieu":
      return (
        <div className="header-partner">
          <div className="container p-0 w-1150">
            <a href="#">Trang chủ</a>
            <a href="#" className="active">Giới thiệu</a>
          </div>
        </div>
      )
    case "empty":
      return (null)
    case "checkout":
      return (
        <div className="header-partner">
          <div className="container p-0 w-1150">
            <a href="#">Trang chủ</a>
            <a href="#" className="active">Đặt hàng</a>
          </div>
        </div>
      )
    case "checkout-success":
      return (
        <div className="header-partner">
          <div className="container p-0 w-1150">
            <a href="#">Trang chủ</a>
            <a href="#" className="active">Đặt hàng thành công</a>
          </div>
        </div>
      )
    case "payment-guide":
      return (
        <div className="header-partner">
          <div className="container p-0 w-1150">
            <a href="#">Trang chủ</a>
            <a href="#" className="active">Hướng dẫn thanh toán</a>
          </div>
        </div>
      )
    case "privacy-policy":
      return (
        <div className="header-partner">
          <div className="container p-0 w-1150">
            <a href="#">Trang chủ</a>
            <a href="#" className="active">Chính sách bảo mật</a>
          </div>
        </div>
      )
    case "shopping-guide":
      return (
        <div className="header-partner">
          <div className="container p-0 w-1150">
            <a href="#">Trang chủ</a>
            <a href="#" className="active">Hướng dẫn mua hàng</a>
          </div>
        </div>
      )
    case "project-detail":
      return (
        <div className="header-partner">
          <div className="container p-0 w-1150">
            <a href="#">Trang chủ</a>
            <a href="#">Dự án</a>
            <a href="#" className="active">Dự án Mũi Né Summer resort Phan Thiết</a>
          </div>
        </div>
      )
    case "project":
      return (
        <div className="header-partner">
          <div className="container p-0 w-1150">
            <a href="#">Trang chủ</a>
            <a href="#" className="active">Dự án</a>
          </div>
        </div>
      )
    case "terms-of-use":
      return (
        <div className="header-partner">
          <div className="container p-0 w-1150">
            <a href="#">Trang chủ</a>
            <a href="#" className="active">Điều khoản sử dụng</a>
          </div>
        </div>
      )
    case "warranty-policy":
      return (
        <div className="header-partner">
          <div className="container p-0 w-1150">
            <a href="#">Trang chủ</a>
            <a href="#" className="active">Chính sách giao hàng</a>
          </div>
        </div>
      )
    default:
      return (
        <div className="header-partner header-partner-homepage">
          <div className="w-1150 notification">
            <p>Tham gia chương trình đối tác của Homeclick. <a href="#">Tìm hiểu thêm</a></p>
            <i className="fa-solid fa-xmark"></i>
          </div>
        </div>
      )
  }
  
}

