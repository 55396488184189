import HomepageBanner from "../Banner/HomepageBanner"
import Header from "../Header"
import DesignBlock from "../DesignBlock"
import HomepageCollection from "../Collection/HomepageCollection"
import HomepagePromotion from "../Promotion/HomepagePromotion"
import HomepageArticle from "../Article/HomepageArticle"
import HomepageProject from "../Project//HomepageProject"
import Service from "../Service"
import Newsletter from "../Newsletter"
import SocialSupportOnlineButtons from "../SocialSupportOnlineButtons"
import Footer from "../Footer"

export default function Login(){
  return (
    <div className="container-fluid p-0">
      <Header page="empty" />
      <section className="account-login-verification">
        <LoginContainer />
      </section>
      <Newsletter />
      <SocialSupportOnlineButtons />
      <Footer />
    </div>
    
  )
}

function LoginContainer(){
  return (
    <div id="login-v2" className="container w-1150 p-0">
        <div className="row">
            <div className="col-12 col-md-6">
                <div className="login-img">
                    <img src={process.env.REACT_APP_PUBLIC_URL + "/images/login.jfif"} alt="Login" />
                </div>
            </div>
            <div className="col-12 col-md-6">
                <div className="login-form">
                    <h6><strong>ĐĂNG NHẬP THÀNH VIÊN HOMECLICK.VN</strong></h6>
                    <form action="#">
                        <div className="profile-input profile-phone">
                            <input type="text" name="phone-number" id="phone-number" className="phone-number" />
                            <label className="placeholder-phone-number">Số điện thoại (<span>*</span>)</label>
                        </div>
                        <div className="profile-input profile-password">
                            <input type="password" name="password" id="password" className="password" />
                            <label className="placeholder-password">Mật khẩu (<span>*</span>)</label>
                            <div className="password-showhide">
                                <i className="show-password fa-solid fa-eye"></i>
                                <i className="hide-password fa-solid fa-eye-slash"></i>
                            </div>
                        </div>
                        <div className="remember-forgot">
                            <div className="forgot-password">
                                <a href="#"><strong>Quên mật khẩu?</strong></a>
                            </div>
                            <div className="remember-login">
                                <input type="checkbox" name="remember-login" id="remember-login" />
                                <span>Ghi nhớ mật khẩu?</span>

                            </div>
                        </div>
                        <button type="submit" className="btn action-login">ĐĂNG NHẬP</button>
                        <span className="create-acc">Bạn chưa có tài khoản? <a href="#">Đăng ký</a></span>
                    </form>
                </div>
            </div>
        </div>
    </div>
  )
}