
export default function HomepageCollection(){
  return (
    <div id="collection-v2" className="container-fluid padding-block-l-r-bt w-1150">
      <div className="row">
        <div className="col-12">
          <h4 className="title-collection-v2">collection</h4>
        </div>
        <div className="col-12 col-sm-4 collection-v2">
          <div className="img__wrap">
            <img className="img-responsive img__img w-100" src={process.env.REACT_APP_PUBLIC_URL + "/images/collection1.png"} />
            <div className="img__description_layer">
              <div>
                <button className="img__description img__description__mobile"><a href="#">Xem thêm</a></button>
                <div className="img__description__cart__heart">
                  <button className="img__description img__description__cart"><a href="#"><i
                        className="fa-solid fa-cart-shopping"></i></a></button>
                  <button className="img__description img__description__heart"><a href="#"><i
                        className="fa-solid fa-heart"></i></a></button>
                </div>
              </div>
            </div>
          </div>
          <a href="#" className="collection-title wrap-text">summer collection summer collection summer collection summer
            collection summer collection summer collection</a>
          <p className="collection-sku">SKU: HCB0001</p>
          <div className="discount-price-v2">
            <span className="original-price">32.980.000đ</span>
            <span className="percent-discount">-5%</span>
          </div>
          <span className="price-v2">29.996.000đ</span>
        </div>
        <div className="col-12 col-sm-4 collection-v2">
          <div className="img__wrap">
            <img className="img-responsive img__img w-100" src={process.env.REACT_APP_PUBLIC_URL + "/images/collection2.png"} />
            <div className="img__description_layer">
              <div>
                <button className="img__description img__description__mobile"><a href="#">Xem thêm</a></button>
                <div className="img__description__cart__heart">
                  <button className="img__description img__description__cart"><a href="#"><i
                        className="fa-solid fa-cart-shopping"></i></a></button>
                  <button className="img__description img__description__heart"><a href="#"><i
                        className="fa-solid fa-heart"></i></a></button>
                </div>
              </div>
            </div>
          </div>
          <a href="#" className="collection-title wrap-text">milano collection</a>
          <p className="collection-sku">SKU: HCB0002</p>
          <span className="price-v2">19.296.000đ</span>
        </div>
        <div className="col-12 col-sm-4 collection-v2">
          <div className="img__wrap">
            <img className="img-responsive img__img w-100" src={process.env.REACT_APP_PUBLIC_URL + "/images/collection3.png"} />
            <div className="img__description_layer">
              <div>
                <button className="img__description img__description__mobile"><a href="#">Xem thêm</a></button>
                <div className="img__description__cart__heart">
                  <button className="img__description img__description__cart"><a href="#"><i
                        className="fa-solid fa-cart-shopping"></i></a></button>
                  <button className="img__description img__description__heart"><a href="#"><i
                        className="fa-solid fa-heart"></i></a></button>
                </div>
              </div>
            </div>
          </div>
          <a href="#" className="collection-title wrap-text">yello collection</a>
          <p className="collection-sku">SKU: HCB0003</p>
          <span className="price-v2">27.296.000đ</span>
        </div>
      </div>
    </div>
  )
}