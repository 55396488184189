import HomepageBanner from "../Banner/HomepageBanner"
import Header from "../Header"
import DesignBlock from "../DesignBlock"
import HomepageCollection from "../Collection/HomepageCollection"
import HomepagePromotion from "../Promotion/HomepagePromotion"
import HomepageArticle from "../Article/HomepageArticle"
import HomepageProject from "../Project/HomepageProject"
import Service from "../Service"
import Newsletter from "../Newsletter"
import SocialSupportOnlineButtons from "../SocialSupportOnlineButtons"
import Footer from "../Footer"

export default function TermsOfUse(){
  return (
    <div className="container-fluid p-0">
      <Header page="terms-of-use" />
      <section className="checkout">
        <TermsOfUseBanner />
        <TermsOfUseContainer />
      </section>
      <Newsletter />
      <SocialSupportOnlineButtons />
      <Footer />
    </div>
    
  )
}

function TermsOfUseBanner(){
  return (
    <div id="carousel-v2">
        <div className="carousel slide" data-ride="carousel" data-interval="0">
            <div className="carousel-inner">
                <div className="carousel-item active">
                    <img className="d-block w-100 img-responsive" src={process.env.REACT_APP_PUBLIC_URL + "/images/terms-of-use.jfif"} alt="First slide" />
                </div>
            </div>
        </div>
    </div>
  )
}

function TermsOfUseContainer(){
  return (
    <div id="terms-of-use" className="container w-1150 padding-block-l-r-bt pt-70">
        <div className="row">
            <div className="col-12">
                <h1 className="terms-of-use-title">ĐIỀU KHOẢN SỬ DỤNG</h1>
            </div>
            <div className="col-12">
                <ul className="nav flex-column" id="nav_accordion">
                    <li className="nav-item">
                        <a className="nav-link collapsed" href="#terms1" data-bs-toggle="collapse" data-bs-target="#terms1">  I. GIỚI THIỆU <i className="fa-solid fa-angle-down"></i></a>
                        <div className="submenu collapse show" id="terms1" aria-expanded="false">
                            Chào mừng quý khách đến với <a href="#">homeclick.vn</a>, chúng tôi là Công Ty TNHH DB Group có địa chỉ trụ sở tại 68 Nguyễn Huệ, Phường Bến Nghé, Quận 1, TP.Hồ Chí Minh, hoạt động kinh doanh thương mại điện tử thông qua website
                            <a href="#">www.homeclick.vn</a> đã được đăng ký chính thức với Bộ Công Thương Việt Nam. <br />
                            <br /> Khi quý khách truy cập vào trang <a href="#">homeclick.vn</a> nghĩa là quý khách đồng ý với các điều khoản sử dụng được trình bày ở trang này. Chúng tôi có quyền thay đổi, chỉnh sửa, thêm hoặc lược bỏ nội dung trong Điều khoản
                            sử dụng này vào bất cứ lúc nào. Các thay đổi có hiệu lực ngay khi được đăng trên <a href="#">homeclick.vn</a> mà không cần thông báo trước. Nếu quý khách tiếp tục truy cập sau khi các thay đổi về Điều khoản sử dụng được đăng
                            tải, có nghĩa là quý khách chấp nhận với những thay đổi đó. <br />
                            <br />Quý khách vui lòng kiểm tra thường xuyên Điều khoản sử dụng để cập nhật kịp thời những thay đổi, đảm bảo quý khách hiểu đúng và đầy đủ quyền lợi cũng như trách nhiệm của mình khi giao dịch tại <a href="#">homeclick.vn</a>
                        </div>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link collapsed" href="#terms2" data-bs-toggle="collapse" data-bs-target="#terms2">  II. QUY ĐỊNH CHUNG <i className="fa-solid fa-angle-down"></i></a>
                        <div className="collapse submenu" id="terms2" aria-expanded="false">
                            Khi truy cập vào trang homeclick.vn, quý khách cần đảm bảo có đầy đủ hành vi dân sự để thực hiện các giao dịch mua bán hàng hóa theo quy định hiện hành của pháp luật Việt Nam.<br />
                            <br /> Quý khách cần cung cấp thông tin chính xác khi đăng ký tài khoản và cập nhật nếu có bất kỳ thay đổi nào. Tài khoản sử dụng được cấp cho quý khách để mua sắm, giao dịch trên homeclick.vn đều tuân thủ và trong khuôn khổ Điều
                            khoản sử dụng đã đề ra. Mỗi người truy cập phải có trách nhiệm với mật khẩu, tài khoản và các giao dịch của mình trên homeclick.vn. Quý khách phải thông báo cho chúng tôi ngay khi phát hiện thông tin hoặc dấu hiệu tài khoản
                            bị truy cập trái phép. Chúng tôi không chịu bất kỳ trách nhiệm nào, dù trực tiếp hay gián tiếp, đối với những thiệt hại hoặc mất mát gây ra do quý khách không tuân thủ các hướng dẫn hoặc quy định trên homeclick.vn
                        </div>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link collapsed" href="#terms3" data-bs-toggle="collapse" data-bs-target="#terms3">  III. ĐẶT HÀNG & CHẤP NHẬN ĐƠN HÀNG <i className="fa-solid fa-angle-down"></i></a>
                        <div className="collapse submenu" id="terms3" aria-expanded="false">
                            Quý khách vui lòng cung cấp chính xác và kiểm tra đầy đủ các thông tin trên đơn hàng trước khi hoàn tất đặt hàng. Nếu có vướng mắc trong quá trình đặt hàng, quý khách có thể tham khảo hướng dẫn mua hàng để hiểu rõ hơn. Trong vòng 4 giờ làm việc sau khi
                            quý khách đặt hàng, nhân viên homeclick.vn sẽ liên hệ với quý khách để xác nhận thông tin trước khi đơn đặt hàng của quý khách được chấp nhận và tiến hành các bước tiếp theo.<br />
                            <br /> Chúng tôi có quyền từ chối hoặc hủy đơn đặt hàng của quý khách vào bất kỳ lúc nào (kể cả các đơn đặt hàng đã xác nhận hoặc đã thanh toán) vì một số lý do sau:
                            <ul>
                                <li>
                                    Liên quan đến lỗi kỹ thuật, lỗi hệ thống.
                                </li>
                                <li>
                                    Nhân viên chúng tôi không liên lạc được với quý khách để xác nhận đơn hàng trong vòng tối đa 24 giờ kể từ lúc quý khách hoàn tất đặt hàng trên homeclick.vn
                                </li>
                                <li>
                                    Chúng tôi chưa hoặc không nhận được thanh toán của quý khách trong thời gian tối đa 24 giờ kể từ thời điểm nhân viên chúng tôi xác nhận thông tin đơn hàng và hình thức thanh toán chuyển khoản ngân hàng với quý khách. Quý khách nên tham khảo và làm theo
                                    hướng dẫn thanh toán để tránh các rủi ro ảnh hưởng đến việc thực hiện đơn hàng.
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link collapsed" href="#terms4" data-bs-toggle="collapse" data-bs-target="#terms4">  IV. GIÁ BÁN & HÓA ĐƠN BÁN HÀNG <i className="fa-solid fa-angle-down"></i></a>
                        <div className="collapse submenu" id="terms4" aria-expanded="false">
                            Với cam kết chỉ cung cấp hàng hoá có chất lượng, uy tín nên tất cả sản phẩm quý khách mua từ homeclick.vn đều có hoá đơn giá trị gia tăng (hóa đơn VAT) theo giá bán thể hiện trên đơn đặt hàng của quý khách. Hoá đơn giá trị gia tăng sẽ được xuất dưới dạng
                            hoá đơn điện tử, do Công Ty TNHH DB Group xuất cho quý khách trong vòng 7 ngày làm việc kể từ thời điểm quý khách nhận hàng thành công.
                        </div>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link collapsed" href="#terms5" data-bs-toggle="collapse" data-bs-target="#terms5"> V. THAY ĐỔI HOẶC HỦY BỎ ĐƠN HÀNG ĐÃ ĐẶT HÀNG THÀNH CÔNG  <i className="fa-solid fa-angle-down"></i></a>
                        <div className="collapse submenu" id="terms5" aria-expanded="false">
                            Quý khách hoàn toàn có quyền thay đổi hoặc huỷ bỏ đơn đặt hàng đã thực hiện thành công trên homeclick.vn. Tuy nhiên để giảm thiểu các thiệt hại có thể có, chúng tôi đề nghị quý khách chủ động thông báo cho chúng tôi biết ngay khi quý khách muốn thay đổi
                            hoặc huỷ bỏ đơn hàng bằng một trong những cách sau: Gọi điện thoại thông báo trực tiếp cho chúng tôi qua số điện thoại <strong>0936 900 319</strong> Gửi email đến info@homeclick.vn với tiêu đề email
                            gồm mã đơn đặt hàng và yêu cầu của quý khách, ví dụ: #Đơn hàng 157 – hủy hoặc #Đơn hàng 157 – thay đổi và nêu nội dung cần thay đổi. Chúng tôi sẽ liên hệ lại để xác nhận với quý khách. Các chi phí phát sinh từ việc thay đổi
                            hoặc hủy đơn hàng (nếu có) sẽ được chúng tôi thông báo và xác nhận với khách hàng trước khi tiến hành công việc thay đổi.
                        </div>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link collapsed" href="#terms6" data-bs-toggle="collapse" data-bs-target="#terms6"> VI. THÔNG TIN SẢN PHẨM  <i className="fa-solid fa-angle-down"></i></a>
                        <div className="collapse submenu" id="terms6" aria-expanded="false">
                            Tất cả thông tin sản phẩm được giới thiệu trên homeclick.vn đều do đối tác của chúng tôi là chủ sở hữu của thương hiệu hoặc là đơn vị phân phối chính thức của thương hiệu tại thị trường Việt Nam cung cấp. Do đó nếu quý khách phát hiện bất kỳ thông tin
                            nào trên sản phẩm sai lệch với thông tin được giới thiệu trên homeclick.vn thì có thể hoàn trả lại hàng cho chúng tôi hoàn toàn miễn phí theo Chính sách đổi, trả & bảo hành mà chúng tôi đã cam kết. Quý khách cũng có thể gửi
                            bất kỳ câu hỏi hoặc thắc mắc nào liên quan đến sản phẩm, thông tin sản phẩm cho chúng tôi qua mục hỏi & đáp hoặc qua email info@homeclick.vn để được giải đáp đầy đủ, rõ ràng trước khi quyết định mua sắm tại homeclick.vn
                        </div>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link collapsed" href="#terms7" data-bs-toggle="collapse" data-bs-target="#terms7"> VII. NHẬN XÉT CỦA KHÁCH HÀNG  <i className="fa-solid fa-angle-down"></i></a>
                        <div className="collapse submenu" id="terms7" aria-expanded="false">
                            Mọi ý kiến, nhận xét trung thực và khách quan của quý khách đối với các sản phẩm trên homeclick.vn đều là thông tin bổ ích giúp những khách hàng khác tham khảo trong quyết định mua sắm của họ và cũng giúp chúng tôi kiểm tra lại những sản phẩm mà quý khách
                            chưa hài lòng. Vì thế để thông tin trong mục nhật xét có cơ sở và minh bạch chúng tôi giới hạn đối tượng được nhận xét sản phẩm là những khách hàng đã mua sản phẩm đó. Vì uy tín của các thương hiệu và đối tác phân phối hàng
                            hoá chúng tôi đề nghị quý khách không nhận xét bằng những thông tin giả mạo; không dùng từ ngữ tiêu cực, thiếu thiện chí góp ý để cải tiến hoặc vì mục đích hạ thấp uy tín, hình ảnh của họ. Nếu phát hiện chúng tôi sẽ đóng vĩnh
                            viễn tài khoản của quý khách ngay lập tức và/hoặc áp dụng các biện pháp khác theo quy định của pháp luật Việt Nam.
                        </div>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link collapsed" href="#terms8" data-bs-toggle="collapse" data-bs-target="#terms8"> VIII. HÌNH THỨC THANH TOÁN  <i className="fa-solid fa-angle-down"></i></a>
                        <div className="collapse submenu" id="terms8" aria-expanded="false">
                            <p>Thanh toán chuyển khoản qua ngân hàng.</p>
                            <p>Trong trường hợp quý khách ở khu vực TP.HCM:</p>
                            <p>Đặt cọc 50% giá trị đơn hàng.</p>
                            <p>Thanh toán 50% còn lại khi nhận hàng.</p>
                            <p>Trong trường hợp quý khách ở các tỉnh thành khác:</p>
                            <p>Thanh toán 50% giá trị đơn hàng.</p>
                            <p>Thanh toán 50% còn lại khi nhân viên homeclick gửi hình ảnh xác nhận xuất kho giao hàng. (Hình ảnh sản phẩm hoàn chỉnh trước và sau đóng gói).</p>
                            <p>Quý khách có thể tham khảo hướng dẫn thanh toán để biết thêm chi tiết.</p>
                        </div>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link collapsed" href="#terms9" data-bs-toggle="collapse" data-bs-target="#terms9">IX. QUY ĐỊNH TRẢ, ĐỔI & BẢO HÀNH  <i className="fa-solid fa-angle-down"></i></a>
                        <div className="collapse submenu" id="terms9" aria-expanded="false">
                            Để tạo sự yên tâm, tin tưởng của quý khách khi mua sắm tại homeclick.vn, chúng tôi xây dựng Chính sách đổi, trả & bảo hành minh bạch, kèm theo đó là hướng dẫn đổi, trả & bảo hành rõ ràng để quý khách thực hiện. Quý khách có thể kiểm tra thời gian bảo
                            hành của tất cả sản phẩm đã mua trong mục “Theo dõi đơn hàng” sau khi Đăng nhập vào tài khoản mà quý khách đã đăng ký trên homeclick.vn
                        </div>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link collapsed" href="#terms10" data-bs-toggle="collapse" data-bs-target="#terms10">X. TRÁCH NHIỆM ĐỐI VỚI LỖI CUNG CẤP, NHẬP SAI THÔNG TIN TRÊN HOMECLICK.VN  <i className="fa-solid fa-angle-down"></i></a>
                        <div className="collapse submenu" id="terms10" aria-expanded="false">
                            Quý khách có trách nhiệm cung cấp thông tin đầy đủ và chính xác khi tham gia giao dịch tại homeclick.vn. Chúng tôi có quyền từ chối thực hiện giao dịch hoặc đóng tài khoản của quý khách và cũng không có nghĩa vụ giải quyết các khiếu nại liên quan đến
                            thông tin do quý khách cung cấp không chính xác.
                        </div>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link collapsed" href="#terms11" data-bs-toggle="collapse" data-bs-target="#terms11">XI. QUY ĐỊNH VỀ BẢO MẬT  <i className="fa-solid fa-angle-down"></i></a>
                        <div className="collapse submenu" id="terms11" aria-expanded="false">
                            Chúng tôi xem trọng việc bảo mật thông tin và sử dụng các giải pháp tối ưu để bảo vệ thông tin mà quý khách đã cung cấp. Tất cả thông tin giao dịch sẽ được bảo mật ngoại trừ trường hợp cơ quan có thẩm quyền theo pháp luật yêu cầu và tuân thủ chính sách
                            bảo mật của homeclick.vn Quý khách không được sử dụng bất kỳ chương trình, công cụ hay hình thức nào khác để can thiệp vào hệ thống và/hoặc làm thay đổi cấu trúc dữ liệu của hệ thống. Chúng tôi cũng nghiêm cấm việc phát tán,
                            truyền bá hay cổ vũ cho bất kỳ hoạt động nào nhằm xâm nhập, can thiệp hay phá hoại hệ thống dữ liệu. Cá nhân hay tổ chức vi phạm sẽ bị chấm dứt mọi giao dịch và bị truy tố trước pháp luật nếu cần thiết.
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
  )
}
