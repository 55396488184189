export default function Newsletter(){
  return (
    <div id="subscribe-v2" className="container-fluid">
      <div className="row w-1150">
        <div className="col-12">
          <div className="form-subscribe">
            <span className="w-768 dang-ky-km">ĐĂNG KÝ NHẬN KHUYẾN MÃI</span>
          </div>
        </div>
        <div className="pl-0 col-12 col-md-9">
          <div className="form-subscribe">
            <span className="w-bigger-768">ĐĂNG KÝ NHẬN KHUYẾN MÃI</span>
            <form className="subscribe-submit" action="#">
              <input type="text" placeholder="Email của bạn" name="search" />
              <button type="submit"><i className="fa-solid fa-angle-right"></i></button>
            </form>
          </div>
        </div>
        <div className="pr-0 col-12 col-md-3 subscribe-icon">
          <a href="#"><i className="fa-brands fa-facebook-f"></i></a>
          <a href="#"><i className="fa-brands fa-instagram"></i></a>
          <a href="#"><i className="fa-brands fa-linkedin-in"></i></a>
          <a href="#"><i className="fa-brands fa-pinterest"></i></a>
          <a href="#"><i className="fa-brands fa-youtube"></i></a>
        </div>
      </div>
    </div>
  )
}