import { useEffect } from 'react';

export default function UseScript(scripts){
  useEffect(() => {
    scripts.map(item => {
        const script = document.createElement('script');
        script.src = item;
        script.async = true;
        document.body.appendChild(script);
        return () => {
          document.body.removeChild(script);
        };
      }
    )
  }, [scripts]);
}